import DownloadIcon from '@mui/icons-material/Download'
import MailIcon from '@mui/icons-material/Mail'
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState, MouseEvent } from 'react'
import { useParams } from 'react-router-dom'
import { tomSelector } from '@store/slices/documentsPages/tom'
import { useAddRemarkMessageMutation, useDeleteRemarkMutation, useDownloadRemarkAttachmentMutation } from '../../../../../../api/remarks'
import { MessageAttachment, RemarkInfo, RemarkMessage, RemarkStatus } from '../../../../../../api/remarks/types'
import Tooltip from '../../../../../../components/Tooltip'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../../hooks/useConfirmDialog'
import { setIsAnnotationMode, setRemarkFormData, setRemarkFormMode } from '@store/slices/remarks/remarks'
import { profileSelector } from '../../../../../../store/slices/profile'
import { RemarkFormData } from '../../../../../../store/slices/remarks/remarks.types'
import { useAppDispatch, useTypedSelector } from '../../../../../../store/store'
import { downloadBlob } from '../../../../../../utils/dowloadBlob'
import { convertDateTime } from '../../../../../../utils/formatDateAndTimeGMT'
import { reduceFileName } from '../../../../../../utils/reduceFileName'
import { RemarkMenu, RemarkMenuData } from '../RemarkMenu'
import { ReplyToRemark } from '../ReplyToRemark'
import { MAX_FILENAME_LENGTH, MAX_HEIGHT_REMARK, remarkMenuList } from './Remark.config'
import {
  AnswerButton,
  ExpandButton,
  FileNameText,
  MainText,
  MessageWrapper,
  RemarkContainer,
  RemarkStatusLabel,
  StatusLabel,
  StatusText,
  SubtitleText,
  TextLink,
} from './Remark.styles'
import { RemarkMenuTitle, RemarkProps, remarkColorStatus, remarkStatusEnToRu } from './Remarks.types'
import { CircleInfoText } from '../RemarkForm'
import { ElipsisText } from '@styles/global/ElipsisText';

export const Remark = ({ remarkData, selectedRemark, scrollTrigger, selectRemark, openRemarkForm }: RemarkProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const profile = useTypedSelector(profileSelector)
  const { remark, mail: remarkMail, messages, totalPages, access } = remarkData || {}
  const { avatar, name, company } = remark.author || {}
  const isAdmin = profile.role === 'admin'
  const isAuthorCompany = profile.company.userCompanyName === company
  const initialRemark: string = remark.isSecond ? 'Повторное' : 'Первичное'
  const [isOpenMessageForm, setIsOpenMessageForm] = useState<boolean>(false)
  const [isSecondRemark, setIsSecondRemark] = useState<boolean>(false)
  const [editAnswerData, setEditAnswerData] = useState<{ message: RemarkMessage, attachment: MessageAttachment[] } | null>(null)
  const [showAll, setShowAll] = useState(false)
  const [offsetHeight, setOffsetHeight] = useState<number>(0)
  const refContainer = useRef<HTMLDivElement | null>(null)
  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled: boolean = !!tom?.features.annulment.annulled

  const [fileDataForDownload, setFileDataForDownload] = useState<string | null>(null)
  const [addRemarkMessage] = useAddRemarkMessageMutation()
  const [downloadAttachment, downloadAttachmentResponse] = useDownloadRemarkAttachmentMutation()
  const [deleteRemark, deleteRemarkResponse] = useDeleteRemarkMutation()

  const remarkTomInfoTitle = (remarkInfo: RemarkInfo, version: number | null, withPage: boolean) =>
    [
      withPage && remark.page && `Лист ${remarkInfo.page}`,
      version !== null && `версия тома ${version}`,
      remark.change !== null && `изм. ${remarkInfo.change}`,
    ]
      .filter(Boolean)
      .join(', ')

  const onEditRemark = () => {
    dispatch(
      setRemarkFormData({
        remarkFormData: {
          change: remark.change,
          page: remark.page,
          comment: remark.isSecond ? messages[messages.length - 1].message.comment : messages[0].message.comment,
          reason: remark.isSecond ? messages[messages.length - 1].message.reason : messages[0].message.reason,
          version: remark.isSecond ? messages[messages.length - 1].message.version : messages[0].message.version,
          remarkId: remark.id,
        } as RemarkFormData,
      }),
    )
    handleSelectRemark(true)
    if (!remark.isSecond) dispatch(setIsAnnotationMode(true))
    dispatch(setRemarkFormMode({ remarkFormMode: 'edit' }))
    openRemarkForm()
  }

  const menuFunctions: Record<RemarkMenuTitle, () => void> = {
    'Удалить замечание': () => openConfirm(),
    'Редактировать замечание': () => onEditRemark(),
  }

  const confirmDeleteRemark = (confirm: boolean) => {
    if (confirm) {
      deleteRemark({ projectId, remarkId: remark.id })
    }
  }

  const remarkMenuData: RemarkMenuData[] | undefined = remarkMenuList(isAdmin, remark.isSecond)[
    remark.status
  ]?.map((item) => ({
    itemName: item,
    action: menuFunctions[item],
  }))

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: confirmDeleteRemark,
    body: <>Замечание будет удалено без возможности восстановить</>,
    title: 'Удалить замечание?',
    denyButtonText: 'Отменить',
    confirmButtonText: 'Удалить',
    confirmButtonColor: 'error',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const сroppedMessages =
    offsetHeight <= MAX_HEIGHT_REMARK || messages.length <= 2 || showAll
      ? messages
      : [messages[0], messages[messages.length - 1]]

  const handleDownloadAttachment = (attachId: number, fileName: string) => {
    downloadAttachment({ projectId, remarkId: remark.id, attachId })
    setFileDataForDownload(fileName)
  }

  const handleSelectRemark = (notCancelSelect?: boolean) => {
    selectRemark(remark.id, messages[0].message.version, remark.change, notCancelSelect)
  }

  const handleSelectMessage = (e: MouseEvent, message: RemarkMessage) => {

    if (message.status !== 'created' && message.status !== 'submitted') {
      e.stopPropagation()
      selectRemark(remark.id, message.version, remark.change)
    }
  }

  const renderAnswerButton = (remarkStatus: RemarkStatus) => {

    const addAnswer = (e: MouseEvent, isSecond: boolean) => {
      e.stopPropagation()
      if (isSecond) {
        setIsSecondRemark(true)
      }
      setIsOpenMessageForm(true)
    }

    const setNewStatus = (e: MouseEvent, newStatus: RemarkStatus) => {
      e.stopPropagation()
      addRemarkMessage({
        projectId,
        remarkId: remark.id,
        file: null,
        message: {
          comment: '',
          reason: '',
          status: newStatus
        }
      })
    }

    switch (remarkStatus) {
      case 'created':
      case 'closed':
        return null
      case 'submitted':
        return (
          <AnswerButton variant='contained' color='secondary' onClick={(e) => addAnswer(e, false)}>
            Добавить ответ
          </AnswerButton>
        )
      case 'accepted':
        return (
          <AnswerButton variant='contained' colortype='green' onClick={(e) => setNewStatus(e, 'processed')}>
            Отработано
          </AnswerButton>
        )
      case 'processed':
        return (
          <Stack direction='column' spacing={2}>
            <AnswerButton variant='contained' colortype='red' onClick={(e) => addAnswer(e, true)}>
              Создать повторно
            </AnswerButton>
            <AnswerButton variant='contained' colortype='green' onClick={(e) => setNewStatus(e, 'closed')}>
              Закрыть замечание
            </AnswerButton>
          </Stack>
        )
    }
  }

  const handleEditAnswer = (e: MouseEvent, message: RemarkMessage, attachment: MessageAttachment[]) => {
    e.stopPropagation()
    setEditAnswerData({ message, attachment })
    setIsOpenMessageForm(true)
  }

  const closeReply = () => {
    setIsOpenMessageForm(false)
    setIsSecondRemark(false)
    setEditAnswerData(null)
  }

  useEffect(() => {
    if (refContainer.current) {
      setOffsetHeight(refContainer.current.offsetHeight)
    }
  }, [refContainer])

  useEffect(() => {
    if (downloadAttachmentResponse.data) {
      downloadBlob(downloadAttachmentResponse.data, fileDataForDownload || 'Файл загрузки')
    }
    if (downloadAttachmentResponse.isError) {
      enqueueSnackbar('Не удалось загрузить файл, попробуйте еще раз.', { variant: 'error' })
    }
  }, [downloadAttachmentResponse])

  useEffect(() => {
    if (deleteRemarkResponse.isSuccess) {
      enqueueSnackbar('Замечание успешно удалено.', { variant: 'success' })
    }
    if (deleteRemarkResponse.isError) {
      enqueueSnackbar('Не удалось удалить замечание.', { variant: 'error' })
    }
  }, [deleteRemarkResponse])


  useEffect(() => {
    if (selectedRemark === remark.id && scrollTrigger) {
      refContainer.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [remark.id, scrollTrigger, selectedRemark])

  return (
    <Stack spacing={0.5} py={1.25} ref={refContainer} >
      <Stack direction='row' spacing={1} alignItems='center'>
        <StatusLabel color={remarkColorStatus[remark.status]} />
        <StatusText>
          {remarkStatusEnToRu[remark.status]}, {convertDateTime(remark.updatedAt, false, true)}
        </StatusText>
      </Stack>

      <RemarkContainer spacing={1} p={2} selected={selectedRemark === remark.id} onClick={() => handleSelectRemark()}>
        <Stack direction='row'>
          <Avatar src={avatar || ''} sx={{ height: '32px', width: '32px' }} />
          <Stack sx={{ textAlign: 'start' }} flex={1} ml={1.5}>
            <Typography variant='body1' fontWeight={500} lineHeight='normal'>
              {name}
            </Typography>
            <TextLink fontSize={14} $hover={true}>
              {remarkTomInfoTitle(remark, messages[0].message.version, true)}
            </TextLink>
            <Typography variant='body1' fontSize={14}>
              {initialRemark}, {convertDateTime(remark.createdAt, false, true)}
            </Typography>
          </Stack>
          {messages[0].mail && (
            <Tooltip
              variant='light'
              title={<></>}
              customTitle={
                <Stack direction='row' spacing={0.5}>
                  <Typography variant='tooltip' textTransform='none' color='#5C6E8C'>
                    Письмо:
                  </Typography>
                  <Typography variant='tooltip' textTransform='none' fontWeight={500}>
                    {`${messages[0].mail.mailNum} от ${messages[0].mail.mailDate}`}
                  </Typography>
                </Stack>
              }
            >
              <MailIcon fontSize='small' color='primary' sx={{ position: 'relative', top: '11px', right: '7px' }} />
            </Tooltip>
          )}
          {(isAdmin || isAuthorCompany) && remarkMenuData && remarkMenuData.length > 0 && (
            <RemarkMenu data={remarkMenuData} title='Управление замечанием' anchorParentElement={true} />
          )}
        </Stack>

        {сroppedMessages.map(({ message, attachments, mail, marks }, index) => (
          <Stack key={message.id}>
            {!showAll && index !== 0 && messages.length > 2 && offsetHeight >= MAX_HEIGHT_REMARK && (
              <ExpandButton variant='text' fullWidth onClick={() => setShowAll(true)}>
                Показать историю
              </ExpandButton>
            )}

            {message.id === editAnswerData?.message.id ? null :
              <MessageWrapper
                spacing={1}
                $bg={message.status !== 'created' && message.status !== 'submitted' ? selectedRemark === remark.id ? 'blue' : 'shades' : ''}
                $hover={message.status !== 'created' && message.status !== 'submitted'}
                onClick={(e) => handleSelectMessage(e, message)}
              >
                {index === 0 ? null : (
                  <Stack direction='row'>
                    <Avatar src={message.author.avatar || ''} sx={{ height: '32px', width: '32px' }} />
                    <Box textAlign='start' maxWidth='190px' ml={1.5}>
                      <ElipsisText variant='body1' fontWeight={500} lineHeight='normal'>
                        {message.author.name}
                      </ElipsisText>
                      <TextLink
                        $hover={message.status !== 'created' && message.status !== 'submitted'}
                        variant='body1' fontSize={14}>
                        {remarkTomInfoTitle(remark, message.version, false)}
                      </TextLink>
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <RemarkStatusLabel color={remarkColorStatus[message.status]}></RemarkStatusLabel>
                        <Typography variant='body1' fontSize={14}>
                          {`${remarkStatusEnToRu[message.status]} ${convertDateTime(message.createdAt, false, true)}`}
                        </Typography>
                      </Stack>
                    </Box>
                    {access.canEditAnswer && message.status === 'accepted' && index === сroppedMessages.length - 1 &&
                      <IconButton color='primary' sx={{ height: '30px', width: '30px', marginLeft: 'auto' }}
                        onClick={(e) => handleEditAnswer(e, message, attachments)}>
                        <EditIcon fontSize='medium' />
                      </IconButton>
                    }
                    {mail && (
                      <Tooltip
                        variant='light'
                        title={<></>}
                        customTitle={
                          <Stack direction='row' spacing={0.5}>
                            <Typography variant='tooltip' textTransform='none' color='#5C6E8C'>
                              Письмо:
                            </Typography>
                            <Typography variant='tooltip' textTransform='none' fontWeight={500}>
                              {`${mail.mailNum} от ${mail.mailDate}`}
                            </Typography>
                          </Stack>
                        }
                      >
                        <MailIcon
                          fontSize='small'
                          color='primary'
                          sx={{ position: 'relative', top: '11px', right: '7px', marginLeft: 'auto !important' }}
                        />
                      </Tooltip>
                    )}
                  </Stack>
                )}

                {message.comment && <MainText variant='body1'>{message.comment}</MainText>}
                {message.reason && (
                  <Stack alignItems='flex-start'>
                    <SubtitleText>Основание</SubtitleText>
                    <MainText variant='body1'>{message.reason}</MainText>
                  </Stack>
                )}

                {/* Пометки */}
                {marks && marks.length > 0 &&
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <SubtitleText>Пометки</SubtitleText>
                    <CircleInfoText>
                      <Typography>{marks.length}</Typography>
                    </CircleInfoText>
                  </Stack>}

                {attachments.map((file) => (
                  <Stack direction='row' alignItems='center' spacing={1} key={file.id}>
                    <Box
                      display='inline-flex'
                      alignItems='center'
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleDownloadAttachment(file.id, file.name)}
                    >
                      <DownloadIcon fontSize='small' color='primary' sx={{ marginRight: 1 }} />
                      <Tooltip title={<>{file.name}</>} disableHoverListener={file.name.length < MAX_FILENAME_LENGTH}>
                        <FileNameText>{reduceFileName(file.name, MAX_FILENAME_LENGTH)}</FileNameText>
                      </Tooltip>
                    </Box>
                  </Stack>
                ))}
              </MessageWrapper>}
            {message.status === 'submitted' && (
              <Stack py={1} mt={1} direction='row' alignItems='center' justifyContent='space-between'>
                <svg xmlns='http://www.w3.org/2000/svg' width='30' height='7' viewBox='0 0 30 7' fill='none'>
                  <path d='M5 3L0 0.613249V6.38675L5 4V3ZM4.5 4H30V3H4.5V4Z' fill='#7890B2' />
                </svg>
                <StatusText>
                  {index === 0 ? 'Направлено ' : 'Направлено повторно'}
                  {convertDateTime(message.createdAt, true, true)}
                </StatusText>
                <svg xmlns='http://www.w3.org/2000/svg' width='30' height='7' viewBox='0 0 30 7' fill='none'>
                  <path d='M25 4L30 6.38675V0.613249L25 3V4ZM0 4H25.5V3H0V4Z' fill='#7890B2' />
                </svg>
              </Stack>
            )}
            {index === 0 && remarkMail && (
              <>
                <Stack direction='row' justifyContent='space-between' spacing={1} pt={2} textAlign='end'>
                  <SubtitleText>Ответственный: </SubtitleText>
                  <Stack direction='column'>
                    {remarkMail.responsible &&
                      <Typography variant='h1' fontSize={12}>{remarkMail.responsible.name}</Typography>}
                    <Typography variant='body2' fontSize={12} fontWeight={500}>{remarkMail.responsibleCompany}</Typography>
                  </Stack>
                </Stack>
                {remark.executor && (
                  <Stack direction='row' justifyContent='space-between' spacing={1} pt={2} textAlign='end'>
                    <SubtitleText>Исполнитель: </SubtitleText>
                    <Stack direction='column'>
                      <Typography variant='h1' fontSize={12}>{remark.executor.name}</Typography>
                      <Typography variant='body2' fontSize={12} fontWeight={500}>{remark.executor.company}</Typography>
                    </Stack>
                  </Stack>
                )}
              </>
            )}
          </Stack>
        ))}
        {isOpenMessageForm &&
          <ReplyToRemark remarkId={remark.id} closeReply={closeReply} isSecondRemark={isSecondRemark} editAnswerData={editAnswerData} />}

        {!access.canAddMessage || isOpenMessageForm ? null : (
          renderAnswerButton(remark.status)
        )}
      </RemarkContainer>
      <ConfirmDialog />
    </Stack>
  )
}
