import { FormControlLabel, Stack, TextField } from "@mui/material";
import { FilterSelectMultiple } from "components/FilterSelectMulti";
import styled from "styled-components";

export const FormWrapper = styled(Stack)`
  width: 434px;
  padding: 20px 0 20px 20px;
  border: 1px solid ${props => props.theme.palette.bg.gray};
  border-radius: 16px;
`
export const SelectAllCheckbox = styled(FormControlLabel)`
  margin-left: 20px;

  & .MuiCheckbox-root.Mui-disabled {
    color: ${props => props.theme.palette.disabled + 'cc'};
  }
  
  &.Mui-disabled {
    & .MuiTypography-root {
      color: ${props => props.theme.palette.disabled + 'cc'};
    }
  }

  & .MuiCheckbox-root {
    margin-right: 8px;
    padding: 0;
    color: ${props => props.theme.palette.secondary.dark};

    &.Mui-checked {
      color: ${props => props.theme.palette.primary.main};
    }

     & .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }
`
export const FilterStatusSelect = styled(FilterSelectMultiple)`
  width: 50%;
  border-radius: 6px;
  background-color: ${props => props.theme.palette.bg.shades};

  & .contentWrapper {
    max-width: calc(100% - 30px);
  }

  & .contentText {
    max-width: 100%;
  }
`

export const TomNameSearch = styled(TextField)`
  height: 36px;
  width: 50%;

  & .MuiInputBase-input {
    padding: 6px;

    ::placeholder {
      font-size: 14px;
    }
  }

  & fieldset {
    border: 1px solid #D1D8FA;
    border-radius: 6px;
  }

  & .MuiOutlinedInput-root {
    padding-right: 4px;

    & .MuiInputAdornment-root {
      margin: 2px;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.secondary.dark + '3A'};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }

    &.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.secondary.dark + '3A'};
    }
  }
`