import { HeadBtnState, RemarkStatus } from '../../../../../../api/remarks/types'
import { RemarkGroupedTableMenuTitle, RemarkMenuTitle, RemarkStatusRu, RemarkTableMenuTitle, remarkStatusEnToRu } from './Remarks.types'

export const MAX_FILENAME_LENGTH = 30
export const MAX_HEIGHT_REMARK = 700

export interface AvailableStatuses extends Record<RemarkStatus, typeof userRolesArray> { }
export interface RemarkMenuList extends Record<RemarkStatus, Array<RemarkMenuTitle>> { }
export interface RemarkTableMenuList extends Partial<Record<RemarkStatus, Array<RemarkTableMenuTitle>>> { }
export interface RemarkGroupedTableMenuList extends Partial<Record<RemarkStatus, Array<RemarkGroupedTableMenuTitle | undefined>>> { }
export const userRolesArray = Object.values(remarkStatusEnToRu) as Array<RemarkStatusRu>

export const remarkMenuList = (isAdmin: boolean, isSecond: boolean): RemarkMenuList => ({
  submitted: isAdmin ? ['Удалить замечание'] : [],
  accepted: isAdmin ? ['Удалить замечание'] : [],
  processed: isAdmin ? ['Удалить замечание'] : [],
  closed: isAdmin ? ['Удалить замечание'] : [],
  created: isSecond ? isAdmin ? ['Редактировать замечание', 'Удалить замечание'] : ['Редактировать замечание'] : ['Редактировать замечание', 'Удалить замечание'],
})

export const remarkTableMenuList = (
  isAdmin: boolean,
  isAuthorCompany: boolean,
  isResponsible: boolean,
  isSecond: boolean,
  remarkSelector: "incoming" | "outgoing",
  executor: boolean
): RemarkTableMenuList => {

  if (remarkSelector === "incoming") {
    return {
      submitted: isAdmin
        ? ['Удалить замечание', executor ? 'Сменить исполнителя' : 'Назначить исполнителя']
        : isResponsible ? [executor ? 'Сменить исполнителя' : 'Назначить исполнителя']
          : [],
      accepted: isAdmin
        ? ['Удалить замечание', executor ? 'Сменить исполнителя' : 'Назначить исполнителя']
        : isResponsible ? [executor ? 'Сменить исполнителя' : 'Назначить исполнителя']
          : [],
      processed: isAdmin ? ['Удалить замечание'] : [],
      closed: isAdmin ? ['Удалить замечание'] : []
    }
  }
  return {
    submitted: isAdmin ? ['Удалить замечание'] : [],
    accepted: isAdmin ? ['Удалить замечание'] : [],
    processed: isAdmin ? ['Удалить замечание'] : [],
    closed: isAdmin ? ['Удалить замечание'] : [],
    created: isAdmin || isAuthorCompany
      ? isSecond
        ? isAdmin
          ? ['Редактировать замечание', 'Удалить замечание']
          : ['Редактировать замечание']
        : ['Редактировать замечание', 'Удалить замечание']
      : [],
  }
}

export const remarkGroupedTableMenuList = (
  isAdmin: boolean,
  isResponsible: boolean,
  remarkSelector: "incoming" | "outgoing",
  btnState: HeadBtnState,
  responsible: boolean
): RemarkGroupedTableMenuList => {
  const { attachments, executors } = btnState

  const executorsMenuItem: Record<('EMPTY' | 'EXISTS'), RemarkGroupedTableMenuTitle> = {
    'EMPTY': 'Назначить исполнителя',
    'EXISTS': 'Сменить исполнителя',
  }

  const attachmentsMenuItem: Record<('SINGLE' | 'MANY' | 'NONE'), RemarkGroupedTableMenuTitle | undefined> = {
    'SINGLE': 'Скачать вложение',
    'MANY': 'Скачать вложения',
    'NONE': undefined
  }

  if (remarkSelector === "incoming") {
    return {
      submitted: isAdmin
        ? [attachmentsMenuItem[attachments], executorsMenuItem[executors], responsible ? 'Сменить ответственного' : 'Назначить ответственного']
        : [attachmentsMenuItem[attachments], isResponsible ? executorsMenuItem[executors] : undefined],
      accepted: isAdmin
        ? [attachmentsMenuItem[attachments], executorsMenuItem[executors], responsible ? 'Сменить ответственного' : 'Назначить ответственного']
        : [attachmentsMenuItem[attachments], isResponsible ? executorsMenuItem[executors] : undefined],
      processed: [attachmentsMenuItem[attachments]],
      closed: [attachmentsMenuItem[attachments]]
    }
  }
  return {
    submitted: isAdmin
      ? [attachmentsMenuItem[attachments], responsible ? 'Сменить ответственного' : 'Назначить ответственного']
      : [attachmentsMenuItem[attachments]],
    accepted: isAdmin
      ? [attachmentsMenuItem[attachments], responsible ? 'Сменить ответственного' : 'Назначить ответственного']
      : [attachmentsMenuItem[attachments]],
    processed: [attachmentsMenuItem[attachments]],
    closed: [attachmentsMenuItem[attachments]],
    created: [attachmentsMenuItem[attachments]],
  }
}