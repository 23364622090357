import { memo, useEffect, useRef, useState } from "react"
import { LazyPageProps } from "./LazyPage.types"
import { PageContainer } from "../../PragmaPdfViewer.styles"
import { Page } from "react-pdf"
import { Placeholder } from "../Placeholder"
import { Annotation } from "../../PragmaPdfViewer.types"
import { CanvasAnnotations } from "../CanvasAnnotations"
import { useAppDispatch, useTypedSelector } from "@store/store"
import { getAnnotationsSelector, isAnnotationModeSelector, remarkFormModeSelector, remarkPageSelector, selectedRemarkIdSelector } from "@store/slices/remarks/selectors/remarks.selectors"
import { setMarkCount, setRemarkAnnotations } from "@store/slices/remarks/remarks"
import { Mark } from "@api/remarks/types"

export const LazyPage = memo(({
  currentPage,
  handTool,
  pageData,
  pageIndex,
  rotate,
  scale,
  selectedNoteMode,
  isOpenRemarkForm,
  incomingAnnotations,
  isShowAllMarks,
  selectedAnnotationId,
  selectAnnotationId,
  stampMode
}: LazyPageProps) => {

  const dispatch = useAppDispatch()
  const pageRef = useRef<HTMLDivElement>(null)
  const willUpdateRef = useRef(false)
  const [annotations, setAnnotations] = useState<Annotation[]>([])
  const remarkPage = useTypedSelector(remarkPageSelector)
  const getAnnotationTrigger = useTypedSelector(getAnnotationsSelector)
  const remarkFormMode = useTypedSelector(remarkFormModeSelector)
  const isAnnotationMode = useTypedSelector(isAnnotationModeSelector)
  const selectedRemarkId = useTypedSelector(selectedRemarkIdSelector)

  const changeAnnotations = (annotations: Annotation | Annotation[]) => {
    Array.isArray(annotations) ? setAnnotations(annotations) : setAnnotations((prev) => [...prev, annotations])
  }

  useEffect(() => {
    if (remarkPage === pageIndex + 1) {
      if (isOpenRemarkForm) {
        remarkFormMode === 'create'
          ? dispatch(setMarkCount(annotations.filter(a => !a.id).length))
          : dispatch(setMarkCount(annotations.length))
      } else {
        dispatch(setMarkCount(null))
      }
    }
  }, [annotations, isOpenRemarkForm, remarkPage])

  useEffect(() => {
    if (getAnnotationTrigger && remarkPage === pageIndex + 1) {
      willUpdateRef.current = true
      const marks: Mark[] = annotations.length > 0
        ? (remarkFormMode === 'create' ? annotations.filter(a => !a.id) : annotations).map(({ id, ...annot }) => ({ annotation: annot, id: id }))
        : []
      dispatch(setRemarkAnnotations(marks))
    }
  }, [getAnnotationTrigger])

  const filterAnnotations = () => {
    if (!isShowAllMarks) {
      selectedRemarkId
        ? setAnnotations(incomingAnnotations.filter(a => a.page === pageIndex + 1 && a.remarkId === selectedRemarkId).flatMap(a => a.annotations))
        : isOpenRemarkForm ? setAnnotations((prev) => prev.filter(a => !a.id)) : setAnnotations([])
    } else if (isShowAllMarks) {
      isOpenRemarkForm
        ? setAnnotations((prev) => [...prev.filter(a => !a.id), ...incomingAnnotations.filter(a => a.page === pageIndex + 1).flatMap(a => a.annotations)])
        : setAnnotations(incomingAnnotations.filter(a => a.page === pageIndex + 1).flatMap(a => a.annotations))
    }
  }
  useEffect(() => {
    const willUpdate = willUpdateRef.current
    if (!isAnnotationMode && !willUpdate) {
      filterAnnotations()
    } else {
      willUpdateRef.current = false
    }
  }, [isAnnotationMode])

  useEffect(() => {
    if (isOpenRemarkForm && (!remarkPage || remarkPage !== pageIndex + 1)) {
      if (remarkFormMode === 'create') {
        setAnnotations(annotations.filter(a => a.id))
      }
      if (remarkFormMode === 'edit') {
        setAnnotations([])
      }
    }
  }, [remarkPage])

  useEffect(() => {
    filterAnnotations()
  }, [incomingAnnotations, isShowAllMarks, pageIndex, selectedRemarkId])

  if (pageIndex === currentPage - 3 || pageIndex === currentPage - 2 || pageIndex === currentPage - 1 || pageIndex === currentPage || pageIndex === currentPage + 1) {
    return (
      <PageContainer data-page={pageIndex} >
        <Page
          rotate={(pageData.pageRotate + rotate + 360) % 360}
          inputRef={pageRef}
          renderTextLayer={!handTool}
          width={pageData.width}
          height={pageData.height}
          scale={scale}
          loading={null}
          pageNumber={pageIndex + 1}
          renderAnnotationLayer={false}
        /* onRenderSuccess={({ pageNumber, width, height, originalHeight, originalWidth }) =>
          handlePageRenderSuccess(pageNumber, width, height, originalWidth)
        } */
        /* onGetAnnotationsSuccess={(annotations) => console.log(annotations)} */
        />
        <CanvasAnnotations
          width={pageData.width * scale}
          height={pageData.height * scale}
          rotate={rotate}
          scale={scale}
          scaleFactor={pageData.scaleFactor}
          canEditAnnotation={isOpenRemarkForm && remarkPage === pageIndex + 1}
          annotationType={selectedNoteMode}
          annotations={annotations}
          setAnnotations={changeAnnotations}
          documentRef={pageRef}
          isSelectMode={!isOpenRemarkForm && isShowAllMarks}
          isCreateMode={remarkFormMode === 'create'}
          pageNumber={pageIndex + 1}
          selectedAnnotationId={selectedAnnotationId}
          selectAnnotationId={selectAnnotationId}
          stampMode={stampMode}
          isShowAllMarks={isShowAllMarks}
        />

      </PageContainer>
    )
  }

  return (
    <PageContainer data-page={pageIndex}>
      <Placeholder height={pageData.height * scale} width={pageData.width * scale} />
    </PageContainer>
  )
})