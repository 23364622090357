import { Delete as DeleteIcon, Download as DownloadIcon, Edit as EditIcon } from '@mui/icons-material'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { SignatoriesList, SignatoriesListItem } from '@seven-winds-studio/react-cades'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { setOpenedDrawer } from 'store/slices/documentsPages/drawerInfo'
import { currentDocumentSelector, setCurrentChange, tomSelector } from 'store/slices/documentsPages/tom'

import { useGetTomSignsQuery } from '@api/tomCommonApi'
import { useCheckSignMutation } from '@api/tomCommonApi'
import { CheckSignRequest } from '@api/tomCommonApi/types'

import Progress from '@components/Progress'

import { TomColoredInfo } from '@pages/Docs'

import { DocVariant } from '../../../../layouts/DocumentsLayout'
import { setSelectedMenuView } from '../../../../store/slices/documentsPages/menu'
import {
  isExtraAccessArchitectorSelector,
  selectedProjectPhaseSelector,
} from '../../../../store/slices/documentsPages/projectInfo'
import { profileSelector } from '../../../../store/slices/profile'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { theme } from '../../../../styles/theme'
import { TomDocument } from '../../../../types/tom'
import { convertDateTime } from '../../../../utils/formatDateAndTimeGMT'
import { tomRoleAccess } from '../DocViewNavBarContentInfo/components/ChangeSign/ChangeSign.types'
import { DocNavBarItemAttachmentsButton, DocNavBarItemWrapper } from './DocNavBarItem.styles'
import { DocNavBarItemProps } from './DocNavBarItem.types'

export const DocNavBarItem: FC<DocNavBarItemProps> = ({ doc, onClick, onDownload, onDelete, loading }) => {
  const dispatch = useAppDispatch()

  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled = tom?.features.annulment.annulled
  const loadingId = useRef<number | null>(null)

  const isExtraAccessArchitector = useTypedSelector(isExtraAccessArchitectorSelector)
  const { role } = useTypedSelector(profileSelector)
  const { currentDocument } = useTypedSelector(currentDocumentSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const [usersWithSignatories, setUsersWithSignatories] = useState<Map<number, SignatoriesListItem>>(new Map())

  const { version, change, preview, uploadedBy, createdAt, attachCount, signed } = doc
  const docVariant: DocVariant = change ? 'change' : 'version'
  const updateChangeAccess: boolean = tomRoleAccess.includes(role)

  const title: Record<DocVariant, string> = {
    change: `Изменение ${change}`,
    version: `Версия ${version}`,
  }

  const { data: documentSigns } = useGetTomSignsQuery(
    { id: currentDocument.tomId || 0, version: version },
    { skip: !currentDocument?.tomId || !signed },
  )

  const [checkSign] = useCheckSignMutation()

  useEffect(() => {
    if (documentSigns?.data) {
      const signsData: [number, SignatoriesListItem][] = documentSigns.data.map((value) => {
        const { name, position, avatar, company } = value.employeeProfile || {}
        return [
          value.id,
          {
            name,
            position,
            avatar,
            company,
            date: value.createdAt,
            ...value
          },
        ]
      })
      setUsersWithSignatories(new Map(signsData))
    }
  }, [documentSigns])

  const onAttachmentsClick = () => {
    dispatch(setSelectedMenuView({ selectedMenuView: 'attachments' }))
  }

  const onDownloadClick = (e: React.MouseEvent<HTMLButtonElement>, doc: TomDocument) => {
    e.stopPropagation()
    onDownload(doc)
    loadingId.current = doc.docId
  }

  const onEditClick = (e: React.MouseEvent<HTMLButtonElement>, doc: TomDocument) => {
    e.stopPropagation()
    dispatch(setCurrentChange({ change }))
    dispatch(setOpenedDrawer({ openedDrawer: 'docChange' }))
  }

  const onDeleteClick = (e: React.MouseEvent<HTMLButtonElement>, doc: TomDocument) => {
    e.stopPropagation()
    onDelete(doc)
  }

  const onButtonCheckSignClick = useCallback(
    async (id: number | string) => {
      const signID = Number(id)
      if (!tom?.id || Number.isNaN(signID)) return

      let updateItem = usersWithSignatories.get(Number(id))
      if (!updateItem) return
      const newState = new Map(usersWithSignatories)
      updateItem.isFetching = true
      newState.set(signID, updateItem)
      setUsersWithSignatories(newState)

      let phasePrefix: CheckSignRequest['phasePrefix'] | null = null
      switch (selectedProjectPhase) {
        case 'Рабочая документация':
          phasePrefix = 'tom'
          break
        case 'Проектная документация':
          phasePrefix = 'tom-pd'
          break
        case 'Инженерные изыскания':
          phasePrefix = 'tom-ii'
          break
        case 'Сбор исходных данных':
          phasePrefix = 'tom-ird'
      }
      if (!phasePrefix) return

      try {
        const checkingResult = await checkSign({
          id: tom?.id,
          phasePrefix,
          signID,
        })
        updateItem.isFetching = false
        if ('data' in checkingResult) {
          updateItem = { ...updateItem, error: checkingResult.data.error, isSignChecked: !checkingResult.data.error }
        }

        setUsersWithSignatories((prev) => {
          const newMap = new Map(prev)
          updateItem && newMap.set(Number(id), updateItem)
          return newMap
        })
      } catch {
        if (updateItem) {
          updateItem.isFetching = false
          newState.set(Number(id), updateItem)
        }
        setUsersWithSignatories(newState)
      }
    },
    [tom?.id, selectedProjectPhase, usersWithSignatories],
  )

  useEffect(() => {
    if (!loading) loadingId.current = null
  }, [loading])

  return (
    <DocNavBarItemWrapper onClick={() => onClick(doc)} isSelected={currentDocument.docId === doc.docId}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2.5}>
        <Box width={63} height={90}>
          <img src={preview} width={63} height={90} alt='Превью документа' />
        </Box>

        <Stack spacing={1.125} flex={1}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
            <Box>
              <Typography variant='body2' height={21} lineHeight='21px' color={theme.palette.primary.main}>
                {title[docVariant]}
              </Typography>
            </Box>

            <TomColoredInfo direction='row' alignItems='center' customColor='blue' smallPadding thin>
              <Typography variant='tooltip' style={{ marginTop: 2 }}>
                pdf
              </Typography>
            </TomColoredInfo>
          </Stack>

          <Stack spacing={0.5}>
            <Box>
              <Typography variant='body2' lineHeight='13px' fontSize={12}>
                {uploadedBy}
              </Typography>
            </Box>
            <Box>
              <Typography variant='body2' lineHeight='13px' fontSize={12}>
                {convertDateTime(createdAt, false, true)}
              </Typography>
            </Box>
          </Stack>

          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2.5}>
            <DocNavBarItemAttachmentsButton onClick={onAttachmentsClick} variant='text' disableRipple>
              <InsertDriveFileOutlinedIcon fontSize='medium' color='secondary' />
              <Box>
                <Typography variant='body2' mt={0.125} lineHeight='15px'>
                  {attachCount}
                </Typography>
              </Box>
            </DocNavBarItemAttachmentsButton>

            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1.25}>
              {!!usersWithSignatories.size && signed && (
                <SignatoriesList
                  users={Array.from(usersWithSignatories.values())}
                  onButtonCheckSignClick={onButtonCheckSignClick}
                />
              )}

              {loading && loadingId.current === doc.docId ? (
                <Progress sx={{ width: '20px !important', height: '20px !important' }} />
              ) : (
                <IconButton onClick={(e) => onDownloadClick(e, doc)} sx={{ p: 0 }} disableRipple>
                  <DownloadIcon fontSize='medium' color='secondary' />
                </IconButton>
              )}
              {!isAnnulled && updateChangeAccess && docVariant === 'change' && (
                <IconButton onClick={(e) => onEditClick(e, doc)} sx={{ p: 0 }} disableRipple>
                  <EditIcon fontSize='medium' color='secondary' />
                </IconButton>
              )}
              {role === 'admin' || (docVariant === 'change' && (role === 'client' || isExtraAccessArchitector)) ? (
                <IconButton onClick={(e) => onDeleteClick(e, doc)} sx={{ p: 0 }} disableRipple>
                  <DeleteIcon fontSize='medium' color='secondary' />
                </IconButton>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </DocNavBarItemWrapper>
  )
}
