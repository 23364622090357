import Progress from "@components/Progress"
import { StyledAgGridWrapper } from "@styles/global/StyledAgGridWrapper"
import { ColDef, ICellRendererParams, RowClickedEvent } from "ag-grid-community"
import { AgGridReact } from "ag-grid-react"
import { ObjectsTableProps } from "./ObjectsTable.types"
import { useMemo } from "react"
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack } from "@mui/material"
import { ElipsisText } from "@styles/global/ElipsisText"
import { COMMON_DOCS_NUMBER } from "@utils/constants"
import { defaultProjectInfoSelector } from "@store/slices/documentsPages/projectInfo"
import { useTypedSelector } from "@store/store"

const flexStartClass = 'ag-cell_flex_column ag-cell_justify-content_center ag-cell_align-items_start commonDocsRow'

const DefaultCell = (params: ICellRendererParams, onEditClick: (id: number) => void, userWithLessControls?: boolean) => {
  const number: string | undefined = params.data.number
  const field: string | undefined = params.colDef?.field
  const canEdit = typeof userWithLessControls === 'boolean' ? !userWithLessControls : false

  return (
    <Stack direction='row' px={2} width='100%' alignItems='center'>
      <ElipsisText
        color={number === COMMON_DOCS_NUMBER ? '#ed6c02' : '#2b3648'}
        fontSize={14}>{params.value}</ElipsisText>
      {field === 'description' && canEdit &&
        <IconButton onClick={() => onEditClick(params.data.id)}>
          <EditIcon fontSize='medium' color='primary'/>
        </IconButton>
      }
    </Stack>
  )
}

export const ObjectsTable = ({ columnsType, data, onRowClick, onEditClick }: ObjectsTableProps) => {
  const { userWithLessControls } = useTypedSelector(defaultProjectInfoSelector)
  const overlayNoRowsTemplate = columnsType === 'objects'
    ? 'Объектов нет. Проверьте состояние переключателя "Все объекты".'
    : 'Разделов нет. Проверьте состояние переключателя "Все разделы".'

  const columnDef = useMemo((): ColDef[] => {
    return [
      {
        field: columnsType === 'objects' ? 'number' : 'mark',
        headerName: columnsType === 'objects' ? 'Номер объекта' : 'Марка',
        flex: 1,
        minWidth: 200,
        cellClass: [flexStartClass],
        cellRenderer: DefaultCell
      },
      {
        field: columnsType === 'objects' ? 'title' : 'fullName',
        headerName: columnsType === 'objects' ? 'Краткое название' : 'Наименование',
        flex: 2,
        minWidth: 300,
        cellClass: [flexStartClass],
        cellRenderer: DefaultCell
      },
      {
        field: 'description',
        headerName: columnsType === 'objects' ? 'Полное наименование' : 'Примечание',
        flex: 2,
        minWidth: 300,
        cellClass: [flexStartClass],
        cellRenderer: (params: ICellRendererParams) => DefaultCell(params, onEditClick, userWithLessControls)
      },
    ]
  }, [columnsType, userWithLessControls])

  const rowClickedListener = (e: RowClickedEvent) => {
    const target = e.event?.target as HTMLElement | null | undefined
    if (target && target.closest('button')) return
    onRowClick(e.data.id)
  }

  return (
    <StyledAgGridWrapper>
      <div className='ag-theme-alpine'>
        <AgGridReact
          onRowClicked={rowClickedListener}
          overlayNoRowsTemplate={overlayNoRowsTemplate}
          loadingOverlayComponent={Progress}
          columnDefs={columnDef}
          rowData={data}
          headerHeight={32}
          detailRowAutoHeight
          rowHeight={40}
          suppressRowTransform={true}
          animateRows={true}
          suppressDragLeaveHidesColumns={true}
          defaultColDef={{
            cellDataType: false,
            editable: false,
            sortable: true,
            filter: false,
            resizable: true,
          }}
        />
      </div>
    </StyledAgGridWrapper>
  )
}