import { FC, MouseEvent } from 'react'
import { InfoBlockWrapper, InfoCardButton } from '../PhaseCardContent'
import { Stack, Typography } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { theme } from '../../../../styles/theme'
import { MediumTemplateWrapper } from '../InfoCard/InfoCard.styles'
import Tooltip from '../../../../components/Tooltip'
import { PackIcon } from '../../../../assets/icons/PackIcon'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetAgreementPocketsInfoQuery } from '../../../../api/agreementPocket'

export const PocketCard: FC = () => {
  const navigate = useNavigate()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const { data } = useGetAgreementPocketsInfoQuery({ projectId })
  const { incoming, outgoing, total } = data || {}

  const onCardClick = () => {
    navigate('pockets')
  }

  const onIncomingClick = (e: MouseEvent) => {
    e.stopPropagation()
    navigate('pockets?initialIncoming=true')
  }

  const onOutgoingClick = (e: MouseEvent) => {
    e.stopPropagation()
    navigate('pockets?initialOutgoing=true')
  }

  return (
    <MediumTemplateWrapper onClick={onCardClick} height="100%">
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Typography
          variant='body1'
          textAlign='left'
          color={theme.palette.primary.main}
        >
          <>Реестр передачи документации</>
        </Typography>

        <Stack spacing={0.25} borderLeft={`1px solid ${theme.palette.bg.gray}`} pl={1}>
          <Tooltip title='Количество накладных'>
            <Stack direction='row' alignItems='center' spacing={0.5}>
              <PackIcon fontSize='small' color='primary' />
              <Typography variant='body2' color={theme.palette.primary.main} pt='3px'>{total || 0}</Typography>
            </Stack>
          </Tooltip>
        </Stack>
      </Stack>

      <Stack flex={1} justifyContent='space-between' spacing={3}>
        <InfoBlockWrapper flex={1} justifyContent='center'>
          <InfoCardButton
            variant='text'
            onClick={(e) => onIncomingClick(e)}
            disabled={!incoming}
            endIcon={<Typography variant='body2'>{incoming || 0}</Typography>}
          >
            Входящие накладные
          </InfoCardButton>

          <InfoCardButton
            variant='text'
            onClick={(e) => onOutgoingClick(e)}
            disabled={!outgoing}
            endIcon={<Typography variant='body2'>{outgoing || 0}</Typography>}
          >
            Исходящие накладные
          </InfoCardButton>
        </InfoBlockWrapper>
      </Stack>
    </MediumTemplateWrapper>
  )
}
