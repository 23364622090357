import { MouseEvent } from 'react'
import { Stack, Typography } from "@mui/material"
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { MediumTemplateWrapper } from "../InfoCard/InfoCard.styles"
import { theme } from "../../../../styles/theme"
import { InfoBlockWrapper, InfoCardButton } from "../PhaseCardContent"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from '../../../../store/store'
import { setCurrentRoadmap, setRoadmapFormMode } from '../../../../store/slices/agreement'
import { Roadmap } from '../../../../api/projectAgreement/types'
import { useGetAgreementSchemesCountQuery } from '../../../../api/projects'

export const AgreementSchemes = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const onInternalAgreementClick = (e: MouseEvent) => {
    e.stopPropagation()
    navigate(`/project/${projectId}/schemes/innermap`)
  }

  const onAgreementClick = (e: MouseEvent) => {
    e.stopPropagation()
    dispatch(setCurrentRoadmap({} as Roadmap))
    dispatch(setRoadmapFormMode({ roadmapFormMode: 'view' }))
    navigate(`/project/${projectId}/schemes/roadmap`)
  }

  const { data: agreementSchemesCount } = useGetAgreementSchemesCountQuery({ id: projectId })

  return (
    <MediumTemplateWrapper height="100%" onClick={(e) => onInternalAgreementClick(e)}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Typography
          variant='body1'
          textAlign='left'
          color={theme.palette.primary.main}
        >
          <>Схемы согласования</>
        </Typography>
      </Stack>

      <Stack flex={1} justifyContent='space-between' spacing={3}>
        <InfoBlockWrapper flex={1} justifyContent='center'>
          <InfoCardButton
            variant='text'
            onClick={(e) => onAgreementClick(e)}
            /* disabled={!incoming} */
            endIcon={<Typography variant='body2'>{agreementSchemesCount?.roadmaps}</Typography>}
          >
            Внешние схемы
          </InfoCardButton>

          <InfoCardButton
            variant='text'
            onClick={(e) => onInternalAgreementClick(e)}
            /* disabled={!outgoing} */
            endIcon={<Typography variant='body2'>{agreementSchemesCount?.innermaps}</Typography>}
          >
            Внутренние схемы
          </InfoCardButton>
        </InfoBlockWrapper>
      </Stack>
    </MediumTemplateWrapper>
  )
}