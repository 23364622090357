import UploadDrawer from "@components/UploadDrawer"
import { TimUploadDrawerProps } from "."
import useConfirmDialog, { UseExitConfirmProps } from "@hooks/useConfirmDialog"
import { ExcelUploadResponse } from "@typesGlobal/global"
import { useState } from "react"
import { useGetTimExcelExampleMutation, useUploadTimExcelMutation } from "@api/tim"
import { useParams } from "react-router-dom"
import { useMutationHandlers } from "@hooks/useMutationHandlers"
import { GetTimExcelExampleResponse } from "@api/tim/api.types"

export const TimUploadDrawer = ({ open, close }: TimUploadDrawerProps) => {
  const { projectId: projectIdString } = useParams()
  const id = Number(projectIdString)
  const [responseData, setResponseData] = useState<ExcelUploadResponse | undefined>(undefined)

  const [getTimExcelExample, { isLoading, ...getTimExcelExampleResponse }] = useGetTimExcelExampleMutation()
  const [uploadTimExcel, uploadTimExcelResponse] = useUploadTimExcelMutation()

  const onFormChange = () => {
    setResponseData(undefined)
  }

  const onSubmit = (value: File[]) => {
    uploadTimExcel({ id, file: value[0] })
  }

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      close()
      setResponseData(undefined)
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
    denyButtonText: 'Отменить',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onCloseDrawer = (dirty: boolean, immediately?: boolean) => {
    if (immediately || !dirty) {
      close()
      setResponseData(undefined)
    } else {
      openConfirm()
    }
  }

  useMutationHandlers(
    getTimExcelExampleResponse,
    (data: GetTimExcelExampleResponse) => {
      window.location.href = data.data
    }
  )

  useMutationHandlers(
    uploadTimExcelResponse,
    (data) => {
      setResponseData(data)
    }
  )

  return (
    <>
      <UploadDrawer
        open={open}
        mode='single'
        onClose={onCloseDrawer}
        title='Импорт ТИМ-моделей'
        onSubmit={onSubmit}
        onChange={onFormChange}
        responseData={responseData}
        text='Вы можете добавить модели с помощью exсel документа с уже подготовленными данными.'
        attentionText={<>В случае если у Вас будут совпадения наименований существующих моделей с новыми из файла, новые значения
          заменят существующие.<br />
          Это действие нельзя отменить.</>}
        uploadData={{
          title: 'Загрузить xls(х)',
          text: 'Чтобы загрузить документ, выберите его из папки на компьютере или просто перетяните его в эту область.',
          formats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel']
        }}
        loading={isLoading}
        getExampleLink={() => getTimExcelExample({ id })}
      />
      <ConfirmDialog />
    </>
  )
}