import { Button, Popover } from "@mui/material";
import { StyledToggleButton, StyledToggleButtonGroup } from "@pages/Docs";
import styled from "styled-components";

export const HelpButton = styled(Button)`
  height: 40px;
  min-width: 40px;
  padding: 5px 10px;
  background-color: ${props => props.theme.palette.bg.lightBlue};
  color: ${props => props.theme.palette.primary.main};
  border: 1px solid transparent;

  :hover {
    background-color: #0044b40a;
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`

export const StyledPopover = styled(Popover)`
 .MuiPopover-paper {
    max-width: 427px;
 }
`
export const CreateRemarkButton = styled(Button)`
  border-color: ${props => props.theme.palette.primaryBorderLight};
  background-color: ${props => props.theme.palette.bg.lightBlue};
  :hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`

export const RemarkSelector = styled(StyledToggleButtonGroup)`
`
export const RemarkSelectorButton = styled(StyledToggleButton)`
`
export const ResetButton = styled(CreateRemarkButton)`
  height: 30px;
  margin-top: 10px;
  text-transform: none;
  font-weight: 400;
  display: flex;
  justify-self: flex-end;
`