import { IconButton, ListItemButton, TextField } from "@mui/material";
import styled from "styled-components";

export const StyledIconButton = styled(IconButton)`
  padding: 4px 6px;
  box-sizing: content-box;
  height: 20px;
  background-color: inherit;

  &:hover {
    background-color: ${props => props.theme.palette.bg.lightBlue};
  }
`
export const ReamarkListItemButton = styled(ListItemButton)`
  font-size: 16px;
  padding-left: 32px;
`
export const ReamarSearchField = styled(TextField)`
  padding: 6px;

  & .MuiInputBase-input {
    padding: 8px 16px 8px 8px;
  }

  & .MuiInputBase-root {
    height: 36px;
    border-radius: 6px;
    padding: 0;
    color: ${props => props.theme.palette.secondary.dark};

    & .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.secondary.main};
      border-width: 1px;
    }

    svg {
      margin-left: 8px;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.primary.main};
    }
  }
`