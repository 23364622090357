import { Divider, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ObjectAccordion } from '@pages/Docs/components/ObjectAccordion'
import { useGetRemarkFilterGroupsQuery } from '../../../../api/remarks'
import useQuery from '../../../../hooks/useQuery'
import { selectedProjectPhaseSelector, setSelectedProjectPhase } from '../../../../store/slices/documentsPages/projectInfo'
import { setRemarkGroupFilter, setRemarkPhaseFilter } from '../../../../store/slices/remarks/remarks'
import { remarkGroupFilterSelector, remarkPhaseFilterSelector } from '../../../../store/slices/remarks/selectors/remarks.selectors'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { ScrollableContainer } from '../../../../styles/global/ScrollableContainer'
import { allProjectsPhasesShort } from '../../../../types/project'
import { TomType, determineTomType, projectPhaseByTomType } from '../../../../types/tom'
import { RemarksFilterAccordion } from '../RemarksFilterAccordion'
import { AllObjectsButton, StyledToggleButton, StyledToggleButtonGroup } from './RemarkFilter.styles'
import { useGetRemarkFilterGroups } from './hooks/useGetRemarkFilterGroups'
import FolderIcon from '@assets/icons/FolderIcon'
import { RemarkType } from '@api/remarks/types'

export const RemarkFilter = ({ remarkSelector }: { remarkSelector: RemarkType }) => {
  const dispatch = useAppDispatch()
  const queryHandler = useQuery()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const remarkPhase = useTypedSelector(remarkPhaseFilterSelector)
  const groupFilter = useTypedSelector(remarkGroupFilterSelector)

  const { data: groupsRes } = useGetRemarkFilterGroupsQuery({ projectId, type: determineTomType(selectedProjectPhase), direction: remarkSelector })
  const { authors = [], menu: remarkGroups, type, executors = [], responsible = [] } = groupsRes || {}

  const currentPhase: TomType | undefined = queryHandler.get('phase') || type

  const { navbarItems } = useGetRemarkFilterGroups(remarkGroups)

  const onViewPhaseChange = (value: TomType) => {
    if (value !== null) {
      dispatch(
        setRemarkGroupFilter({
          remarkGroupFilter: { ...groupFilter, obj: null, group: null, authors: null, responsible: null, executors: null },
        }),
      )
      dispatch(setRemarkPhaseFilter({ remarkPhaseFilter: value }))
      dispatch(setSelectedProjectPhase({ selectedProjectPhase: projectPhaseByTomType[value] }))
      queryHandler.set('phase', value)
    }
  }

  const selectAll = () => {
    dispatch(setRemarkGroupFilter({
      remarkGroupFilter: { ...groupFilter, group: null, obj: null }
    }))
  }

  useEffect(() => {
    if (currentPhase) {
      dispatch(
        setRemarkGroupFilter({
          remarkGroupFilter: { ...groupFilter, second: groupFilter.second },
        }),
      )
      dispatch(setRemarkPhaseFilter({ remarkPhaseFilter: currentPhase }))
      dispatch(setSelectedProjectPhase({ selectedProjectPhase: projectPhaseByTomType[currentPhase] }))
    }
  }, [])

  return (
    <Stack padding='20px 0 20px 20px' spacing={2.5} overflow='hidden' flex={1}>
      <Stack spacing={1.5} pr={2.5}>
        <StyledToggleButtonGroup
          value={remarkPhase}
          exclusive
          fullWidth
          onChange={(e, value) => onViewPhaseChange(value)}
        >
          {allProjectsPhasesShort.map((phase) => (
            <StyledToggleButton value={phase} key={phase}>
              <Typography width={24} height={24} variant='buttonMedium' lineHeight='25px'>
                {phase}
              </Typography>
            </StyledToggleButton>
          ))}
        </StyledToggleButtonGroup>

        <RemarksFilterAccordion authors={authors} responsible={responsible} executors={executors} />

        <Divider sx={{ borderColor: '#CAD3F4' }} />
      </Stack>
      <ScrollableContainer spacing={1.5}>
        <AllObjectsButton variant='text' startIcon={<FolderIcon fontSize='small' color='primary' />} onClick={selectAll}>
          {remarkPhase === 'РД' ? 'Все объекты' : 'Все разделы'}
        </AllObjectsButton>
        {navbarItems?.map((navbarItem) => {
          return <ObjectAccordion {...navbarItem} key={navbarItem.id} />
        })}
      </ScrollableContainer>
    </Stack>
  )
}
