import { Stack, Typography } from '@mui/material'
import React, { FC, useEffect, useLayoutEffect, useRef, useState, useTransition } from 'react'
import {
  GroupRatioValueWrapper,
  GroupRow,
  RatioValueWrapper,
  ShowWorksIconButton,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTablePaper,
  StyledTableRow
} from './WorksTable.styles'
import {
  CommonWorksTableColumn,
  DayCell,
  DURATION_CELL_WIDTH,
  GROUP_CELL_DEFAULT_COLSPAN,
  MonthCell,
  MonthCellTemplate,
  MOVE_CELL_OFFSET,
  MOVE_CELL_WIDTH,
  PERIOD_CELL_WIDTH,
  PERSON_CELL_WIDTH,
  QuarterCell,
  QuarterCellTemplate,
  RATIO_CELL_WIDTH,
  RATIO_ITEM_CELL_WIDTH,
  RatioBottomColumn,
  STATUS_CELL_OFFSET,
  STATUS_CELL_WIDTH,
  TITLE_CELL_OFFSET,
  TITLE_CELL_WIDTH,
  WorksTableColumn,
  WorksTableProps,
  YearDayCell
} from './WorksTable.types'
import { TitleCell } from './cells/TitleCell'
import { StatusCell } from './cells/StatusCell'
import { PersonCell } from './cells/PersonCell'
import { PeriodCell } from './cells/PeriodCell'
import { DurationCell } from './cells/DurationCell'
import { GraphCell } from './cells/GraphCell'
import { monthsColumnsTemplate, parseDateForCell, quarterColumnsTemplate } from './WorksTable.utils'
import { formatToDate } from '../../../../utils/formatDate'
import { format, getDaysInMonth, intervalToDuration, isEqual } from 'date-fns'
import { ru } from 'date-fns/locale'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import useQuery from '../../../../hooks/useQuery'
import { MoveCell } from './cells/MoveCell'
import { useParams } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Tooltip from '../../../../components/Tooltip'
import { RatioCell } from './cells/RatioCell'
import { GetGroupedWorksRequest, useDeleteGroupMutation, Work, WorkRatio, worksApi } from '../../../../api/works'
import { RootState, store, useAppDispatch } from '../../../../store/store'
import { GroupTitleCell } from './cells/GroupTitleCell'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../hooks/useConfirmDialog'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'


const defaultColSpan = 1 // title cells
const ratioKeysArray: (keyof WorkRatio)[] = ['total', 'weight']
const ratioColsCount = ratioKeysArray.length // ready, total, weight cells
const maxColSpan = 1000

const localDate = parseDateForCell(new Date())
const {
  year: localYear,
  quarter: localQuarter,
  month: localMonth,
  day: localDay,
} = localDate

export const WorksTable: FC<WorksTableProps> = ({
  data,
  onRowClick,
  tableView,
  entirePeriod,
  overallRatio,
}) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [showWorks, setShowWorks] = useState<boolean>(true)

  const showRatio = !!(overallRatio.ready && overallRatio.total && overallRatio.weight)

  const state = store.getState() as RootState
  const getGroupedWorksKeys = Object.keys(state.api.queries).filter(key => key.includes('getGroupedWorks'))
  const getGroupedWorksLastKey = getGroupedWorksKeys[getGroupedWorksKeys?.length - 1]

  const onShowWorksClick = () => {
    dispatch(
      worksApi.util.updateQueryData(
        'getGroupedWorks',
        state.api.queries[getGroupedWorksLastKey]?.originalArgs as GetGroupedWorksRequest,
        (draft) => {
          draft.data.forEach(group => {
            group.hideWorks = showWorks
          })
        }
      )
    )

    setShowWorks(prevState => !prevState)
  }

  const onHideGroupWorksClick = (changedGroupName: string, changedGroupNumber: number) => {
    dispatch(
      worksApi.util.updateQueryData(
        'getGroupedWorks',
        state.api.queries[getGroupedWorksLastKey]?.originalArgs as GetGroupedWorksRequest,
        (draft) => {
          const changedGroupIndex = draft.data.findIndex(group => (group.group === changedGroupName) && group.numGroup === changedGroupNumber)
          draft.data[changedGroupIndex].hideWorks = !draft.data[changedGroupIndex]?.hideWorks
          // Ошибка при переключении на другую страницу и назад и раскрытие группы
        }
      )
    )
  }

  useEffect(() => {
    const isEveryHidden = data.every((group) => !!group.hideWorks)
    const isEveryShow = data.every((group) => !group.hideWorks)
    if (isEveryHidden) {
      setShowWorks(false)
    }
    if (isEveryShow) {
      setShowWorks(true)
    }
  }, [data])

  const moveColumnData: CommonWorksTableColumn = {
    label: '',
    style: {
      position: 'sticky',
      left: MOVE_CELL_OFFSET,
      zIndex: 1,
      minWidth: MOVE_CELL_WIDTH,
    },
    className: 'moveCell',
    align: 'center'
  }

  const baseColumns: readonly WorksTableColumn[] = [
    {
      label: 'Наименование работ',
      CustomCell: TitleCell,
      style: {
        position: 'sticky',
        left: TITLE_CELL_OFFSET,
        zIndex: 1,
        minWidth: TITLE_CELL_WIDTH,
        width: 1000,
        maxWidth: 1000,
      },
      actions: [
        <Tooltip title={`${showWorks ? 'Скрыть' : 'Показать'} работы в группе`}>
          <ShowWorksIconButton onClick={onShowWorksClick}>
            {showWorks
              ? <VisibilityIcon fontSize='medium' />
              : <VisibilityOffIcon fontSize='medium' />
            }
          </ShowWorksIconButton>
        </Tooltip>
      ],
      className: 'titleCell',
      rowSpan: 2,
      align: 'left'
    },
    {
      label: 'Ответственный',
      SelfCell: PersonCell,
      style: {
        // position: 'sticky',
        // left: PERSON_CELL_OFFSET,
        // zIndex: 1,
        minWidth: PERSON_CELL_WIDTH,
        // width: PERSON_CELL_WIDTH,
      },
      rowSpan: 2,
      align: 'center'
    },
    {
      label: 'Период работ (План\u00A0/\u00A0Факт)',
      CustomCell: PeriodCell,
      style: {
        // position: 'sticky',
        // left: PERIOD_CELL_OFFSET,
        // zIndex: 1,
        minWidth: PERIOD_CELL_WIDTH,
        // width: PERIOD_CELL_WIDTH,
      },
      rowSpan: 2,
      align: 'center'
    },
    {
      label: 'Длительность календ. дни',
      CustomCell: DurationCell,
      style: {
        // position: 'sticky',
        // left: DURATION_CELL_OFFSET,
        // zIndex: 1,
        minWidth: DURATION_CELL_WIDTH,
        width: DURATION_CELL_WIDTH,
      },
      rowSpan: 2,
      align: 'center'
    },
  ]

  const ratioTopColumn: WorksTableColumn | null = showRatio
    ? {
      label: 'Готовность',
      CustomCell: DurationCell,
      style: {
        // position: 'sticky',
        // left: RATIO_CELL_OFFSET,
        // zIndex: 1,
        minWidth: RATIO_CELL_WIDTH,
        width: RATIO_CELL_WIDTH,
      },
      rowSpan: 1,
      colSpan: 3,
      align: 'center'
    }
    : null

  const statusColumn: WorksTableColumn = {
    label: 'Текущий статус',
    SelfCell: StatusCell,
    hover: true,
    style: {
      // position: 'sticky',
      // left: STATUS_CELL_OFFSET,
      // zIndex: 1,
      minWidth: STATUS_CELL_WIDTH,
      width: STATUS_CELL_WIDTH,
    },
    rowSpan: 2,
    align: 'center'
  }

  const ratioBottomColumns: RatioBottomColumn[] = showRatio
    ? [
      {
        label: 'Выполнение',
        // data: overallRatio?.ready,
        dataVariant: 'ready',
        SelfCell: RatioCell,
        style: {
          // position: 'sticky',
          // left: RATIO_CELL_OFFSET,
          // zIndex: 1,
          minWidth: RATIO_ITEM_CELL_WIDTH,
          width: RATIO_ITEM_CELL_WIDTH,
        },
        align: 'center',
      },
      {
        label: 'Общая',
        data: overallRatio?.total,
        dataVariant: 'total',
        SelfCell: RatioCell,
        style: {
          // position: 'sticky',
          // left: RATIO_CELL_OFFSET + RATIO_ITEM_CELL_WIDTH,
          // zIndex: 1,
          minWidth: RATIO_ITEM_CELL_WIDTH,
          width: RATIO_ITEM_CELL_WIDTH,
        },
        align: 'center',
      },
      {
        label: 'Вес',
        data: overallRatio?.weight,
        dataVariant: 'weight',
        SelfCell: RatioCell,
        style: {
          // position: 'sticky',
          // left: RATIO_CELL_OFFSET + RATIO_ITEM_CELL_WIDTH * 2,
          // zIndex: 1,
          minWidth: RATIO_ITEM_CELL_WIDTH,
          width: RATIO_ITEM_CELL_WIDTH,
        },
        align: 'center',
      },
    ]
    : []

  const { projectId: projectIdStr, scheduleId: scheduleIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)
  const scheduleId = parseInt(scheduleIdStr!)

  const queryHandler = useQuery()
  const [sourceGroup, setSourceGroup] = useState<string | null>(queryHandler.get('group'))
  const [sourceWorkIdStr, setSourceWorkIdStr] = useState<string | null>(queryHandler.get('workId'))
  const sourceWorkId = !!sourceWorkIdStr && Number(sourceWorkIdStr)

  const { dateStart: entireStartDate, dateEnd: entireEndDate } = entirePeriod
  const {
    year: entireStartYear,
    quarter: entireStartQuarter,
    month: entireStartMonth,
    day: entireStartDay,
  } = parseDateForCell(formatToDate(entireStartDate)!)
  const {
    year: entireEndYear,
    quarter: entireEndQuarter,
    month: entireEndMonth,
    day: entireEndDay,
  } = parseDateForCell(formatToDate(entireEndDate)!)

  const yearsCount = (intervalToDuration({
    start: new Date(entireStartYear, 0),
    end: new Date(entireEndYear, 0),
  }).years || 0) + 1

  const yearsArray = Array(yearsCount).fill(0).map((_, index) => entireStartYear + index)

  const yearQuartersColumns: CommonWorksTableColumn[] = yearsArray.map(year => {
    let colSpan = 4

    if (year === entireStartYear) {
      colSpan = colSpan - entireStartQuarter + 1
    } else if (year === entireEndYear) {
      colSpan = entireEndQuarter
    }

    return {
      label: `${year}`,
      style: {
        minWidth: 136,
        width: 136,
        maxWidth: 136,
      },
      colSpan,
      align: 'center'
    }
  })

  const yearMonthsColumns: CommonWorksTableColumn[] = yearsArray.map(year => {
    let colSpan = 12

    if (year === entireStartYear) {
      colSpan = colSpan - entireStartMonth + 1
    } else if (year === entireEndYear) {
      colSpan = entireEndMonth
    }

    return {
      label: `${year}`,
      style: {
        minWidth: 136,
        width: 136,
        maxWidth: 136,
      },
      colSpan,
      align: 'center'
    }
  })

  const yearDaysColumns: YearDayCell[] = yearsArray.map(year => {
    return monthsColumnsTemplate
      .filter(({ month }) => !((year === entireStartYear && month < entireStartMonth)
        || (year === entireEndYear && month > entireEndMonth)))
      .map<YearDayCell>(({ month }) => {
        const currentDate = new Date(year, month - 1)
        const monthName = capitalizeFirstLetter(format(currentDate, 'LLLL', { locale: ru }))
        const daysInMonth = getDaysInMonth(currentDate)

        let colSpan = daysInMonth

        if (year === entireStartYear && month === entireStartMonth) {
          colSpan = daysInMonth - entireStartDay + 1
        } else if (year === entireEndYear && month === entireEndMonth) {
          colSpan = entireEndDay
        }

        return {
          label: `${monthName}, ${year}`,
          year,
          month,
          colSpan,
          align: 'center'
        }
      })
  }).flat()

  const getFilteredQuarterColumns = (year: number): QuarterCellTemplate[] => {
    return quarterColumnsTemplate.filter(({ quarter }) => {
      if (year === entireStartYear) {
        return quarter >= entireStartQuarter
      } else if (year === entireEndYear) {
        return quarter <= entireEndQuarter
      }

      return true
    })
  }

  const quarterColumns: QuarterCell[] = yearQuartersColumns.map(({ colSpan, label: yearString }) => {
    const year = Number(yearString)
    return getFilteredQuarterColumns(year).map<QuarterCell>(({ quarter, label: quarterName, ...column }) => {
      const isCurrentDate = year === localYear && quarter === localQuarter

      return {
        label: quarterName,
        quarter,
        year,
        isCurrentDate,
        ...column,
      }
    })
  }).flat()

  const getFilteredMonthColumns = (year: number): MonthCellTemplate[] => {
    return monthsColumnsTemplate.filter(({ month }) => {
      if (year === entireStartYear) {
        return month >= entireStartMonth
      } else if (year === entireEndYear) {
        return month <= entireEndMonth
      }

      return true
    })
  }

  const monthColumns: MonthCell[] = yearMonthsColumns.map(({ colSpan, label: yearString }) => {
    const year = Number(yearString)
    return getFilteredMonthColumns(year).map<MonthCell>(({ month, label: monthName, ...column }) => ({
      label: monthName,
      month,
      year: Number(yearString),
      isCurrentDate: isEqual(
        new Date(year, month),
        new Date(localYear, localMonth),
      ),
      ...column,
    }))
  }).flat()

  const daysColumns: DayCell[] = yearDaysColumns.map(({ colSpan, year, month }) => {
    const daysInMonth = colSpan
    let startDay = 1

    if (year === entireStartYear && month === entireStartMonth) {
      startDay = entireStartDay
    }

    return Array(daysInMonth).fill(startDay).map((day, index) => ({
      label: day + index,
      day: day + index,
      month,
      year,
      isCurrentDate: isEqual(
        new Date(year, month, day + index),
        new Date(localYear, localMonth, localDay),
      ),
      style: { minWidth: 30, width: 30 }
    }))
  }).flat()


  let topColumns: CommonWorksTableColumn[] = []
  switch (tableView) {
    case 'quarters':
      topColumns = yearQuartersColumns
      break
    case 'months':
      topColumns = yearMonthsColumns
      break
    case 'days':
      topColumns = yearDaysColumns
      break
  }

  let bottomColumns: (QuarterCell | MonthCell | DayCell)[] = []
  switch (tableView) {
    case 'quarters':
      bottomColumns = quarterColumns
      break
    case 'months':
      bottomColumns = monthColumns
      break
    case 'days':
      bottomColumns = daysColumns
      break
  }

  // Scroll
  // Vertical
  const sourceGroupRowRef = useRef<HTMLTableRowElement | null>(null)
  const sourceWorkRowRef = useRef<HTMLTableRowElement | null>(null)

  const currentDateRef = useRef<HTMLTableCellElement | null>(null)
  const tableRef = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    (sourceWorkRowRef?.current || sourceGroupRowRef?.current)?.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    })
  }, [sourceGroupRowRef, sourceWorkRowRef, tableView])

  const [_, startTransition] = useTransition()

  // remove highlight after scroll
  useEffect(() => {
    const tableScrollYAfterScroll = tableRef?.current?.scrollTop || 0

    const removeSourceData = () => {
      const currentScrollY = tableRef?.current?.scrollTop

      startTransition(() => {
        if (currentScrollY !== tableScrollYAfterScroll) {
          setSourceGroup(null)
          setSourceWorkIdStr(null)
          tableRef?.current?.removeEventListener('scroll', removeSourceData)
        }
      })
    }

    tableRef?.current?.addEventListener('scroll', removeSourceData)
  }, [])

  // Horizontal
  const infoColumnsCount = baseColumns.length + ratioColsCount + 1 // 1 - status column
  const columnsCount = infoColumnsCount + bottomColumns.length // 1 - status column
  const emptyGroupRowCount = Math.floor(columnsCount / maxColSpan) || 0
  const groupCellColSpan = columnsCount < GROUP_CELL_DEFAULT_COLSPAN
    ? columnsCount
    : GROUP_CELL_DEFAULT_COLSPAN

  useLayoutEffect(() => {
    const baseTableWidth = STATUS_CELL_OFFSET + STATUS_CELL_WIDTH
    const graphTableWidth = (tableRef?.current?.clientWidth || 1800) - baseTableWidth
    const scrollOffsetToCurrentDate = baseTableWidth + (graphTableWidth / 2)
    const tableScrollOffset = (currentDateRef?.current?.offsetLeft || 0) - scrollOffsetToCurrentDate

    tableRef?.current?.scrollTo({
      behavior: 'auto',
      left: tableScrollOffset,
    })
  }, [currentDateRef, tableRef, tableView])

  // confirm delete group
  const [deleteGroup, deleteGroupResponse] = useDeleteGroupMutation()

  const handleDeleteGroupConfirm = (confirm: boolean, group: string, numGroup: number) => {
    if (confirm) {
      deleteGroup({ id: projectId, scheduleId, group, numGroup })
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: handleDeleteGroupConfirm,
    title: 'Удалить группу работ?',
    body: <>При удалении группы будут удалены все включённые в неё работы.</>,
    confirmButtonColor: 'error'
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  useMutationHandlers(
    deleteGroupResponse,
    () => {
      enqueueSnackbar('Группа успешно удалена', { variant: 'success' })
    },
    () => {
      enqueueSnackbar('Группа не удалена, попробуйте еще раз', { variant: 'error' })
    }
  )

  return (
    <>
      <StyledTablePaper>
        <StyledTableContainer ref={tableRef} sx={{ maxHeight: 440 }}>
          <StyledTable>
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableCell {...moveColumnData} rowSpan={2}>
                  <Stack
                    direction='row'
                    justifyContent={moveColumnData.align}
                    alignItems='center'
                    spacing={1}
                  >
                    {moveColumnData.actions?.length && (
                      <Stack direction='row' alignItems='center' spacing={1}>
                        {moveColumnData.actions.map(action => action)}
                      </Stack>
                    )}
                  </Stack>

                </StyledTableCell>

                {[...baseColumns, ratioTopColumn || [], statusColumn].flat().map((column, index) => (
                  <StyledTableCell
                    key={column.id}
                    {...column}
                    style={{
                      ...column.style,
                      ...(index === (baseColumns.length + 1 + (ratioTopColumn ? 1 : 0)) - 1 // 1 - statusColumn, 1||0 - ratioColumn
                        ? {
                          boxShadow: `inset 1px 0 0 rgba(0, 36, 95, 0.1), inset -1px 0 0 rgba(0, 36, 95, 0.1)`, // for last column
                        }
                        : index === 1 // for first unpinned column
                          ? { boxShadow: 'none' }
                          : {}
                      )
                    }}
                    hover={false}
                  >
                    <Stack
                      direction='row'
                      justifyContent={column.actions?.length ? 'space-between' : column.align}
                      alignItems='center'
                      spacing={1}
                    >
                      <Typography style={{ textAlign: column.align }} variant='subtitle2'>
                        {column.label}
                      </Typography>

                      {column.actions?.length && (
                        <Stack direction='row' alignItems='center' spacing={1}>
                          {column.actions.map(action => action)}
                        </Stack>
                      )}
                    </Stack>
                  </StyledTableCell>
                ))}

                {topColumns.map(({ label, colSpan }, index) => (
                  <StyledTableCell
                    colSpan={colSpan}
                    style={index === 0
                      ? { boxShadow: 'none' }
                      : {}
                    }
                  >
                    <Typography variant='subtitle2'>
                      {label}
                    </Typography>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
              <StyledTableRow>
                {ratioBottomColumns.map((column, index) => (
                  <StyledTableCell
                    key={column.id}
                    {...column}
                    style={{
                      ...column.style,
                    }}
                    noPadding
                  >
                    <Stack direction='row' justifyContent='center' spacing={1}>
                      <Typography variant='subtitle2'>
                        {column.label}
                      </Typography>
                      {(column.data !== undefined) && (
                        <RatioValueWrapper>
                          {new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 }).format(column.data || 0)}%
                        </RatioValueWrapper>
                      )}

                    </Stack>
                  </StyledTableCell>
                ))}

                {bottomColumns.map((column, index) => (
                  <StyledTableCell
                    key={column.id}
                    {...column}
                    style={{
                      ...column.style,
                      ...(index === 0
                        ? { boxShadow: 'inset 0px 1px 0px rgba(0,36,95,0.1)' }
                        : {}
                      )
                    }}
                    dark={column.isCurrentDate}
                    ref={column.isCurrentDate ? currentDateRef : undefined}
                    noPadding
                  >
                    <Typography variant='subtitle2'>
                      {column.label}
                    </Typography>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </StyledTableHead>
            <StyledTableBody>
              {data.map((
                {
                  group,
                  numGroup,
                  ratio: groupRatio,
                  period: groupPeriod,
                  hideWorks: groupHideWorks,
                  works
                },
                groupIndex
              ) => {
                const isSourceGroup = group === sourceGroup

                return (
                  <>
                    <GroupRow
                      ref={isSourceGroup ? sourceGroupRowRef : undefined}
                      focused={isSourceGroup}
                      onClick={() => onHideGroupWorksClick(group, numGroup)}
                    >
                      <MoveCell
                        moveVariant='group'
                        projectId={projectId}
                        numGroup={numGroup}
                        group={group}
                        disableUp={groupIndex === 0}
                        disableDown={groupIndex === data.length - 1}
                      />

                      <GroupTitleCell
                        onDeleteClick={openConfirm}
                        defaultColSpan={defaultColSpan}
                        numGroup={numGroup}
                        group={group}
                      />

                      {/* empty cell for person cell */}
                      <StyledTableCell colSpan={1}></StyledTableCell>

                      <StyledTableCell>
                        <PeriodCell
                          work={{ period: groupPeriod } as Work}
                        />
                      </StyledTableCell>

                      <StyledTableCell align='center'>
                        <DurationCell
                          work={{ period: groupPeriod } as Work}
                        />
                      </StyledTableCell>

                      {/* empty cell for duration and complete ratio cells */}
                      <StyledTableCell colSpan={1}></StyledTableCell>

                      {showRatio &&
                        ratioKeysArray.map((key, index) => (
                          <StyledTableCell
                            colSpan={1}
                            className='ratioCell'
                          //style={{ left: `${RATIO_CELL_OFFSET + index * RATIO_ITEM_CELL_WIDTH}px` }}
                          >
                            <GroupRatioValueWrapper>
                              {new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 }).format(groupRatio[key] || 0)}%
                              {/*{+(groupRatio[key] || 0).toFixed(2)}%*/}
                            </GroupRatioValueWrapper>
                          </StyledTableCell>
                        ))}

                      {/* status Column */}
                      {showRatio && <StyledTableCell colSpan={1}></StyledTableCell>}

                      {/* Quarters table view */}
                      {tableView === 'quarters' && yearQuartersColumns.map(yearColumn => {
                        const year: number = Number(yearColumn.label)

                        return getFilteredQuarterColumns(year).map(quarterColumn => (
                          <StyledTableCell
                            align={quarterColumn.align}
                            noPadding
                            className='graphCell'
                            key={`group-${group}_graph_year-${year}_quarter-${quarterColumn.label}`}
                          >
                            <GraphCell
                              work={{ period: groupPeriod } as Work}
                              tableView='quarters'
                              year={year}
                              quarter={quarterColumn.quarter}
                            />
                          </StyledTableCell>
                        ))
                      })}

                      {/* Months table view */}
                      {tableView === 'months' && yearMonthsColumns.map(yearColumn => {
                        const year: number = Number(yearColumn.label)

                        return getFilteredMonthColumns(year).map(monthColumn => (
                          <StyledTableCell
                            align={monthColumn.align}
                            noPadding
                            className='graphCell'
                            key={`group-${group}_graph_year-${year}_month-${monthColumn.label}`}
                          >
                            <GraphCell
                              work={{ period: groupPeriod } as Work}
                              tableView='months'
                              year={year}
                              month={monthColumn.month}
                            />
                          </StyledTableCell>
                        ))
                      })}

                      {/* Days table view */}
                      {tableView === 'days' && daysColumns.map(({ year, month, day, ...dayColumn }) => (
                        <StyledTableCell
                          align={dayColumn.align}
                          noPadding
                          className='graphCell'
                          key={`group-${group}_graph_year-${year}_month-${month}_day-${day}`}
                        >
                          <GraphCell
                            work={{ period: groupPeriod } as Work}
                            tableView='days'
                            year={year}
                            month={month}
                            day={day}
                          />
                        </StyledTableCell>
                      ))}

                      {/*/!* empty cell for reach 20 colspan *!/*/}
                      {/*<StyledTableCell colSpan={GROUP_CELL_DEFAULT_COLSPAN - infoColumnsCount}></StyledTableCell>*/}

                      {/*{groupCellColSpan === GROUP_CELL_DEFAULT_COLSPAN && (*/}
                      {/*  <StyledTableCell colSpan={maxColSpan - groupCellColSpan}></StyledTableCell>*/}
                      {/*)}*/}

                      {/*{Array(emptyGroupRowCount).fill(0).map(() => (*/}
                      {/*  <StyledTableCell colSpan={maxColSpan}></StyledTableCell>*/}
                      {/*))}*/}
                    </GroupRow>

                    {!groupHideWorks && works.map((work, workIndex) => {
                      const isSourceWork = work.id === sourceWorkId

                      return (
                        <StyledTableRow
                          onClick={() => onRowClick(work.id)}
                          ref={isSourceWork ? sourceWorkRowRef : undefined}
                          focused={isSourceWork}
                          hover
                          tabIndex={-1}
                          key={`${group}-${work.id}`}
                        >
                          <MoveCell
                            moveVariant='work'
                            projectId={projectId}
                            numGroup={numGroup}
                            numWork={work.numWork}
                            workId={work.id}
                            disableUp={workIndex === 0}
                            disableDown={workIndex === works.length - 1}
                            {...moveColumnData}
                          />

                          {[...baseColumns, ...ratioBottomColumns, statusColumn].map(({
                            field,
                            SelfCell,
                            CustomCell,
                            rowSpan,
                            ...column
                          }, index) => {
                            if (SelfCell) {
                              return (
                                <SelfCell work={work} {...column} key={`${group}-${work.id}-render-${index}`} />
                              )
                            } else if (CustomCell) {
                              return (
                                <StyledTableCell {...column} key={`${group}-${work.id}-render-${index}`}>
                                  <CustomCell work={work} />
                                </StyledTableCell>
                              )
                            } else if (field) {
                              return (
                                <StyledTableCell {...column} key={`${group}-${work.id}-${field}`}>
                                  <Typography variant='body2'>
                                    {work[field]}
                                  </Typography>
                                </StyledTableCell>
                              )
                            } else {
                              return (
                                <StyledTableCell {...column} key={`${group}-${work.id}-empty-${index}`}>
                                  <Typography variant='body2'>
                                    —
                                  </Typography>
                                </StyledTableCell>
                              )
                            }
                          })}

                          {/* Quarters table view */}
                          {tableView === 'quarters' && yearQuartersColumns.map(yearColumn => {
                            const year: number = Number(yearColumn.label)
                            return getFilteredQuarterColumns(year).map(quarterColumn => (
                              <StyledTableCell
                                align={quarterColumn.align}
                                noPadding
                                key={`group-${group}_work-${work.id}_year-${year}_quarter-${quarterColumn.label}`}
                              >
                                <GraphCell
                                  work={work}
                                  tableView='quarters'
                                  year={year}
                                  quarter={quarterColumn.quarter}
                                />
                              </StyledTableCell>
                            ))
                          })}

                          {/* Months table view */}
                          {tableView === 'months' && yearMonthsColumns.map(yearColumn => {
                            const year: number = Number(yearColumn.label)

                            return getFilteredMonthColumns(year).map(monthColumn => (
                              <StyledTableCell
                                align={monthColumn.align}
                                noPadding
                                key={`group-${group}_work-${work.id}_year-${year}_month-${monthColumn.label}`}
                              >
                                <GraphCell
                                  work={work}
                                  tableView='months'
                                  year={year}
                                  month={monthColumn.month}
                                />
                              </StyledTableCell>
                            ))
                          })}

                          {/* Days table view */}
                          {tableView === 'days' && daysColumns.map(({ year, month, day, ...dayColumn }) => (
                            <StyledTableCell
                              align={dayColumn.align}
                              noPadding
                              key={`group-${group}_work-${work.id}_year-${year}_month-${month}_day-${day}`}
                            >
                              <GraphCell
                                work={work}
                                tableView='days'
                                year={year}
                                month={month}
                                day={day}
                              />
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      )
                    })}
                  </>
                )
              })}
            </StyledTableBody>
          </StyledTable>
        </StyledTableContainer>
      </StyledTablePaper>

      <ConfirmDialog />
    </>
  )
}
