import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { setRemarkGroupFilter } from '@store/slices/remarks/remarks'
import { useGetProjectByIdQuery, useGetProjectsQuery } from '../../api/projects'
import { GetProjectsResponse } from '../../api/projects/api.types'
import { useGetProjectGroupedRemarksQuery, useGetProjectTomsQuery } from '../../api/remarks'
import { RemarkStatus, RemarkType } from '../../api/remarks/types'
import Header from '../../components/Header'
import Progress from '../../components/Progress'
import Tabs from '../../components/Tabs'
import { TabData } from '../../components/Tabs/Tabs.types'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import useQuery from '../../hooks/useQuery'
import { ConfirmDialog } from '../../layouts/DocumentsLayout/components/ConfirmDialog'
import { InnerContainer, MainContainer } from '../../layouts/HomeLayout/styles'
import { onDrawerClose, openedDrawerSelector, setOpenedDrawer } from '../../store/slices/documentsPages/drawerInfo'
import { selectedProjectPhaseSelector } from '../../store/slices/documentsPages/projectInfo'
import { remarkGroupFilterSelector, remarkPhaseFilterSelector } from '../../store/slices/remarks/selectors/remarks.selectors'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { determineTomType } from '../../types/tom'
import { remarkStatusEnToRu, RemarkStatusRu, remarkStatusRuArray, remarkStatusRuToEn } from '../DocView/components/DocViewRightSideBar/components/Remark'
import { CreateRemarkButton, HelpButton, RemarkSelector, RemarkSelectorButton, ResetButton, StyledPopover } from './RemarksPage.styles'
import { ManualSubmitDrawer } from './components/ManualSubmitDrawer'
import { RemarkFilterDrawer } from './components/RemarkFilterDrawer'
/* import { RemarkHistoryDrawer } from './components/RemarkHistoryDrawer' */ //RIP 03.06.2024-13.02.2025
import { RemarksManagmentMenu } from './components/RemarksManagmentMenu'
import { RemarksTable } from './components/RemarksTable'
import { RemarkFormDrawer } from './components/RemarkFormDrawer'

export const RemarksPage = () => {
  const navigate = useNavigate()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const dispatch = useAppDispatch()
  const queryHandler = useQuery()

  const [remarkSelector, setRemarkSelector] = useState<RemarkType>('incoming')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorElTooltip, setAnchorElTooltip] = useState<null | HTMLElement>(null)
  const MenuOpen = Boolean(anchorEl)
  const tooltipOpen = Boolean(anchorElTooltip)

  const {
    data: projectData,
    isLoading: projectLoading,
    isFetching: isProjectFetching,
  } = useGetProjectByIdQuery({ id: projectId })
  const { data: project } = projectData || {}
  const projectName = project?.shortName || ''
  const loading = projectLoading || isProjectFetching
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const phaseFilter = useTypedSelector(remarkPhaseFilterSelector)
  const groupFilter = useTypedSelector(remarkGroupFilterSelector)

  const { data: projectsData, isLoading: projectsLoading, isFetching: projectsFetching } = useGetProjectsQuery({})
  const { data: projects } = projectsData || ({} as GetProjectsResponse)

  const { data: projectToms } = useGetProjectTomsQuery({ projectId, type: determineTomType(selectedProjectPhase) })

  const selectedStatus: RemarkStatus | null = queryHandler.get('status')
  const defaultStatus: RemarkStatusRu = remarkSelector === 'incoming' ? 'Направлено' : 'Создано'
  const currentStatus: RemarkStatusRu = selectedStatus ? remarkStatusEnToRu[selectedStatus] : defaultStatus
  const [currentTab, setCurrentTab] = useState<RemarkStatusRu>(currentStatus)

  const { data: remarks } = useGetProjectGroupedRemarksQuery({
    projectId,
    direction: remarkSelector,
    status: remarkStatusRuToEn[currentTab],
    type: determineTomType(selectedProjectPhase),
    group: groupFilter.group,
    obj: groupFilter.obj,
    second: groupFilter.second,
    authors: groupFilter.authors,
    responsible: groupFilter.responsible,
    executors: groupFilter.executors
  })

  const ProjectBreadClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onMenuItemClick = useCallback(
    (projectId: number) => {
      dispatch(
        setRemarkGroupFilter({
          remarkGroupFilter: { ...groupFilter, obj: null, group: null, authors: null, responsible: null, executors: null },
        }),
      )
      navigate(`/project/${projectId}/remarks?phase=${phaseFilter}`)
      onClose()
    },
    [onClose, phaseFilter, groupFilter],
  )

  useBreadcrumbs(
    [
      {
        title: projectName,
        onClick: (e) => ProjectBreadClick(e),
        MenuOpen,
        type: 'project',
      },
      { title: 'Реестр замечаний' },
    ],
    [MenuOpen, projectName],
    loading,
  )

  const changeRemarkSelector = (value: RemarkType) => {
    if (value === null) return
    setRemarkSelector(value)
    dispatch(setRemarkGroupFilter({
      remarkGroupFilter: { ...groupFilter, obj: null, group: null, authors: null, responsible: null, executors: null },
    }),)
    const defaultValue: RemarkStatusRu = value === 'incoming' ? 'Направлено' : 'Создано'
    setCurrentTab(defaultValue)
    queryHandler.set('status', remarkStatusRuToEn[defaultValue])
  }

  useEffect(() => {
    if (remarkSelector === 'incoming' && selectedStatus === 'created') {
      setCurrentTab('Направлено')
      queryHandler.set('status', remarkStatusRuToEn['Направлено'])
    }
  }, [remarkSelector, selectedStatus])

  const onTabChange = (e: React.SyntheticEvent, tabValue: RemarkStatusRu) => {
    setCurrentTab(tabValue)
    phaseFilter && queryHandler.set('phase', phaseFilter)
    queryHandler.set('status', remarkStatusRuToEn[tabValue])
  }

  const tabsData: TabData<RemarkStatusRu>[] = useMemo(() => {
    if (!remarks) return []
    const tabs = remarkSelector === 'incoming' ? remarkStatusRuArray.slice(1) : remarkStatusRuArray
    return tabs.map((tabName) => {
      const remarkDataCount = remarks.count[remarkStatusRuToEn[tabName]]
      return {
        value: tabName,
        label: `${tabName} (${remarkDataCount})`,
        disabled: remarkDataCount === 0,
      }
    })
  }, [remarks, remarkSelector])

  const closeDrawer = (dirty: boolean, immediately?: boolean) => {
    dispatch(onDrawerClose({ dirty, immediately }))
  }

  const openAddRemarkDrawer = () => {
    dispatch(setOpenedDrawer({ openedDrawer: 'remarks' }))
    changeRemarkSelector('outgoing')
  }

  const resetTableSettings = () => {
    localStorage.removeItem('remarkColumnState')
    navigate(0)
  }

  return (
    <>
      <RemarkFilterDrawer remarkSelector={remarkSelector} />
      <MainContainer>
        <Header isHasSearch={false} />
        <InnerContainer>
          <Stack width='100%'>
            <Stack direction='row' pt={1} pb={1.75} spacing={1} justifyContent='space-between'>
              <>
                <Stack direction='row' spacing={1}>
                  <RemarkSelector
                    value={remarkSelector}
                    exclusive
                    color='primary'
                    onChange={(e, value) => changeRemarkSelector(value)}
                  >
                    <RemarkSelectorButton value='incoming' >Входящие</RemarkSelectorButton>
                    <RemarkSelectorButton value='outgoing' >Исходящие</RemarkSelectorButton>
                  </RemarkSelector>

                  {projectToms && projectToms.data.length > 0 && (
                    <CreateRemarkButton onClick={openAddRemarkDrawer} startIcon={<AddIcon />} variant='outlined' >
                      Создать замечание
                    </CreateRemarkButton>
                  )}
                </Stack>
                <Stack direction={'row'} spacing={1}>
                  <RemarksManagmentMenu
                    data={{
                      project,
                      selectedProjectPhase,
                      selectedStatus: remarkStatusRuToEn[currentTab],
                      remarkType: remarkSelector,
                      showManualSubmit: true,
                    }}
                  />

                  <HelpButton onClick={(e) => setAnchorElTooltip(anchorElTooltip ? null : e.currentTarget)}>
                    <HelpOutlineIcon />
                  </HelpButton>
                  <StyledPopover
                    open={tooltipOpen}
                    anchorEl={anchorElTooltip}
                    onClose={() => setAnchorElTooltip(null)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    <Box p={2}>
                      <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='h2' fontWeight={500} mb={1.5}>
                          Настройка отображения таблицы
                        </Typography>
                        <IconButton onClick={() => setAnchorElTooltip(null)} size='small' sx={{ height: 30 }}>
                          <CloseIcon fontSize='medium' />
                        </IconButton>
                      </Stack>
                      <Typography variant='body2'>
                        Для изменения ширины колонки наведите курсор на разделители колонок в шапке таблицы. Настройки
                        сохранятся после обновления страницы.
                      </Typography>
                      <ResetButton variant='outlined' onClick={resetTableSettings}>Сбросить настройки</ResetButton>
                    </Box>
                  </StyledPopover>
                </Stack>
              </>
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
              <Tabs<RemarkStatusRu> currentTab={currentTab} onTabChange={onTabChange} tabsData={tabsData} />
            </Stack>
            <RemarksTable remarks={remarks?.data} remarkSelector={remarkSelector} currentTab={currentTab} />
          </Stack>
        </InnerContainer>
      </MainContainer>
      <Menu
        anchorEl={anchorEl}
        open={MenuOpen}
        onClose={onClose}
        MenuListProps={{
          style: {
            width: '100%',
          },
        }}
        PaperProps={{
          style: {
            display: 'flex',
            justifyContent: 'center',
            minWidth: 200,
            maxWidth: 400,
            maxHeight: 300,
          },
        }}
      >
        {projectsLoading || projectsFetching ? (
          <Progress />
        ) : projects?.length > 1 ? (
          projects
            .filter((project) => project.id !== projectId!)
            ?.map((project) => (
              <MenuItem onClick={() => onMenuItemClick(project.id)} style={{ maxWidth: 450 }} key={project.id}>
                <Typography
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {project.shortName}
                </Typography>
              </MenuItem>
            ))
        ) : (
          <MenuItem style={{ maxWidth: 450 }} disabled>
            <Typography>Другие проекты отсутствуют</Typography>
          </MenuItem>
        )}
      </Menu>
      {/* <RemarkHistoryDrawer open={openedDrawer === 'remarksHistory'} closeDrawer={closeDrawer} /> */}
      <RemarkFormDrawer
        tomId={undefined}
        type={determineTomType(selectedProjectPhase)}
      />
      <ManualSubmitDrawer
        open={openedDrawer === 'manualSumbitRemarks'}
        selectedPhase={determineTomType(selectedProjectPhase)}
        remarkType={remarkSelector}
        closeDrawer={closeDrawer}
      />
      <ConfirmDialog />
    </>
  )
}
