import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ReactCadesWrapper } from '@seven-winds-studio/react-cades'
import ruLocale from 'date-fns/locale/ru'
import { SnackbarOrigin, SnackbarProvider } from 'notistack'
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { UICProvider } from './contexts/ui'
import { i18n } from './i18n'
import { AppRoutes } from './routes'
import { store } from './store/store'
import './styles/App.scss'
import { theme } from './styles/theme'

import 'ag-grid-community/styles/ag-grid.css'

const snackbarAnchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const App: React.FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <Provider store={store}>
                <UICProvider>
                  <BrowserRouter>
                    <SnackbarProvider style={{ maxWidth: 320 }} anchorOrigin={snackbarAnchorOrigin} maxSnack={2}>
                      <ReactCadesWrapper>
                        <div className='App'>
                          <AppRoutes />
                        </div>
                      </ReactCadesWrapper>
                    </SnackbarProvider>
                  </BrowserRouter>
                </UICProvider>
              </Provider>
            </I18nextProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  )
}

export default App
