import { Drawer, Stack } from "@mui/material"
import { RemarkForm } from "@pages/DocView/components/DocViewRightSideBar/components/RemarkForm"
import { onDrawerClose, openedDrawerSelector } from "@store/slices/documentsPages/drawerInfo"
import { useAppDispatch, useTypedSelector } from "@store/store"
import { RemarkFormDrawerProps } from "./RemarkFormDrawer.types"
import { useState } from "react"

export const RemarkFormDrawer = ({ tomId, type }: RemarkFormDrawerProps) => {
  const dispatch = useAppDispatch()
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const [closeTrigger, setCloseTrigger] = useState(false)
  const open = openedDrawer === 'remarks'
  const closeDrawer = () => {
    dispatch(onDrawerClose({ dirty: false }))
    setCloseTrigger(false)
  }

  const handleCloseDrawer = () => {
    setCloseTrigger(true)
  }

  return (
    <Drawer anchor='right' open={open} onClose={handleCloseDrawer} >
      {open &&
        <Stack height='100%' width="400px">
          <RemarkForm
            tomId={tomId}
            type={type}
            closeRemarkForm={closeDrawer}
            closeTrigger={closeTrigger}
            setCloseTrigger={(value) => setCloseTrigger(value)}
          />
        </Stack>}
    </Drawer>
  )
}
