import React, { useCallback, useContext } from 'react'
import { Box, Breadcrumbs as MuiBreadcrumbs, Stack, Typography } from '@mui/material'
import { BreadcrumbLink } from './styles'
import { BreadcrumbsItem, uiContext } from '../../contexts/ui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

export const Breadcrumbs: React.FC = () => {
  const cUI = useContext(uiContext)

  const getItem = useCallback((item: BreadcrumbsItem, isLast: boolean) => {
    const key = item.title + item.url

    let element: React.ReactNode
    switch (item.type) {
      case 'project': {
        element = (
          <Stack
            direction='row'
            alignItems='center'
            onClick={(e: React.MouseEvent<HTMLSpanElement>) => item?.onClick?.(e)}
            key={key}
            style={{
              cursor: 'pointer'
            }}
          >
            <Typography
              variant='h1'
              component='span'
              style={{
                maxWidth: 300,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {item.title}
            </Typography>
            {item?.MenuOpen
              ? <ExpandLessIcon />
              : <ExpandMoreIcon />
            }
          </Stack>

        )
        break
      }

      case 'longname': {
        element = (
          <Typography
            variant='h1'
            style={{
              maxWidth: 300,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
            key={key}
          >
            {item.title}
          </Typography>
        )
        break
      }

      default: {
        if (isLast || item.url == null) {
          element = (
            <Typography
              component='div'
              variant='h1'
              style={{
                overflow: 'hidden',
                maxWidth: 500,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
              key={key}
            >
              {item.title}
            </Typography>
          )

          break
        }

        element = (
          <BreadcrumbLink
            to={[item.url, item.query].filter(Boolean).join('?')}
            key={key}
            style={{
              maxWidth: 200,
            }}
          >
            <Typography
              variant='h1'
              component='span'
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {item.title}
            </Typography>
          </BreadcrumbLink>
        )
        break
      }
    }

    return element
  }, [])

  return (
    <Box>
      <MuiBreadcrumbs
        maxItems={cUI.breadcrumbsItems.length}
        sx={{
          'li': {
            maxWidth: 500, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'
          }
        }}>
        {cUI.breadcrumbsItems.map((item, i) => {
          const isLast = cUI.breadcrumbsItems.length === i + 1
          return getItem(item, isLast)
        })}
      </MuiBreadcrumbs>
    </Box>
  )
}

export default Breadcrumbs