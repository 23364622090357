import { Button } from "@mui/material";
import styled from "styled-components";

export const PageActionButton = styled(Button)`
  font-weight: 500;
  height: 40px;
  font-size: 14px;
  padding: 8px 16px;
  background-color: ${props => props.theme.palette.bg.lightBlue};
  color: ${props => props.theme.palette.primary.main};
  border: 1px solid ${props => props.theme.palette.primaryBorderLight};

  :hover {
    border-color: ${(props) => props.theme.palette.primary.main};
    background-color: ${props => props.theme.palette.bg.lightBlue};
  }
`