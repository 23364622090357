import { useGetVersions } from 'hooks/useGetVersions'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Outlet, matchRoutes, useLocation, useNavigate, useParams } from 'react-router-dom'

import useQuery from '@hooks/useQuery'

import { DocsChangeDrawer, DocsDrawer, DocsNavBarContent, DocsVersionDrawer } from '@pages/Docs'

import { useGetProjectByIdQuery } from '../../api/projects'
import Header from '../../components/Header'
import Progress from '../../components/Progress'
import UserNavBar from '../../components/UserNavBar'
import { useGetChanges } from '../../hooks/useGetChanges'
import DocViewNavBarContent from '../../pages/DocView/components/DocViewNavBarContent'
import ObjectsNavBarContent from '../../pages/Objects/components/ObjectsNavBarContent'
import { onDrawerClose, openedDrawerSelector } from '../../store/slices/documentsPages/drawerInfo'
import { setAvailableRdsItems, setFilterItems } from '../../store/slices/documentsPages/filter'
import { setSelectedMenuView } from '../../store/slices/documentsPages/menu'
import {
  selectedProjectPhaseSelector,
  setDefaultProjectInfo,
  setSelectedProjectPhase,
} from '../../store/slices/documentsPages/projectInfo'
import { setDetailedSidebarItems, setSidebarItems } from '../../store/slices/documentsPages/sidebar'
import {
  isCmnTomSelector,
  setDefaultCurrentDocument,
  setDocs,
  setIsCmnTom,
  setRoadmapAccess,
  setTom,
} from '../../store/slices/documentsPages/tom'
import { profileSelector } from '../../store/slices/profile'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { ProjectPhase, ProjectPhaseEn, projectPhaseEnToRu } from '../../types/project'
import { InnerContainer, MainContainer } from '../HomeLayout/styles'
import { DocViewMenuView } from './DocumentsLayout.types'
import { ConfirmDialog } from './components/ConfirmDialog'
import { useFilters } from './hooks/useFilters'
import { useGetDetailedSidebarItems } from './hooks/useGetDetailedSidebarItems'
import { useGetSidebarItems } from './hooks/useGetSidebarItems'
import { useGetTom } from './hooks/useGetTom'

export type CurrentPages = 'objects' | 'docs' | 'doc-view' | null

const routesWithParams = [
  { path: '/project/:projectId/objects' },
  { path: '/project/:projectId/toms' },
  { path: '/project/:projectId/tom/:tomId' },
  { path: '/project/:projectId/tom/cmn' },
  { path: '/project/:projectId/tim' },
]

export const DocumentsLayout: React.FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const queryHandler = useQuery()
  const userMatch = matchRoutes(routesWithParams, location)
  const routeMatch = userMatch?.length && userMatch[0].route.path
  const [currentPage, setCurrentPage] = useState<CurrentPages>(null)
  const { projectId: projectIdStr, tomId: tomIdStr } = useParams<{ projectId: string; tomId?: string }>()
  const projectId = parseInt(projectIdStr!)
  const tomId = parseInt(tomIdStr || '')

  const { role } = useTypedSelector(profileSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector, isEqual)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector, isEqual)
  const isCmnTom = useTypedSelector(isCmnTomSelector)
  const projectPhaseEn: ProjectPhaseEn | null = queryHandler.get('phase')

  useEffect(() => {
    if (role === 'none') {
      navigate('/home')
    }
  }, [role])

  // set current page
  useEffect(() => {
    switch (routeMatch) {
      case '/project/:projectId/objects':
        setCurrentPage('objects')
        dispatch(setIsCmnTom({ isCmnTom: false }))
        break
      case '/project/:projectId/tim':
        setCurrentPage('objects')
        dispatch(setIsCmnTom({ isCmnTom: false }))
        break
      case '/project/:projectId/toms':
        setCurrentPage('docs')
        dispatch(setIsCmnTom({ isCmnTom: false }))
        break
      case '/project/:projectId/tom/:tomId':
        setCurrentPage('doc-view')
        dispatch(setIsCmnTom({ isCmnTom: false }))
        break
      case '/project/:projectId/tom/cmn':
        setCurrentPage('doc-view')
        dispatch(setIsCmnTom({ isCmnTom: true }))
        break
    }
  }, [location])

  //// SET DEFAULT DATA
  const { data: projectData } = useGetProjectByIdQuery({
    id: projectId,
  })
  const { data: currentProject, pdAccess, rdAccess, iiAccess, irdAccess } = projectData || {}

  const { currentTom, roadmapAccess, tomLoading, accessData, isError } = useGetTom({
    project: currentProject,
    tomId,
    rdAccess,
    pdAccess,
    iiAccess,
    irdAccess,
  })

  // set initial data
  useEffect(() => {
    if (currentProject?.id) {
      dispatch(
        setDefaultProjectInfo({
          project: currentProject,
          ...accessData,
          userWithLessControls: role === 'contractor' || role === 'supervisor',
        }),
      )
    }
  }, [projectData])

  // set project phase on refresh page (update 27.12.24 queryHandler.get('phase'))
  useEffect(() => {
    if (!selectedProjectPhase) {
      const selectedProjectPhase: ProjectPhase =
        (localStorage.getItem('selectedProjectPhase') as ProjectPhase) || currentProject?.phase
      projectPhaseEn
        ? dispatch(setSelectedProjectPhase({ selectedProjectPhase: projectPhaseEnToRu[projectPhaseEn] }))
        : dispatch(setSelectedProjectPhase({ selectedProjectPhase }))
    }
  }, [])

  // set current tom & roadmapAccess (update 14.11.24 navigate)
  useEffect(() => {
    if (tomId && !tomLoading) {
      dispatch(setRoadmapAccess({ roadmapAccess }))
      dispatch(setTom({ tom: currentTom || null }))
      if (isError) {
        navigate(`/project/${projectId}/toms`, { replace: true })
      }
    }
  }, [currentTom, tomLoading, tomId, isError])

  // set menu view in doc-view page on page refresh
  useLayoutEffect(() => {
    const selectedMenuViewFromLS = localStorage.getItem('selectedMenuView') as DocViewMenuView
    if (currentPage === 'doc-view' && selectedMenuViewFromLS) {
      dispatch(setSelectedMenuView({ selectedMenuView: selectedMenuViewFromLS }))
    }
  }, [currentPage])

  //// SET SIDEBAR DATA
  const { sidebarItems, sidebarDataLoading } = useGetSidebarItems({
    projectId,
    currentPage,
    selectedProjectPhase: selectedProjectPhase,
  })

  const { detailedSidebarItems, detailedSidebarDataLoading } = useGetDetailedSidebarItems({
    projectId,
    currentPage,
    selectedProjectPhase: selectedProjectPhase,
  })

  const { filterItems } = useFilters()
  // set sidebar (+ detailed) items and default filter items
  useEffect(() => {
    const { allObjectsItems, allPdsItems, allIisItems, availableRdsItems, allIrdsItems } = filterItems
    dispatch(setFilterItems({ allObjectsItems, allPdsItems, allIisItems, allIrdsItems }))
    dispatch(setAvailableRdsItems({ availableRdsItems }))
    dispatch(setSidebarItems(sidebarItems))
    dispatch(setDetailedSidebarItems(detailedSidebarItems))
  }, [sidebarItems, detailedSidebarItems, filterItems, dispatch])

  //// SET DOCS DATA
  const { tomVersions, versionsLoading } = useGetVersions({
    currentTomId: isCmnTom ? projectId : currentTom?.id,
    currentPage,
    selectedProjectPhase: selectedProjectPhase,
    isCmnTom,
  })

  const { tomChanges, changesLoading } = useGetChanges({
    currentTomId: isCmnTom ? projectId : currentTom?.id,
    currentTomStatus: currentTom?.status,
    currentPage,
    selectedProjectPhase: selectedProjectPhase,
    isCmnTom,
  })

  // set documents (at the same time, it's important)
  useLayoutEffect(() => {
    if (versionsLoading || changesLoading) return

    dispatch(setDefaultCurrentDocument({ tomVersions, tomChanges }))
    dispatch(setDocs({ tomVersions, tomChanges }))
  }, [tomVersions, tomChanges, versionsLoading, changesLoading])

  const loading =
    tomLoading ||
    sidebarDataLoading ||
    detailedSidebarDataLoading
  // убрано чтобы не сбрасывать состояние при каждом обновлении версий и изменений
  /*||
    versionsLoading ||
    changesLoading*/

  // UTILS
  const getNavBarContent = () => {
    switch (currentPage) {
      case 'objects':
        return <ObjectsNavBarContent />
      case 'docs':
        return <DocsNavBarContent />
      case 'doc-view':
        return <DocViewNavBarContent />
    }
  }

  const onReturnClick = useCallback(() => {
    navigate(-1)
  }, [])

  const onHomeClick = useCallback(() => {
    currentProject?.id && navigate(`/project/${currentProject.id}`)
  }, [currentProject])

  return loading ? (
    <Progress />
  ) : (
    <>
      <UserNavBar
        getContent={getNavBarContent}
        loading={loading}
        onReturnClick={onReturnClick}
        onHomeClick={onHomeClick}
      />
      <MainContainer>
        <Header isHasSearch={currentPage === 'doc-view' ? false : true} />
        <InnerContainer $noscrollable={currentPage === 'doc-view'}>
          <Outlet />
        </InnerContainer>
      </MainContainer>

      <DocsDrawer
        open={openedDrawer === 'create' || openedDrawer === 'update'}
        onClose={(dirty, immediately) => dispatch(onDrawerClose({ dirty, immediately }))}
      />
      <DocsVersionDrawer
        open={openedDrawer === 'version'}
        onClose={(dirty, immediately) => dispatch(onDrawerClose({ dirty, immediately }))}
      />
      <DocsChangeDrawer
        open={openedDrawer === 'docChange'}
        onClose={(dirty, immediately) => dispatch(onDrawerClose({ dirty, immediately }))}
      />

      <ConfirmDialog />
    </>
  )
}
