import React, { useCallback, useState } from 'react'
import { OpenedDrawer, UseGetManagementHandlersResponse } from './useGetManagementHandlers.types'
import { ProjectPhase } from '../../../../types/project'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../hooks/useConfirmDialog'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector
} from '../../../../store/slices/documentsPages/projectInfo'
import { updateFilterData } from '../../../../store/slices/documentsPages/filter'
import { useExportObjectsMutation } from '../../../../api/rdPhase'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { downloadBlob } from '../../../../utils/dowloadBlob'
import { useExportPpdMutation } from '../../../../api/pdPhase'
import { useExportIiMutation } from '../../../../api/iiPhase'
import { useExportIrdMutation } from '../../../../api/irdPhase'
import { rdViewTabSelector } from '../../../../store/slices/documentsPages/objects'
import { useExportRdpMutation } from '../../../../api/rdPhase/rdp'
import { setTimFilter } from '../../../../store/slices/tim/tim'

export const useGetManagementHandlers = (): UseGetManagementHandlersResponse => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isTimPage = location.pathname.includes('tim')

  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const rdViewTab = useTypedSelector(rdViewTabSelector)

  const [openedDrawer, setOpenedDrawer] = useState<OpenedDrawer>(null)
  const [chosenId, setChosenId] = useState<number | undefined>(undefined)

  // drawer close
  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setOpenedDrawer(null)
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty
      ? setOpenedDrawer(null)
      : openConfirm()
  }, [])

  // Rd objects || tim objects
  const onObjectItemClick = (objectId: number) => {
    if (isTimPage) {
      dispatch(setTimFilter({ timFilter: { allTims: false, expiredFilter: false, objectFiltered: objectId, rdpFiltered: undefined } }))
      navigate(`/project/${project.id}/tim/models?obj=${objectId}`)
    } else {
      navigate(`/project/${project.id}/toms`)
      dispatch(updateFilterData({ selectedIds: [objectId], filterType: 'object' }))
    }
  }

  const onObjectEditClick = (objectId: number) => {
    setOpenedDrawer('object')
    setChosenId(objectId)
  }

  const [exportObjects, exportObjectsResponse] = useExportObjectsMutation()
  const onObjectExportClick = () => {
    exportObjects({ id: project.id })
  }

  useMutationHandlers(
    exportObjectsResponse,
    (data: Blob) => {
      downloadBlob(new File([data], ''), `${project.shifrName} Объекты.xlsx`)
    }
  )

  // Rdp
  /*   const onRdpItemClick = (rdpId: number) => {
      dispatch(updateFilterData({ selectedIds: [rdpId], filterType: 'rd' }))
      navigate(`/project/${project.id}/toms`)
    } */

  const onRdpEditClick = ( rdpId: number) => {
    setOpenedDrawer('rdp')
    setChosenId(rdpId)
  }


  const [exportRdp, exportRdpResponse] = useExportRdpMutation()

  const onRdpExportClick = () => {
    exportRdp({ id: project.id })
  }

  useMutationHandlers(
    exportRdpResponse,
    (data: Blob) => {
      downloadBlob(new File([data], ''), `РД.xlsx`)
    }
  )


  // Pd
  const onPdItemClick = (pdId: number) => {
    dispatch(updateFilterData({ selectedIds: [pdId], filterType: 'pd' }))
    navigate(`/project/${project.id}/toms`)
  }

  const onPdEditClick = (pdId: number) => {
    setOpenedDrawer('ppd')
    setChosenId(pdId)
  }

  const [exportPpd, exportPpdResponse] = useExportPpdMutation()
  const onPpdExportClick = () => {
    exportPpd({ id: project.id })
  }

  useMutationHandlers(
    exportPpdResponse,
    (data: Blob) => {
      downloadBlob(new File([data], ''), `${project.shifrName} ПД.xlsx`)
    }
  )


  // Ii
  const onIiItemClick = (iiId: number) => {
    dispatch(updateFilterData({ selectedIds: [iiId], filterType: 'ii' }))
    navigate(`/project/${project.id}/toms`)
  }

  const onIiEditClick = (iiId: number) => {
    setOpenedDrawer('ii')
    setChosenId(iiId)
  }

  const [exportIi, exportIiResponse] = useExportIiMutation()
  const onIiExportClick = () => {
    exportIi({ id: project.id })
  }

  useMutationHandlers(
    exportIiResponse,
    (data: Blob) => {
      downloadBlob(new File([data], ''), `${project.shifrName} ИИ.xlsx`)
    }
  )


  // Ird
  const onIrdItemClick = (irdId: number) => {
    dispatch(updateFilterData({ selectedIds: [irdId], filterType: 'ird' }))
    navigate(`/project/${project.id}/toms`)
  }

  const onIrdEditClick = (irdId: number) => {
    setOpenedDrawer('ird')
    setChosenId(irdId)
  }

  const [exportIrd, exportIrdResponse] = useExportIrdMutation()
  const onIrdExportClick = () => {
    exportIrd({ id: project.id })
  }

  useMutationHandlers(
    exportIrdResponse,
    (data: Blob) => {
      downloadBlob(new File([data], ''), `${project.shifrName} ИРД.xlsx`)
    }
  )


  // Common
  const onAddClick = () => {
    setChosenId(undefined)
    if (isTimPage) {
      rdViewTab === 'objects' ? setOpenedDrawer('object') : setOpenedDrawer('rdp')
      return
    }
    switch (selectedProjectPhase) {
      case 'Рабочая документация':
        rdViewTab === 'objects' ? setOpenedDrawer('object') : setOpenedDrawer('rdp')
        break
      case 'Проектная документация':
        setOpenedDrawer('ppd')
        break
      case 'Инженерные изыскания':
        setOpenedDrawer('ii')
        break
      case 'Сбор исходных данных':
        setOpenedDrawer('ird')
        break
    }
  }

  const onUploadClickByPhase: Record<ProjectPhase, () => void> = {
    'Рабочая документация': () => rdViewTab === 'objects' ? setOpenedDrawer('objectsUpload') : setOpenedDrawer('rdpUpload'),
    'Проектная документация': () => setOpenedDrawer('ppdUpload'),
    'Инженерные изыскания': () => setOpenedDrawer('iiUpload'),
    'Сбор исходных данных': () => setOpenedDrawer('irdUpload'),
  }

  const onExportClickByPhase: Record<ProjectPhase, () => void> = {
    'Рабочая документация': () => rdViewTab === 'objects' ? onObjectExportClick() : onRdpExportClick(),
    'Проектная документация': () => onPpdExportClick(),
    'Инженерные изыскания': () => onIiExportClick(),
    'Сбор исходных данных': () => onIrdExportClick(),
  }

  const onRowClick: Record<ProjectPhase, (value: number) => void> = {
    'Рабочая документация': (value: number) => rdViewTab === 'objects' ? onObjectItemClick(value) : undefined/* onRdpItemClick(value) */,
    'Проектная документация': (value: number) => onPdItemClick(value),
    'Инженерные изыскания': (value: number) => onIiItemClick(value),
    'Сбор исходных данных': (value: number) => onIrdItemClick(value),
  }

  return {
    onAddClick,
    onUploadClick: onUploadClickByPhase[selectedProjectPhase],
    onExportClick: onExportClickByPhase[selectedProjectPhase],

    onObjectItemClick,
    onObjectEditClick,
    /* onRdpItemClick, */
    onRdpEditClick,
    onPdItemClick,
    onPdEditClick,
    onIiItemClick,
    onIiEditClick,
    onIrdItemClick,
    onIrdEditClick,

    onRowClick: onRowClick[selectedProjectPhase],

    openedDrawer,
    chosenId,
    onDrawerClose,
    ConfirmDialog,
  }
}
