import styled from 'styled-components'
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

export const DrawerContentTextWrapper = styled(Box)`
  padding: 7px 16px;
  background-color: ${props => props.theme.palette.bg.shades};
  border: 1px solid rgba(92, 110, 140, 0.1);
  border-radius: 6px;
`

export const DrawerProjectTitle = styled(Typography)`
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  width: 100%;
`

export const DrawerProjectDescription = styled(DrawerProjectTitle)`
  color: ${props => props.theme.palette.text.dark};
`

export const ProjectAccentText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: ${props => props.theme.palette.primary.main};
`

export const ProjectText = styled(Typography)`
  line-height: 22px;
  color: ${props => props.theme.palette.text.dark};
`
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  border-radius: 6px;
`
export const StyledToggleButton = styled(ToggleButton)`
  padding: 7px;
  background-color: transparent;
  width: 100%;
  border: 1px solid ${props => props.theme.palette.primary.main};

  & .MuiTypography-root {
    color: ${props => props.theme.palette.secondary.dark};
  }

  &.Mui-selected {
    &, :hover {
      background-color: ${props => props.theme.palette.primary.main};
    }

    & .MuiTypography-root {
      color: ${props => props.theme.palette.text.light};
    }
  }

  
`