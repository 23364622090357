import { useGetRemarkResponsibleQuery } from "@api/remarks"
import { useParams } from "react-router-dom"
import { RemarkCompanyMultiSelectProps } from "./RemarkCompanyMultiSelect.types"
import { ChangeEvent, useMemo, useState } from "react"
import { useFormikContext } from "formik"
import { ManualSubmitValues } from "../ManualSubmitDrawer"
import { Checkbox, Select, SelectChangeEvent, Stack,  Typography } from "@mui/material"
import { StyledMenuItem, StyledSearch } from "./RemarkCompanyMultiSelect.styles"
import { measureTextWidth } from "@utils/measureTextWidth"
import { Search as SearchIcon } from '@mui/icons-material'
import { theme } from "@styles/theme"
import { OverflowTextNew } from "@components/OverflowTextNew"
import Tooltip from "@components/Tooltip"

export const RemarkCompanyMultiSelect = ({ selectedPhase, mailType }: RemarkCompanyMultiSelectProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const maxWidth = 160
  const { values, setFieldValue } = useFormikContext<ManualSubmitValues>()

  const { data: responsibleCompany } = useGetRemarkResponsibleQuery(
    { id: projectId, type: selectedPhase, mailType },
    { skip: !selectedPhase || !open }
  )
  const { data: companies } = responsibleCompany || {}

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    setFieldValue('copyToCompanies', e.target.value)
  }

  const filtredList = useMemo(() => {
    const formattedSearchValue: string = searchValue.trim().toLowerCase()
    if (!companies) return []
    if (!formattedSearchValue) return companies

    return companies.filter((company) => {
      const formattedCompany: string = company.trim().toLowerCase()

      return formattedCompany.includes(formattedSearchValue)
    })
  }, [companies, searchValue])

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Select
      multiple
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      onChange={handleChange}
      onKeyDown={(e) => e.stopPropagation()}
      value={values.copyToCompanies}
      variant='standard'
      disableUnderline
      displayEmpty
      sx={{
        width: 200,
        height: 32,
        '& > .MuiSvgIcon-root': {
          right: '8px',
          fontSize: '16px !important',
          color: '#2b3648',
        },
        '& .MuiInputBase-input': {
          borderRadius: '6px !important',
          padding: '3px',
          paddingRight: '32px !important',
          backgroundColor: '#F6F7FB !important',
          border: !open ? '1px solid  #5c6e8c1a' : '1px solid  #0044B4',
          ':hover': {
            border: !open ? '1px solid  #2B36481F' : '1px solid  #0044B4',
          },
        },
      }}
      renderValue={(selected) => {
        let totalWidth = 0
        let visibleCount = 0;

        selected.forEach((company: string) => {
          const width = measureTextWidth(company + ', ')
          totalWidth += width

          if (totalWidth <= maxWidth) visibleCount++
          else return
        })
        const hiddenCount = selected.length - visibleCount

        return (
          <Stack direction={'row'} spacing={1}>
            {selected.length > 0 ?
              <Typography
                variant='body2'
                textOverflow='ellipsis'
                overflow='hidden'
                className='contentText'
                lineHeight={'inherit'}
                pl={1}
              >
                {selected.join(', ')}
              </Typography>
              : <Typography className='contentText' pl={1} color='#a0a8b6' lineHeight={'inherit'} variant='body2'>
                Выберите из списка
              </Typography>}

            {!!hiddenCount && (
              <Tooltip variant='light' placement='right' title={selected.join(', ')}>
                <Stack
                  ml={'auto'}
                  justifyContent={'center'}
                  height={'24px'}
                  p={'0px 8px !important'}
                  width={'fit-content'}
                  borderRadius={3}
                  bgcolor={theme.palette.bg.lightBlue}
                >
                  <Typography variant='body2' color={`${theme.palette.primary.main} !important`}>
                    + {hiddenCount}
                  </Typography>
                </Stack>
              </Tooltip>
            )}
          </Stack>
        )
      }}
      MenuProps={{
        disableAutoFocusItem: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 100
        },
        PaperProps: {
          style: {
            width: 200,
            maxHeight: 300
          },
        },
      }}
    >
      <Stack direction={'row'} alignItems={'center'} p={1} spacing={1}>
        <SearchIcon fontSize='medium' color='secondaryDark' />
        <StyledSearch value={searchValue} onChange={onChangeSearch} onKeyDown={e => e.stopPropagation()} placeholder='Поиск' />
      </Stack>

      {filtredList.map((company) => (
        <StyledMenuItem key={company} value={company} >
          <Checkbox checked={values.copyToCompanies.includes(company)} />
          <OverflowTextNew variant='body2' display={'block !important'}>
            {company}
          </OverflowTextNew>
        </StyledMenuItem>
      ))}
    </Select>
  )
}