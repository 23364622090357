import { Stack } from '@mui/material'
import { Form, FormikProvider } from 'formik'
import { useSnackbar } from 'notistack'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import {
  useCreateProjectMutation,
  useDeleteImagesFromProjectMutation,
  useEditProjectMutation,
  useGetProjectDashboardQuery,
  useUploadImagesToProjectMutation,
} from '../../../api/projects'
import {
  ProjectAddressForRequest,
  ProjectBaseResponse,
  ProjectBaseWithImagesResponse,
  ProjectRequest,
} from '../../../api/projects/api.types'
import { EmployeeData, ProjectLink, ProjectResponsiblePersons } from '../../../api/projects/types'
import useBreadcrumbs from '../../../hooks/useBreadcrumbs'
import useFilteredEmployees from '../../../hooks/useFilteredEmployees'
import { useForm } from '../../../hooks/useForm'
import { useMutationHandlers } from '../../../hooks/useMutationHandlers'
import { ProjectFullStatusesRu, ProjectPhase, ProjectType } from '../../../types/project'
import { DATE_FORMAT } from '../../../utils/constants'
import { formatDateToString, formatToDate } from '../../../utils/formatDate'
import { validationProject } from '../../Administration/components/ProjectDrawerForm/validation'
import { ProjectFormData, ProjectManagementTabs } from './ProjectManagement.types'
import { DEFAULT_LINK_NAME } from './components/LinkBlock'
import { ManagementForm } from './components/ManagementForm'
import { ManagementLegend } from './components/ManagementLegend'
import { MapForm } from './components/MapForm'

export const ProjectManagement: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { projectId: projectIdString } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const projectId = Number(projectIdString)

  useBreadcrumbs(
    projectId
      ? [{ title: 'Проекты' }, { title: 'Управление проектом' }]
      : [{ title: 'Проекты' }, { title: 'Добавление проекта' }],
  )

  const { data: projectRes, isFetching } = useGetProjectDashboardQuery(
    {
      id: projectId!,
    },
    {
      skip: !projectId,
    },
  )
  const { data: projectData } = projectRes || {}
  const { project, addressData, responsiblePersons, link, images } = projectData || {}

  const { id, shortName, shifrName, fullName, startDate, endDate, status, phase, type, expertise } = project || {}
  const { address, latitude, longitude, region } = addressData || {}
  const { url, name } = link || {}
  const {
    client: initialClient,
    architector: initialArchitector,
    contractors: initialContractors,
  } = responsiblePersons || {}

  const [currentTab, setCurrentTab] = useState<ProjectManagementTabs>('info')
  const onTabChange = (value: ProjectManagementTabs) => {
    value !== currentTab && setCurrentTab(value)
  }

  const initialValues: ProjectFormData = useMemo(
    () => ({
      shortName: shortName || '',
      shifrName: shifrName || '',
      fullName: fullName || '',
      status: status || '',
      phase: phase || '',
      type: type || '',
      expertise: expertise || '',
      startDate: formatToDate(startDate),
      endDate: formatToDate(endDate),
      initialImages: images || [],
      imagesForCreate: [],
      imagesIdsToDelete: [],
      addressData: {
        address: { value: address || '', unrestricted_value: '', data: '' },
        latitude: latitude || '',
        longitude: longitude || '',
        region: region || '',
      },
      link: {
        url: url || '',
        name: name || '',
      },
      responsiblePersons: {
        client: {
          data: initialClient?.id || initialClient?.customName || '',
          company: initialClient?.company,
        },
        architector: {
          data: initialArchitector?.id || initialArchitector?.customName || '',
          company: initialArchitector?.company,
        },
        contractorsArray: initialContractors?.length
          ? initialContractors?.map((contractor) => ({
              data: contractor?.id || contractor?.customName || '',
              company: contractor?.company,
            }))
          : [{ data: '' }],
      },
    }),
    [projectData, initialClient, initialArchitector, initialContractors],
  )

  const { architectorsAndAdmins, clientsAndAdmins, contractors } = useFilteredEmployees()

  const [createProject, createProjectResponse] = useCreateProjectMutation()
  const [editProject, editProjectResponse] = useEditProjectMutation()
  const [uploadImagesToProject, uploadImagesToProjectResponse] = useUploadImagesToProjectMutation()
  const [deleteImagesFromProject, deleteImagesFromProjectResponse] = useDeleteImagesFromProjectMutation()

  const onSubmit = useCallback(
    (values: ProjectFormData) => {
      const { client, architector, contractorsArray } = values.responsiblePersons || {}
      const { address, ...otherAddressData } = values.addressData || {}
      const { expertise } = values

      const { data: clientData, company: clientCompany } = client
      const { data: architectorData, company: architectorCompany } = architector

      const responsiblePersons: ProjectResponsiblePersons<EmployeeData> = {
        client: {
          id: typeof clientData === 'number' ? clientData : (null as unknown as number),
          name:
            (typeof clientData === 'number'
              ? clientsAndAdmins.find((client) => client.id === clientData)?.name
              : clientData) || '',
          company: clientCompany || '',
        },
        architector: {
          id: typeof architectorData === 'number' ? architectorData : (null as unknown as number),
          name:
            (typeof architectorData === 'number'
              ? architectorsAndAdmins.find((architector) => architector.id === architectorData)?.name
              : architectorData) || '',
          company: architectorCompany || '',
        },
        contractors:
          contractorsArray
            ?.filter((contractor) => contractor.data)
            ?.map(({ data, company }) => ({
              id: typeof data === 'number' ? data : (null as unknown as number),
              name:
                (typeof data === 'number' ? contractors.find((contractor) => contractor.id === data)?.name : data) ||
                (null as unknown as string),
              company: company || '',
            })) || [],
      }

      const addressData: ProjectAddressForRequest = {
        ...otherAddressData,
        region: values.addressData?.region || null,
        address: address?.value || '',
      }

      const link: ProjectLink = {
        url: values.link?.url || undefined,
        name: values.link?.url ? values.link?.name || DEFAULT_LINK_NAME : undefined,
      }

      const dataForRequest: ProjectRequest = {
        fullName: values.fullName,
        shifrName: values.shifrName,
        shortName: values.shortName,
        startDate: formatDateToString(values.startDate!, DATE_FORMAT),
        endDate: formatDateToString(values.endDate!, DATE_FORMAT),
        status: values.status as ProjectFullStatusesRu,
        phase: values.phase as ProjectPhase,
        type: values.type as ProjectType,
        addressData,
        link,
        responsiblePersons,
      }
      expertise && (dataForRequest.expertise = expertise)

      if (id) {
        editProject({
          id,
          ...dataForRequest,
        })
      } else {
        createProject(dataForRequest)
      }
    },
    [project],
  )

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationProject,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, isValid, isSubmitting } = formik

  useMutationHandlers(createProjectResponse, (data: ProjectBaseResponse) => {
    if (data.success) {
      !values.imagesForCreate.length
        ? navigate('/administration/projects')
        : uploadImagesToProject({
            id: data.data.id,
            file: values.imagesForCreate,
          })
    } else {
      enqueueSnackbar(`Не удалось создать проект. ${data.description}`, { variant: 'error' })
    }
  })

  useMutationHandlers(editProjectResponse, (data: ProjectBaseResponse) => {
    if (values.imagesIdsToDelete.length) {
      deleteImagesFromProject({
        id: data.data.id,
        img: values.imagesIdsToDelete,
      })
      return
    }

    if (values.imagesForCreate.length) {
      uploadImagesToProject({
        id: data.data.id,
        file: values.imagesForCreate,
      })
    }

    navigate('/administration/projects')
  })

  useMutationHandlers(deleteImagesFromProjectResponse, (data: ProjectBaseWithImagesResponse) => {
    if (values.imagesForCreate.length) {
      uploadImagesToProject({
        id: data.data.id,
        file: values.imagesForCreate,
      })
    }

    navigate('/administration/projects')
  })

  useMutationHandlers(uploadImagesToProjectResponse, () => {
    navigate('/administration/projects')
  })

  useEffect(() => {
    if (isSubmitting && !isValid) {
      enqueueSnackbar('Неверно заполнены данные или отсутствуют обязательные поля', { variant: 'error' })
    }
  }, [isSubmitting])

  const ContentByTab: Record<ProjectManagementTabs, ReactNode> = {
    info: <ManagementForm />,
    additional: <MapForm />,
  }

  return (
    <FormikProvider value={formik}>
      <Stack flex={1} component={Form}>
        <ManagementLegend tabValue={currentTab} onTabChange={onTabChange} />

        {ContentByTab[currentTab]}
      </Stack>
    </FormikProvider>
  )
}
