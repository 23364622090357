import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { formLabelClasses, Stack, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import React, { FC, ReactElement } from 'react'

import FieldForm from '../../../../components/FieldForm'
import { openedDrawerSelector } from '../../../../store/slices/documentsPages/drawerInfo'
import {
  isExtraAccessArchitectorSelector,
  selectedProjectPhaseSelector,
} from '../../../../store/slices/documentsPages/projectInfo'
import { tomSelector } from '../../../../store/slices/documentsPages/tom'
import { profileSelector } from '../../../../store/slices/profile'
import { useTypedSelector } from '../../../../store/store'
import { TomStatus } from '../../../../types/tom'
import { DocFormData } from '../DocsDrawerForm/DocsDrawerForm.types'
import { formatDateToFullYear } from '@utils/formatDateToFullYear'

export const DocsDrawerDatesBlock: FC = () => {
  const { role } = useTypedSelector(profileSelector)
  const isExtraAccessArchitector = useTypedSelector(isExtraAccessArchitectorSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)
  const { values, setFieldValue } = useFormikContext<DocFormData>()

  const isEditTom = openedDrawer === 'update' && !!tom?.id

  const PlannedDate = (
    <Stack direction='row' justifyContent='space-between'>
      <Stack direction='row' spacing={1} pt={0.75}>
        <EventNoteIcon fontSize='medium' color='secondary' />
        <Typography variant='body2' component='span'>
          Плановая:
        </Typography>
        <Typography variant='body2' component='span' color='#f46b6b' ml='3px !important'>*</Typography>
      </Stack>

      <FieldForm
        version='date'
        name='plannedDate'
        dataValue={values.plannedDate}
        onDataChange={(value: Date | null) => setFieldValue('plannedDate', value)}
        sx={{ maxWidth: 200 }}
        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
        inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
      />
    </Stack>
  )

  const ForecastDate = (
    <Stack direction='row' justifyContent='space-between'>
      <Stack direction='row' spacing={1} pt={0.75}>
        <EventAvailableIcon fontSize='medium' color='secondary' />
        <Typography variant='body2' component='span'>
          Прогнозная:
        </Typography>
      </Stack>

      <FieldForm
        version='date'
        name='forecastDate'
        dataValue={values.forecastDate}
        onDataChange={(value: Date | null) => setFieldValue('forecastDate', value)}
        sx={{ maxWidth: 200 }}
        inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
      />
    </Stack>
  )

  const ActualDate = (
    <Stack direction='row' justifyContent='space-between'>
      <Stack direction='row' spacing={1} pt={0.75}>
        <EventAvailableIcon fontSize='medium' color='secondary' />
        <Typography variant='body2' component='span'>
          Фактическая:
        </Typography>
      </Stack>

      <FieldForm
        version='date'
        name='actualDate'
        maxDate={new Date()}
        dataValue={values.actualDate}
        onDataChange={(value: Date | null) => setFieldValue('actualDate', formatDateToFullYear(value))}
        sx={{ maxWidth: 200 }}
        inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
      />
    </Stack>
  )

  const ProdDate = (
    <Stack direction='row' justifyContent='space-between'>
      <Stack direction='row' spacing={1} pt={0.75}>
        <EventAvailableIcon fontSize='medium' color='secondary' />
        <Typography variant='body2' component='span'>
          Производства:
        </Typography>
      </Stack>

      <FieldForm
        version='date'
        name='prodDate'
        dataValue={values.prodDate}
        onDataChange={(value: Date | null) => setFieldValue('prodDate', value)}
        sx={{ maxWidth: 200 }}
        inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
      />
    </Stack>
  )

  const IssueDate = (
    <Stack direction='row' justifyContent='space-between'>
      <Stack direction='row' spacing={1} pt={0.75}>
        <EventNoteIcon fontSize='medium' color='secondary' />
        <Typography variant='body2' component='span'>
          Дата выдачи:
        </Typography>
      </Stack>

      <FieldForm
        version='date'
        name='issueDate'
        dataValue={values.issueDate}
        onDataChange={(value: Date | null) => setFieldValue('issueDate', value)}
        sx={{ maxWidth: 200 }}
        inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
      />
    </Stack>
  )

  const DurationDate = (
    <Stack direction='row' justifyContent='space-between'>
      <Stack direction='row' spacing={1} pt={0.75}>
        <EventAvailableIcon fontSize='medium' color='secondary' />
        <Typography variant='body2' component='span'>
          Срок действия:
        </Typography>
      </Stack>

      <FieldForm
        version='date'
        name='durationDate'
        dataValue={values.durationDate}
        onDataChange={(value: Date | null) => setFieldValue('durationDate', value)}
        sx={{ maxWidth: 200 }}
        inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
      />
    </Stack>
  )

  const NoNeedAgreeDates = (
    <>
      {IssueDate}
      {DurationDate}
    </>
  )

  const AgreedDates = (
    <>
      {PlannedDate}
      {isEditTom && ForecastDate}
      {(role === 'admin' || role === 'client' || isExtraAccessArchitector) && ActualDate}
      {isEditTom &&
        selectedProjectPhase === 'Рабочая документация' &&
        values.prodDate &&
        (role === 'admin' || role === 'client' || isExtraAccessArchitector) &&
        ProdDate}
    </>
  )

  const DefaultDates = (
    <>
      {PlannedDate}
      {ForecastDate}
    </>
  )

  const DatesBlock: Record<TomStatus | '', ReactElement> = {
    'Не согласуется': NoNeedAgreeDates,
    Согласовано: AgreedDates,
    Корректировка: DefaultDates,
    'На согласовании': DefaultDates,
    Разработка: DefaultDates,
    'Не в работе': DefaultDates,
    '': DefaultDates,
  }

  return DatesBlock[values.status]
}
