import { MenuItem } from "@mui/material";
import { theme } from "@styles/theme";
import styled from "styled-components";

export const StyledMenuItem = styled(MenuItem)`
  padding-left: 8px;
  padding-right: 8px;

  &:hover {
    color: ${props => props.theme.palette.primary.main};
    background-color: ${props => props.theme.palette.bg.shades};
  }

  &.Mui-selected {
    background-color: ${props => props.theme.palette.bg.white};
  }

  &.Mui-focusVisible,
  &.Mui-selected:hover {
    background-color: ${props => props.theme.palette.bg.gray} !important;
  }

  & .MuiCheckbox-root {
    margin-right: 16px;
    padding: 0;

    & .MuiSvgIcon-root {
      fill: ${props => props.theme.palette.primary.main};

    }
  }
`
export const StyledSearch = styled.input`
  width: 100%;
  border: none;
  outline: none;
  padding: 4px;
  border-bottom: 1px solid transparent;

  &:focus {
    border-bottom: 1px solid ${theme.palette.text.dark};
  }
`