// Общее API для томов
import {
  CheckSignRequest,
  DocumentSignItem,
  GetTomSignsRequest,
  PaginatedDocumentSignList,
} from '@api/tomCommonApi/types'

import { api } from '../api'
import {
  DocumentPropertiesRequest,
  DocumentPropertiesResponse,
  TomAccessCompanyStatusUpdateRequest,
  TomAccessCompanyStatusUpdateResponse,
  TomAccessListRequest,
  TomAccessListResponse,
  TomAccessUserStatusUpdateAllRequest,
  TomAccessUserStatusUpdateAllResponse,
  TomAccessUserStatusUpdateRequest,
  TomAccessUserStatusUpdateResponse,
} from './api.types'

export const tomCommonApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomAccessList: build.query<TomAccessListResponse, TomAccessListRequest>({
      query: ({ projectId, tomId, ...params }) => ({
        url: `/project/${projectId}/tom-access/${tomId}/list`,
        method: 'GET',
        params,
      }),
      providesTags: ['TomAccess'],
    }),
    tomAccessCompanyStatusUpdate: build.mutation<
      TomAccessCompanyStatusUpdateResponse,
      TomAccessCompanyStatusUpdateRequest
    >({
      query: ({ projectId, ...body }) => ({
        url: `/project/${projectId}/tom-access/company/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TomAccess'],
    }),
    tomAccessUserStatusUpdate: build.mutation<TomAccessUserStatusUpdateResponse, TomAccessUserStatusUpdateRequest>({
      query: ({ projectId, ...body }) => ({
        url: `/project/${projectId}/tom-access/person/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TomAccess'],
    }),
    tomAccessUserStatusUpdateAll: build.mutation<
      TomAccessUserStatusUpdateAllResponse,
      TomAccessUserStatusUpdateAllRequest
    >({
      query: ({ projectId, ...body }) => ({
        url: `/project/${projectId}/tom-access/person/update-all`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TomAccess'],
    }),

    getTomSigns: build.query<PaginatedDocumentSignList, GetTomSignsRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom/${id}/pdf/get-signs`,
        method: 'GET',
        params,
      }),
      providesTags: ['InnermapPersons', 'TomAgreement', 'AgreementOverview', 'SIGN'],
    }),
    getDocumentProperties: build.query<DocumentPropertiesResponse, DocumentPropertiesRequest>({
      query: ({ docId, ...params }) => ({
        url: `/stamp/pdf-info/${docId}`,
        method: 'GET',
        params,
      }),
    }),

    checkSign: build.mutation<DocumentSignItem, CheckSignRequest>({
      query: ({ id, phasePrefix, ...params }) => ({
        url: `/project/${phasePrefix}/${id}/pdf/check-sign`,
        method: 'POST',
        params,
      }),
      invalidatesTags: ['SIGN'],
    }),
  }),
})

export const {
  useGetTomAccessListQuery,
  useTomAccessCompanyStatusUpdateMutation,
  useTomAccessUserStatusUpdateMutation,
  useTomAccessUserStatusUpdateAllMutation,
  useGetTomSignsQuery,
  useGetDocumentPropertiesQuery,
  useCheckSignMutation,
} = tomCommonApi
