import { Grid, Stack, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import DownloadingIcon from '@mui/icons-material/Downloading'
import { PageActionButton } from "../../styles/global/PageActionButton"
import { ViewModeButtonGroup } from "../../components/ViewModeButtonGroup"
import { useCallback, useEffect, useMemo, useState } from "react"
import { modelsViewSelector, PagesViewMode, setPageViewMode, ViewMode } from "../../store/slices/ui"
import { ModelsFilterDrawer } from "./components/ModelsFilterDrawer"
import EmptyPage from "../../components/EmptyPage"
import { EmptyPageData } from "../../components/EmptyPage/EmptyPage.types"
import { getEmptyPageData } from "../Home"
import { useAppDispatch, useTypedSelector } from "../../store/store"
import { profileSelector } from "../../store/slices/profile"
import { useNavigate, useParams } from "react-router-dom"
import Progress from "../../components/Progress"
import { ModelCard } from "./components/ModelCard"
import useSearch from "../../hooks/useSearch"
import { filterByFieldNames } from "../../utils/filterByFieldNames"
import { AgGridModels } from "./components/AgGridModels"
import { ForUpdateData, ModelDrawer } from "./components/ModelDrawer"
import { onDrawerClose, openedDrawerSelector, setOpenedDrawer } from "../../store/slices/documentsPages/drawerInfo"
import useConfirmDialog, { UseExitConfirmProps } from "../../hooks/useConfirmDialog"
import { useGetTimListQuery } from "../../api/tim"
import { TIMAndVersions } from "../../api/tim/types"
import { timFilterSelector, timStatusSelector } from "../../store/slices/tim/selectors/tim.selectors"
import Tabs from "../../components/Tabs"
import { timStatusArray, TimStatusLabels, timStatusLabelsRuToEn } from "../../types/models"
import { TabData } from "../../components/Tabs/Tabs.types"
import { setTimFilter, setTimStatus } from "../../store/slices/tim/tim"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useGetTanglIntegrationStatusQuery } from "api/settings"
import { NavigationContainer } from "./Model.styles"
import Tooltip from "components/Tooltip"
import { TimUploadDrawer } from "./components/ExelUploadDrawer"

export const Models = () => {

  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { searchValue } = useSearch()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { role } = useTypedSelector(profileSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const { allTims, expiredFilter, objectFiltered, rdpFiltered, emptyFilter } = useTypedSelector(timFilterSelector)
  const timStatusSelected = useTypedSelector(timStatusSelector)
  const userWithLessControls = role === 'contractor' || role === 'supervisor'
  const viewMode = useTypedSelector(modelsViewSelector)
  const [forUpdateData, setForUpdateData] = useState<ForUpdateData | null>(null)
  const [currentTab, setCurrentTab] = useState<TimStatusLabels>('Все модели')
  const [isShowUploadDrawer, setIsShowUploadDrawer] = useState<boolean>(false)

  const { data: statusIntegrationData, isFetching: loadingStatusIntegration } = useGetTanglIntegrationStatusQuery(
    undefined,
    { refetchOnMountOrArgChange: true }
  )
  const { status: statusIntegration } = statusIntegrationData || {}

  const { data: timsData, isFetching } = useGetTimListQuery({
    id: projectId,
    obj: allTims ? null : objectFiltered,
    rdp: allTims ? undefined : rdpFiltered,
    expired: expiredFilter,
    status: currentTab !== 'Все модели' ? currentTab : undefined,
    empty: emptyFilter
  })
  const { data: models = [], statusCount } = timsData || {}

  // TODO Проверить поиск
  const getFilteredData = (data: TIMAndVersions[]) => {

    const filteredBySearch = searchValue
      ? filterByFieldNames<TIMAndVersions>(data, ['tim.title' as keyof TIMAndVersions], searchValue)
      : data
    return filteredBySearch
  }

  const filteredData = getFilteredData(models)

  const onTabChange = (e: React.SyntheticEvent, tabValue: TimStatusLabels) => {
    setCurrentTab(tabValue)
  }

  const tabsData: TabData<TimStatusLabels>[] = useMemo(() => {
    let tabs: TimStatusLabels[] = ['Все модели', ...timStatusArray]
    if (!statusCount) return []

    return tabs.map(tabName => {
      const remarkDataCount: number = statusCount[timStatusLabelsRuToEn[tabName]]

      return {
        value: tabName,
        label: `${tabName} (${remarkDataCount})`,
        disabled: remarkDataCount === 0
      }
    })
  }, [statusCount])

  useEffect(() => {
    if (timStatusSelected) {
      setCurrentTab(timStatusSelected)
      dispatch(setTimStatus({ timStatus: null }))
    }
  }, [timStatusSelected])

  const onViewChange = useCallback((updatedViewMode: ViewMode) => {
    if (updatedViewMode !== null) {
      dispatch(setPageViewMode({ page: 'models', viewMode: updatedViewMode }))
    }
  }, [])

  const onAddModelClick = () => {
    dispatch(setOpenedDrawer({ openedDrawer: 'tim' }))
  }

  const onViewModelClick = () => {
    if (statusIntegration === 'ENABLED') {
      navigate('view')
    }
  }

  const onUploadClick = () => setIsShowUploadDrawer(!isShowUploadDrawer)
  const closeUploadDrawer = () => setIsShowUploadDrawer(false)

  const onUpdateData = (timId: number) => {
    if (userWithLessControls) return
    const selectedModel = models.find(model => model.tim.id === timId)
    selectedModel && setForUpdateData({
      objectId: selectedModel.tim.objectId,
      plannedDate: selectedModel.tim.plannedDate,
      rdpId: selectedModel.tim.rdpId,
      status: selectedModel.tim.status,
      timId: selectedModel.tim.id,
      title: selectedModel.tim.title,
      actualDate: selectedModel.tim.actualDate,
      architectorId: selectedModel.tim.architectorId,
      architectorName: selectedModel.tim.architectorName,
      clientId: selectedModel.tim.clientId,
      clientName: selectedModel.tim.clientName,
      description: selectedModel.tim.description
    })
    dispatch(setOpenedDrawer({ openedDrawer: 'tim' }))
  }

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
      forUpdateData && setForUpdateData(null)
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
    denyButtonText: 'Отменить',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const closeDrawer = (dirty: boolean) => {
    if (dirty) {
      openConfirm()
    } else {
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
      forUpdateData && setForUpdateData(null)
    }
  }

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют модели в данном объекте.<br />
      Для добавления модели нажмите на кнопку Создать модель.</>,
    [
      {
        text: 'Создать модель',
        icon: AddIcon,
        onClick: () => onAddModelClick()
      },
    ]
  )

  const emptyPageDataWithoutControls: EmptyPageData = getEmptyPageData(
    <>Нет моделей для отображения.</>
  )

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют модели, соответствующие результатам запроса.</>,
    []
  )

  useEffect(() => {
    const cachedPagesViewMode: PagesViewMode = JSON.parse(localStorage.getItem('pagesViewMode') || '{}')
    if (cachedPagesViewMode?.models) {
      dispatch(setPageViewMode({ page: 'models', viewMode: cachedPagesViewMode.models }))
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setTimFilter({
        timFilter: {
          objectFiltered: null,
          allTims: false,
          emptyFilter: false,
          expiredFilter: false,
          rdpFiltered: undefined
        }
      }))
    }
  }, [])

  return (
    <>
      <ModelsFilterDrawer statusIntegration={statusIntegration} />
      <Stack width='100%'>
        <Stack direction="row" pt={1} pb={0.75}>
          {!userWithLessControls &&
            <Stack direction='row' spacing={1.25}>
              <PageActionButton startIcon={<AddIcon />} onClick={onAddModelClick}>
                Создать модель
              </PageActionButton>
              <PageActionButton startIcon={<DownloadingIcon />} onClick={onUploadClick}>
                Импорт excel
              </PageActionButton>
            </Stack>
          }
          <Stack direction='row' spacing={2.5} alignItems='center' pl={1} ml='auto'>
            <Tooltip title={null} variant="light" maxWidth={320}
              customTitle={
                (loadingStatusIntegration || statusIntegration !== 'ENABLED') &&
                <Stack spacing={1.5} p={1}>
                  <Typography variant="subtitle2" fontSize={18}>
                    Просмотр моделей недоступен
                  </Typography>
                  <Typography variant="body2">
                    Для просмотра моделей необходимо настроить интеграцию с Tangl в режиме администрирования.
                  </Typography>
                </Stack>
              }>
              <NavigationContainer direction='row' spacing={1}
                active={statusIntegration === 'ENABLED'} onClick={onViewModelClick}>
                {!loadingStatusIntegration && statusIntegration !== 'ENABLED' &&
                  <ErrorOutlineIcon fontSize='medium' color='action' />
                }
                <Typography variant="h2" fontSize={14}>Перейти в режим просмотра моделей</Typography>
                <ArrowForwardIcon color={loadingStatusIntegration ? "disabled" : "primary"} fontSize="medium" />
              </NavigationContainer>
            </Tooltip>
            <ViewModeButtonGroup viewMode={viewMode} onViewChange={onViewChange} />

          </Stack>
        </Stack>
        <Tabs<TimStatusLabels> currentTab={currentTab} onTabChange={onTabChange} tabsData={tabsData} />
        {isFetching ?
          <Progress />
          : models.length ?
            filteredData.length ?
              viewMode === 'list' ? (
                <Grid sx={{ py: 1.25 }} spacing={2.5} container>
                  {filteredData.map(tim => (
                    <Grid
                      item
                      xs={12} md={8} lg={6} xl={4} xxl={3} xxxl={2.2}
                      container
                      justifyContent='center'
                      key={tim.tim.id}
                    >
                      <ModelCard data={tim} onUpdateData={onUpdateData} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <AgGridModels models={filteredData} onUpdateData={onUpdateData} />
              )
              :
              <EmptyPage data={emptyFilteredData} />
            :
            <EmptyPage data={userWithLessControls ? emptyPageDataWithoutControls : emptyPageData} />
        }
      </Stack>
      <ModelDrawer
        closeDrawer={closeDrawer}
        setDefaultTab={() => setCurrentTab('Все модели')}
        objectId={objectFiltered}
        forUpdateData={forUpdateData}
        open={openedDrawer === 'tim'} />
      <TimUploadDrawer open={isShowUploadDrawer} close={closeUploadDrawer}/>
      <ConfirmDialog />
    </>
  )
}