import { Box, Button, CircularProgress, Drawer, Stack, Typography } from "@mui/material"
import { ManualSubmitDrawerProps, ManualSubmitValues } from "./ManualSubmitDrawer.types"
import { ManualSubmitDrawerHeader, ManualSubmitDrawerWrapper } from "./ManualSubmitDrawer.styles"
import Divider from "components/Divider"
import { ManualSubmitMainForm } from "../ManualSubmitMainForm/ManualSubmitMainForm"
import { useForm } from "hooks/useForm"
import { FormikProvider } from "formik"
import { ManualSubmitViewSelected } from "../ManualSubmitViewSelected"
import { ManualSubmitSelectForm } from "../ManualSubmitSelectForm"
import { useEffect, useState } from "react"
import { manualSubmitValidation } from "./ManualSubmitDrawer.utils"
import { useManualSendRemarkMutation } from "api/remarks"
import { useParams } from "react-router-dom"
import { getLocalTime } from "utils/getLocalTime"
import { formatDateToString } from "utils/formatDate"
import { DATE_FORMAT } from "utils/constants"
import { useMutationHandlers } from "hooks/useMutationHandlers"
import { useSnackbar } from "notistack"
import { MailType } from "@api/remarks/types"

const initialMailDate = new Date()

export const ManualSubmitDrawer = ({ open, closeDrawer, selectedPhase, remarkType }: ManualSubmitDrawerProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const [selectAll, setSelectAll] = useState<boolean>(false)

  const [manualSendRemark, manualSendRemarkResponse] = useManualSendRemarkMutation()

  const initialValues: ManualSubmitValues = {
    mailNum: 'б/н',
    mailDate: initialMailDate,
    responsibleCompany: '',
    responsibleId: null,
    copyToCompanies: [],
    file: null,
    selectedToms: []
  }

  const onSubmit = (values: ManualSubmitValues) => {
  const mailType: MailType = remarkType === 'incoming' ? 'RESPONSE' : 'REQUEST'
    manualSendRemark({
      file: values.file,
      id: projectId,
      localDateTime: getLocalTime(),
      mailType,
      type: selectedPhase,
      mail: {
        mailNum: values.mailNum,
        mailDate: formatDateToString(values.mailDate, DATE_FORMAT),
        responsibleCompany: values.responsibleCompany,
        responsibleId: values.responsibleId,
        copyToCompanies: values.copyToCompanies,
        toms: values.selectedToms.map(tom => tom.id)
      }
    })
  }

  const { formik } = useForm({
    validationSchema: manualSubmitValidation,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onSubmit(values)
  })

  const { dirty, values, isValid, setFieldValue } = formik

  useEffect(() => {
    if (remarkType === 'incoming') {
      setFieldValue('selectedToms', [])
    }
  }, [values.responsibleCompany])

  useEffect(() => {
    if (!open) {
      formik.resetForm()
    }
  }, [open])

  useMutationHandlers(
    manualSendRemarkResponse,
    (data) => {
      if (data) {
        enqueueSnackbar(`Замечания успешно направлены.`, { variant: 'success' })
        closeDrawer(false)
      }
    },
    (error) => {
      enqueueSnackbar(`Не удалось направить замечания.`, { variant: 'error' })
    }
  )

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => closeDrawer(dirty)}
    >
      <ManualSubmitDrawerWrapper>
        <FormikProvider value={formik}>
          <ManualSubmitDrawerHeader>
            <Typography variant="h1">
              {remarkType === 'incoming' ? 'Направить сопроводительное письмо' : 'Направить замечания на устранение'}
            </Typography>
          </ManualSubmitDrawerHeader>
          <Divider />
          <Stack direction='row' p={2.5} spacing={2.5} height='calc(100% - 146px)'>

            <ManualSubmitMainForm selectedPhase={selectedPhase} remarkType={remarkType}/>
            <ManualSubmitSelectForm
              selectedPhase={selectedPhase}
              selectAll={selectAll}
              remarkType={remarkType}
              disabled={remarkType === 'incoming' && !values.responsibleCompany}
              setSelectAll={(value) => setSelectAll(value)}
            />
            <ManualSubmitViewSelected
              setSelectAll={(value) => setSelectAll(value)}
            />

          </Stack>
          <Stack direction='row' spacing={2} width={400} p={2.5} alignSelf='flex-end'>
            <Box position='relative' width='100%'>
              <Button type="submit" fullWidth color="success"
                disabled={!dirty || !isValid || manualSendRemarkResponse.isLoading}
                onClick={() => onSubmit(values)}>
                Направить
              </Button>
              {(manualSendRemarkResponse.isLoading) && <CircularProgress
                size={24}
                color="success"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />}
            </Box>
            <Button type="submit" fullWidth
              onClick={() => closeDrawer(dirty)} >
              Отменить
            </Button>
          </Stack>
        </FormikProvider>
      </ManualSubmitDrawerWrapper>
    </Drawer>
  )
}