import { useState, MouseEvent, useMemo, useId, useEffect } from "react"
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import SearchIcon from '@mui/icons-material/Search'
import {
  Popper,
  Paper,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Avatar,
} from "@mui/material"
import { ReamarkListItemButton, ReamarSearchField, StyledIconButton } from "./RemarkTableMenu.styles"
import { RemarkTableMenuProps, SubMenuData } from "."
import { RemarkTableMenuData } from "../../RemarksTable.types"
import { useGetEmployeesByProjectQuery } from "@api/users"
import { useParams } from "react-router-dom"
import { useTypedSelector } from "@store/store"
import { currentCompanyIdSelector } from "@store/slices/profile"
import { ElipsisText } from "@styles/global/ElipsisText"

export const RemarkTableMenu = ({ data, disabled, responsibleCompany }: RemarkTableMenuProps) => {
  const id = useId()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const companyId = useTypedSelector(currentCompanyIdSelector)
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const [subMenuAnchor, setSubMenuAnchor] = useState<null | HTMLDivElement>(null)
  const [subMenu, setSubMenu] = useState<SubMenuData | null>(null)
  const [search, setSearch] = useState("")
  const itemsWithSubMenu = ['Назначить исполнителя', 'Назначить ответственного', 'Сменить исполнителя', 'Сменить ответственного']

  const { data: employeesByCompanyData } = useGetEmployeesByProjectQuery(
    { companyId, projectId, company: responsibleCompany! },
    { skip: !anchorEl || !responsibleCompany }
  )

  const filteredUsers = useMemo(() => {
    if (!employeesByCompanyData) return []
    const filtered =
    employeesByCompanyData.data.filter((user) => user.name.toLowerCase().includes(search.toLowerCase()))
      || employeesByCompanyData.data.filter((user) => user.position?.toLowerCase().includes(search.toLowerCase()))
    return filtered
  }, [search, employeesByCompanyData])

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSubMenu(null)
  }

  const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      e.stopPropagation()
      if (anchorEl) {
        handleMenuClose()
      } else {
        document.dispatchEvent(new CustomEvent("RemarkTableMenuOpen", { detail: id }))
        setAnchorEl(e.currentTarget)
      }
    }
  }

  const onSelectMenuItem = (e: MouseEvent, action: () => void) => {
    e.stopPropagation()
    action()
    handleMenuClose()
  }

  const handleAssignExecutor = (e: MouseEvent<HTMLDivElement>, item: RemarkTableMenuData) => {
    setSubMenuAnchor(e.currentTarget)
    setSubMenu({ item: item.itemName, action: item.action })
  }

  const handleSelectUser = (id: number, e: MouseEvent) => {
    e.stopPropagation()
    subMenu?.action(id)
    handleMenuClose()
  }

  useEffect(() => {
    const listener = (e: CustomEvent) => {
      if (e.detail !== id) {
        if (anchorEl) {
          handleMenuClose()
        }
      }
    };
    document.addEventListener("RemarkTableMenuOpen", listener as EventListener)
    return () => {
      document.removeEventListener("RemarkTableMenuOpen", listener as EventListener)
    };
  }, [id, anchorEl])

  return (
    <>
      <StyledIconButton onClick={handleMenuOpen} disabled={disabled}>
        <MoreVertIcon fontSize="small" color={disabled ? 'disabled' : 'primary'} />
      </StyledIconButton>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start">
        <Paper>
          <ClickAwayListener onClickAway={handleMenuClose}>
            <List disablePadding>
              {data && data.map((item) =>
                itemsWithSubMenu.includes(item.itemName) ? (
                  <ReamarkListItemButton key={item.itemName} onClick={e => e.stopPropagation()} onMouseEnter={(e) => handleAssignExecutor(e, item)} sx={{ paddingLeft: '16px!important' }}>
                    <ArrowLeftIcon fontSize="small" />
                    {item.itemName}
                  </ReamarkListItemButton>
                ) : (
                  <ReamarkListItemButton key={item.itemName} onClick={(e) => onSelectMenuItem(e, item.action)} onMouseEnter={() => setSubMenu(null)}>
                    {item.itemName}
                  </ReamarkListItemButton>
                )
              )}
            </List>
          </ClickAwayListener>
        </Paper>
      </Popper>
      <Popper open={subMenu !== null} anchorEl={subMenuAnchor} placement='left-start'>
        <Paper sx={{ width: 250, maxHeight: 350, overflowY: 'auto', overflowX: 'hidden' }} onMouseLeave={() => setSubMenu(null)}>
          <>
            <ReamarSearchField
              type="search"
              placeholder="Поиск"
              value={search}
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                startAdornment: (
                  <SearchIcon fontSize="medium" />
                ),
              }}
            />
            <List>
              {filteredUsers.map((user) => (
                <ListItem disablePadding key={user.id}>
                  <ListItemButton
                    onClick={(e) => handleSelectUser(user.id, e)}
                    sx={{ "&:hover": { backgroundColor: "#f0f0f0" } }}
                  >
                    <Stack direction='row' spacing={1}>
                      <Avatar sx={{ height: 32, width: 32 }} src={user.avatar}></Avatar>
                      <Stack maxWidth={165}>
                        <ElipsisText fontSize={14}>{user.name}</ElipsisText>
                        <ElipsisText variant="body2" fontSize={12}>{user.position}</ElipsisText>
                      </Stack>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        </Paper>
      </Popper>
    </>
  )
}
