import { api } from '../../api'
import {
  DeleteTomCmnVersionRequest,
  DownloadTomCmnVersionRequest,
  DownloadTomCmnVersionResponse,
  GetTomCmnVersionsRequest,
  GetTomCmnVersionsResponse,
  TomCmnVersionBaseResponse,
  UploadTomCmnVersionRequest
} from './tomVersionApi.types'
import { setCurrentDocument } from '../../../store/slices/documentsPages/tom'
import { ExportAttachmentResponseBack } from '../../attachments/types'

export const tomCmnVersionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomCmnVersions: build.query<GetTomCmnVersionsResponse, GetTomCmnVersionsRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom-cmn/pdf/list`,
        params,
        method: 'GET',
      }),
      transformResponse: (res: GetTomCmnVersionsResponse, _, requestData) => {
        const { id: tomId } = requestData
        const updatedVersions = res.data.map(version => ({ ...version, tomId }))

        return {
          ...res,
          data: updatedVersions
        }
      },
      providesTags: ['TomCmnDoc', 'AgreementOverview'],
    }),
    uploadTomCmnVersion: build.mutation<TomCmnVersionBaseResponse, UploadTomCmnVersionRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/${id}/tom-cmn/pdf/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedVersionData } = await queryFulfilled
          const uploadedVersion = uploadedVersionData.data

          // getTomCmnVersions
          const getTomCmnVersionsKeys = Object.keys(state.api.queries).filter(key => key.includes('getTomCmnVersions'))
          const getTomCmnVersionsLastKey = getTomCmnVersionsKeys[getTomCmnVersionsKeys?.length - 1]

          dispatch(
            tomCmnVersionApi.util.updateQueryData(
              'getTomCmnVersions',
              state.api.queries[getTomCmnVersionsLastKey]?.originalArgs as GetTomCmnVersionsRequest,
              (draft) => {
                draft.data.unshift(uploadedVersion)
              }
            )
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: uploadedVersion }))

        } catch {
        }
      },
    }),
    deleteTomCmnVersion: build.mutation<TomCmnVersionBaseResponse, DeleteTomCmnVersionRequest>({
      query: ({ id, version }) => ({
        url: `/project/${id}/tom-cmn/pdf/delete`,
        params: { version },
        method: 'DELETE',
      }),
      invalidatesTags: ['TomCmnDoc'],
    }),
    downloadTomCmnVersion: build.mutation<DownloadTomCmnVersionResponse, DownloadTomCmnVersionRequest>({
      query: ({ id, version }) => ({
        url: `/project/${id}/tom-cmn/pdf/download`,
        params: { version },
        method: 'GET',
      }),
    }),
    // getTomCmnVersionLink: build.mutation<string, GetTomCmnVersionLinkRequest>({
    //   query: ({ id, version }) => ({
    //     url: `/project/tom-cmn/${id}/pdf/get-link`,
    //     params: { version },
    //     method: 'GET',
    //   }),
    // }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomCmnVersionsQuery,
  useUploadTomCmnVersionMutation,
  useDeleteTomCmnVersionMutation,
  useDownloadTomCmnVersionMutation,
} = tomCmnVersionApi
