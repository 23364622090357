import { Menu, MenuItem, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import { useGetProjectByIdQuery, useGetProjectsQuery } from '../../api/projects'
import { GetProjectsResponse } from '../../api/projects/api.types'
import Header from '../../components/Header'
import Progress from '../../components/Progress'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import { InnerContainer, MainContainer } from '../HomeLayout/styles'
import { AgreementNavbar } from './AgreementNavbar'

export const AgreementLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { projectId: projectIdString, tomId: tomIdString } = useParams()
  const projectId = Number(projectIdString)
  const tomId = Number(tomIdString)
  const {
    data: projectData,
    isLoading: projectLoading,
    isFetching: isProjectFetching,
  } = useGetProjectByIdQuery({ id: projectId })
  const { data: project } = projectData || {}
  const projectName = project?.shortName || ''
  const loading = projectLoading || isProjectFetching
  const isRoadmap = location.pathname.includes('roadmap')

  const { data: projectsData, isLoading: projectsLoading, isFetching: projectsFetching } = useGetProjectsQuery({})
  const { data: projects } = projectsData || ({} as GetProjectsResponse)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const MenuOpen = Boolean(anchorEl)

  const ProjectBreadClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onMenuItemClick = useCallback(
    (projectId: number) => {
      tomId
        ? navigate(`/project/${projectId}/toms`)
        : navigate(`/project/${projectId}/schemes/${isRoadmap ? 'roadmap' : 'innermap'}`)
      onClose()
    },
    [onClose, isRoadmap, tomId],
  )

  useBreadcrumbs(
    [
      {
        title: projectName,
        onClick: (e) => ProjectBreadClick(e),
        MenuOpen,
        type: 'project',
      },
      { title: tomId ? 'Согласование' : isRoadmap ? 'Внешние схемы' : 'Внутренние схемы' },
    ],
    [MenuOpen, projectName, isRoadmap, tomId],
    loading,
  )

  return (
    <>
      {!tomId && <AgreementNavbar />}
      <MainContainer>
        <Header isHasSearch={false} isShowNavButtons={!!tomId} isShowLogo={!!tomId} />
        <InnerContainer>
          <Outlet />
        </InnerContainer>
      </MainContainer>
      <Menu
        anchorEl={anchorEl}
        open={MenuOpen}
        onClose={onClose}
        MenuListProps={{
          style: {
            width: '100%',
          },
        }}
        PaperProps={{
          style: {
            display: 'flex',
            justifyContent: 'center',
            minWidth: 200,
            maxWidth: 400,
            maxHeight: 300,
          },
        }}
      >
        {projectsLoading || projectsFetching ? (
          <Progress />
        ) : projects?.length > 1 ? (
          projects
            .filter((project) => project.id !== projectId!)
            ?.map((project) => (
              <MenuItem onClick={() => onMenuItemClick(project.id)} style={{ maxWidth: 450 }} key={project.id}>
                <Typography
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {project.shortName}
                </Typography>
              </MenuItem>
            ))
        ) : (
          <MenuItem style={{ maxWidth: 450 }} disabled>
            <Typography>Другие проекты отсутствуют</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
