import React from 'react'

import { useGetTomCmnVersionsQuery } from '@api/cmnTom'
import { useGetTomIiVersionsQuery } from '@api/iiPhase'
import { useGetTomIrdVersionsQuery } from '@api/irdPhase'
import { useGetTomPdVersionsQuery } from '@api/pdPhase'
import { useGetTomRdVersionsQuery } from '@api/rdPhase'

import { ProjectPhase } from '@typesGlobal/project'
import { TomDocument } from '@typesGlobal//tom'
import { UseGetVersionsProps, UseGetVersionsResponse } from './useGetVersions.types'

export const useGetVersions = ({
  currentTomId = 0,
  currentPage,
  selectedProjectPhase,
  isCmnTom,
}: UseGetVersionsProps): UseGetVersionsResponse => {
  // Rd
  const { data: tomRdVersionsData, isFetching: tomRdVersionsFetching } = useGetTomRdVersionsQuery(
    { id: currentTomId },
    { skip: currentPage !== 'doc-view' || !currentTomId || selectedProjectPhase !== 'Рабочая документация' },
  )
  const { data: tomRdVersions = [] } = tomRdVersionsData || {}

  // Pd
  const { data: tomPdVersionsData, isFetching: tomPdVersionsFetching } = useGetTomPdVersionsQuery(
    { id: currentTomId },
    { skip: currentPage !== 'doc-view' || !currentTomId || selectedProjectPhase !== 'Проектная документация' },
  )
  const { data: tomPdVersions = [] } = tomPdVersionsData || {}

  // Ii
  const { data: tomIiVersionsData, isFetching: tomIiVersionsFetching } = useGetTomIiVersionsQuery(
    { id: currentTomId },
    { skip: currentPage !== 'doc-view' || !currentTomId || selectedProjectPhase !== 'Инженерные изыскания' },
  )
  const { data: tomIiVersions = [] } = tomIiVersionsData || {}

  // Ird
  const { data: tomIrdVersionsData, isFetching: tomIrdVersionsFetching } = useGetTomIrdVersionsQuery(
    { id: currentTomId },
    { skip: currentPage !== 'doc-view' || !currentTomId || selectedProjectPhase !== 'Сбор исходных данных' },
  )
  const { data: tomIrdVersions = [] } = tomIrdVersionsData || {}

  // Cmn
  const { data: tomCmnVersionsData, isFetching: tomCmnVersionsFetching } = useGetTomCmnVersionsQuery(
    { id: currentTomId },
    { skip: currentPage !== 'doc-view' || !currentTomId || selectedProjectPhase !== 'Проектная документация' },
  )
  const { data: tomCmnVersions = [] } = tomCmnVersionsData || {}

  // Common
  const tomVersionsByPhase: Record<ProjectPhase, TomDocument[]> = {
    'Рабочая документация': tomRdVersions,
    'Проектная документация': tomPdVersions,
    'Инженерные изыскания': tomIiVersions,
    'Сбор исходных данных': tomIrdVersions,
  }

  if (!selectedProjectPhase)
    return {
      tomVersions: [],
      versionsLoading: true,
    }
  const tomVersions = isCmnTom ? tomCmnVersions : tomVersionsByPhase[selectedProjectPhase]

  const versionsLoading =
    tomRdVersionsFetching ||
    tomPdVersionsFetching ||
    tomIiVersionsFetching ||
    tomIrdVersionsFetching ||
    tomCmnVersionsFetching

  return {
    tomVersions,
    versionsLoading,
  }
}
