import * as yup from 'yup'

import { format, isDate } from 'date-fns'

export function parseDateString(value: any, originalValue: any) {
  if (!originalValue) return null

  const firstFormattedDate = format(originalValue, 'MM/dd/yy')
  const receivedYear = firstFormattedDate.split('/')[2]
  const finalYear = receivedYear.length === 2 ? `20${receivedYear}` : receivedYear
  const secondFormattedDate = [ ...firstFormattedDate.split('/').slice(0, 2), finalYear]

  const formattedDate = new Date(secondFormattedDate.join('/'))
  const parsedDate = isDate(formattedDate)
    ? formattedDate
    : null

  return parsedDate
}

const today = new Date()

export const validationProject = yup.object({
  shifrName: yup.string().trim().required('common:errors.required'),
  shortName: yup.string().trim().required('common:errors.required'),
  fullName: yup.string().trim().required('common:errors.required'),
  status: yup.string().required('common:errors.required'),
  phase: yup.string().required('common:errors.required'),
  type: yup.string().required('common:errors.required'),
  startDate: yup.date().transform(parseDateString).nullable().required('common:errors.required').typeError('Неверный формат даты'),
  endDate: yup.date().transform(parseDateString).min(today, 'Дата не может быть раньше сегодняшней')
    .min(yup.ref('startDate'), 'Дата окончания должна быть позже даты начала').nullable().required('common:errors.required').typeError('Неверный формат даты'),
  link: yup.object({
    url: yup.string().url('Строка должна быть ссылкой URL')
  }),
})
