import { Accordion, AccordionDetails, AccordionSummary, alpha, FormControlLabel } from "@mui/material";
import styled from "styled-components";

export const StyledAccordion = styled(Accordion)`
    width: 100%;
  box-shadow: none;

  &.Mui-disabled {
    background-color: inherit;
  }

  &::before {
    display: none;
  }
`
export const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 8px;
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 4px;

  & .MuiFormControlLabel-root {
    margin: 0;

    & .MuiCheckbox-root {
      &.Mui-checked {
        & .MuiSvgIcon-root {
          color: ${props => props.theme.palette.primary.main};
        }
      }

      & .MuiSvgIcon-root {
        color: ${props => alpha(props.theme.palette.disabled!, 0.35)};
      }
    }
  }

  &.Mui-expanded {
    min-height: 34px;
  }

  & .MuiAccordionSummary-content {
    margin: 8px 0;
  }

  & .MuiCheckbox-root {
    padding: 0;
  }
  
  & .MuiAccordionSummary-expandIconWrapper {
    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.text.dark};
    }
  }
  
  & .MuiTypography-root {
    font-weight: 400;
    color: ${props => props.theme.palette.text.dark};
  }
`
export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  padding-top: 8px;

  & .MuiFormControlLabel-root {
    width: fit-content;
    height: 40px;
    margin-right: 0;

    & .MuiCheckbox-root {
      &.Mui-checked {
        & .MuiSvgIcon-root {
          color: ${props => props.theme.palette.primary.main};
        }
      }
    
      & .MuiSvgIcon-root {
        color: ${props => alpha(props.theme.palette.disabled!, 0.35)};
      }
    }
  }
`
export const StyledCheckbox = styled(FormControlLabel)<{disabled: boolean}>`
  margin-left: 0;

  & .MuiCheckbox-root {
    margin-right: 8px;
  }

  & .MuiTypography-root {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${props => props.disabled
    ? props.theme.palette.disabled
    : props.theme.palette.primary.main};
  }
`
export const StyledSubItemsCheckbox = styled(StyledCheckbox)<{disabled: boolean}>`
  & .MuiCheckbox-root {
    padding: 0;
  }
  
  & .MuiSvgIcon-root {
    color: ${props => props.theme.palette.text.dark + '1F'} !important;
  }

  & .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
    color: ${props => props.theme.palette.primary.main} !important;
  }

  & .MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    color: ${props => props.disabled
    ? props.theme.palette.disabled
    : props.theme.palette.text.dark};
  }
`

