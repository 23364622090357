import * as yup from 'yup'
import { UploadPropsData } from '../../../../components/FileUpload/FileUpload.types'

export const versionValidation = () => yup.object({
  description: yup.string().trim(),
  file: yup.mixed().required()
})

export const uploadData: UploadPropsData = {
  title: 'Загрузить Файл',
  text: 'Чтобы загрузить документ, выберите его из папки на компьютере или просто перетяните его в эту область. Максимальный размер файла 256 Мб.',
  formats: [],
  maxSize: 256 * 1024 * 1024
/*   mimeTypes: {
    '.rvt': 'application/vnd.autodesk.revit',
    '.ifc': 'application/octet-stream'
  } */
}