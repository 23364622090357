import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RemarksState } from './remarks.types'
import { Mark } from '@api/remarks/types'

let initialState: RemarksState = {
  remarkFormData: {
    page: null,
    totalPages: null,
    version: null,
    change: null,
    type: '',
    comment: '',
    reason: '',
    fileList: null,
    remarkId: null,
    tomId: undefined,
  },
  remarkPage: null,
  selectedRemarkId: null,
  remarkFormMode: 'create',
  remarkPhaseFilter: undefined,
  allowScrolling: {
    allowScrolling: false,
    mode: 'create',
    id: null,
  },
  remarkGroupFilter: {
    second: undefined,
    group: null,
    obj: null,
    authors: null,
    responsible: null,
    executors: null
  },
  remarkHistoryData: {
    remarkId: null,
    index: null,
  },
  isAnnotationMode: false,
  remarkAnnotations: null,
  getAnnotations: false,
  selectedAnnotationId: null,
  markCount: null,
  getClickHandler: false
}

const remarksSlice = createSlice({
  name: 'remarks',
  initialState,
  reducers: {
    setRemarkFormData: (state, { payload }: PayloadAction<Pick<RemarksState, 'remarkFormData'>>) => {
      state.remarkFormData = payload.remarkFormData
    },
    setRemarkPage: (state, { payload }: PayloadAction<number | null>) => {
      state.remarkPage = payload
    },
    setSelectedRemarkId: (state, { payload }: PayloadAction<number | null>) => {
      state.selectedRemarkId = payload
    },
    setRemarkFormMode: (state, { payload }: PayloadAction<Pick<RemarksState, 'remarkFormMode'>>) => {
      state.remarkFormMode = payload.remarkFormMode
    },
    setRemarkPhaseFilter: (state, { payload }: PayloadAction<Pick<RemarksState, 'remarkPhaseFilter'>>) => {
      state.remarkPhaseFilter = payload.remarkPhaseFilter
    },
    setRemarkGroupFilter: (state, { payload }: PayloadAction<Pick<RemarksState, 'remarkGroupFilter'>>) => {
      state.remarkGroupFilter = payload.remarkGroupFilter
    },
    setAllowScrolling: (state, { payload }: PayloadAction<Pick<RemarksState, 'allowScrolling'>>) => {
      state.allowScrolling = payload.allowScrolling
    },
    setRemarkHistoryData: (state, { payload }: PayloadAction<Pick<RemarksState, 'remarkHistoryData'>>) => {
      state.remarkHistoryData = payload.remarkHistoryData
    },
    setIsAnnotationMode: (state, { payload }: PayloadAction<boolean>) => {
      state.isAnnotationMode = payload
    },
    setRemarkAnnotations: (state, { payload }: PayloadAction<Mark[] | null>) => {
      state.remarkAnnotations = payload
    },
    getAnnotations: (state, { payload }: PayloadAction<boolean>) => {
      state.getAnnotations = payload
    },
    selectAnnotationId: (state, { payload }: PayloadAction<number | null>) => {
      state.selectedAnnotationId = payload
    },
    setMarkCount: (state, { payload }: PayloadAction<number | null>) => {
      state.markCount = payload
    },
    getClickHandler: (state, { payload }: PayloadAction<boolean>) => {
      state.getClickHandler = payload
    },
  },
})

export const { reducer: remarksReducer } = remarksSlice
export const {
  setRemarkFormData,
  setRemarkPage,
  setSelectedRemarkId,
  setRemarkFormMode,
  setRemarkPhaseFilter,
  setRemarkGroupFilter,
  setAllowScrolling,
  setRemarkHistoryData,
  setIsAnnotationMode,
  setRemarkAnnotations,
  getAnnotations,
  selectAnnotationId,
  setMarkCount,
  getClickHandler,
} = remarksSlice.actions
