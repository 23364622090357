import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ObjectsState } from './objects.types'
import { SetRdViewTabPayload } from './objects.payloads.types'


let initialState: ObjectsState = {
  rdViewTab: 'objects',
}


const objectsSlice = createSlice({
  name: 'objectsSliceState',
  initialState,
  reducers: {
    setRdViewTab: (state, { payload }: PayloadAction<SetRdViewTabPayload>) => {
      const { rdViewTab } = payload
      state.rdViewTab = rdViewTab
    },
  },
})

export const { reducer: objectsReducer } = objectsSlice
export const { setRdViewTab } = objectsSlice.actions
