import { ExcelUploadResponse } from "@typesGlobal/global"
import { api } from "../api"
import { AddTimVersionRequest, AddTimVersionResponse, createTimAndUploadRequest, createTimAndUploadResponse, CreateTimRequest, CreateTimResponse, DeleteTimRequest, DeleteTimResponse, DeleteTimVersionRequest, DeleteTimVersionResponse, DownloadTimRequest, GetTimByIdRequest, GetTimByIdResponse, GetTimExcelExampleRequest, GetTimExcelExampleResponse, GetTimLimitRequest, GetTimListResponse, GetTimSideMenuRequest, GetTimSideMenuResponse, GetTimSideMenuViewerRequest, GetTimSideMenuViewerResponse, GetTimVersionsRequest, GetTimVersionsResponse, UpdateTimRequest, UpdateTimResponse, UploadTimExcelRequest } from "./api.types"

export const timApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTimList: build.query<GetTimListResponse, GetTimLimitRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tim/list`,
        method: 'GET',
        params
      }),
      providesTags: ['Tim'],
    }),
    getTimSideMenu: build.query<GetTimSideMenuResponse, GetTimSideMenuRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tim/side-menu-filter`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Tim', id: 'SIDE_MENU' }],
    }),
    getTimById: build.query<GetTimByIdResponse, GetTimByIdRequest>({
      query: ({ id, timId }) => ({
        url: `/project/${id}/tim/${timId}/get`,
        method: 'GET',
      }),
      providesTags: ['Tim'],
    }),
    createTim: build.mutation<CreateTimResponse, CreateTimRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/tim/add`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Tim', 'ObjectsByProject', { type: 'Projects', id: 'dashboard' }],
    }),
    createTimAndUpload: build.mutation<createTimAndUploadResponse, createTimAndUploadRequest>({
      query: ({ id, file, ...body }) => {
        const formData = new FormData()
        Object.entries(body).forEach(([key, value]) => {
          formData.append(key, JSON.stringify(value))
        })
        if (file) {
          formData.append('file', file)
        }
        return {
          url: `/project/${id}/tim/add-and-upload`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: ['Tim', 'ObjectsByProjectUpload', { type: 'Projects', id: 'dashboard' }],
    }),
    updateTim: build.mutation<UpdateTimResponse, UpdateTimRequest>({
      query: ({ id, timId, ...body }) => ({
        url: `/project/${id}/tim/${timId}/update`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Tim', { type: 'Projects', id: 'dashboard' }],
    }),
    deleteTim: build.mutation<DeleteTimResponse, DeleteTimRequest>({
      query: ({ id, timId }) => ({
        url: `/project/${id}/tim/${timId}/delete`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Tim', 'ObjectsByProject', { type: 'Projects', id: 'dashboard' }],
    }),
    getTimVersions: build.query<GetTimVersionsResponse, GetTimVersionsRequest>({
      query: ({ id, timId }) => ({
        url: `/project/${id}/tim/${timId}/version/list`,
        method: 'GET',
      }),
      providesTags: ['Tim'],
    }),
    addTimVersion: build.mutation<AddTimVersionResponse, AddTimVersionRequest>({
      query: ({ id, timId, file, description }) => {
        const formData = new FormData()
        description && formData.append('description', description)
        formData.append('file', file)
        return {
          url: `/project/${id}/tim/${timId}/version/upload`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: ['Tim'],
    }),
    downloadTim: build.mutation<Blob, DownloadTimRequest>({
      query: ({ id, timId, ...params }) => ({
        url: `/project/${id}/tim/${timId}/version/download`,
        method: 'POST',
        params,
        responseHandler: (response: any) => response.blob()
      }),
    }),
    deleteTimVersion: build.mutation<DeleteTimVersionResponse, DeleteTimVersionRequest>({
      query: ({ id, timId, ...params }) => ({
        url: `/project/${id}/tim/${timId}/version/delete`,
        method: 'DELETE',
        params
      }),
      invalidatesTags: ['Tim'],
    }),
    getTimSideMenuViewer: build.query<GetTimSideMenuViewerResponse, GetTimSideMenuViewerRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tim/side-menu-viewer`,
        method: 'GET',
      }),
      providesTags: ['Tim'],
    }),
    getTimExcelExample: build.mutation<GetTimExcelExampleResponse, GetTimExcelExampleRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tim/excel/link-example`,
        method: 'GET',
      })
    }),
    uploadTimExcel: build.mutation<ExcelUploadResponse, UploadTimExcelRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/${id}/tim/excel/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled

        if (data.success) {
          dispatch(timApi.util.invalidateTags(['Tim']))
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTimListQuery,
  useGetTimSideMenuQuery,
  useGetTimByIdQuery,
  useCreateTimMutation,
  useCreateTimAndUploadMutation,
  useUpdateTimMutation,
  useDeleteTimMutation,
  useGetTimVersionsQuery,
  useAddTimVersionMutation,
  useDownloadTimMutation,
  useDeleteTimVersionMutation,
  useGetTimSideMenuViewerQuery,
  useGetTimExcelExampleMutation,
  useUploadTimExcelMutation,
} = timApi