import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Stack, Typography } from '@mui/material'
import { useGetProjectDashboardQuery } from 'api/projects'
import { Project } from 'api/projects/types'
import Progress from 'components/Progress'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { MouseEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { downloadBlob } from 'utils/dowloadBlob'
import { getLocalTime } from 'utils/getLocalTime'

import { setRemarkGroupFilter } from '@store/slices/remarks/remarks'
import { useAppDispatch } from '@store/store'

import { useGetRemarksWidgetQuery, useExportRemarksMutation } from '../../../../api/remarks'
import { RemarkStatus, dashboardRemarkStatuses } from '../../../../api/remarks/types'
import SmallDownloadIcon from '../../../../assets/icons/SmallDownloadIcon'
import { ProjectPhase, projectPhaseRuToEn } from '../../../../types/project'
import { determineTomType } from '../../../../types/tom'
import { remarkStatusEnToRu } from '../../../DocView/components/DocViewRightSideBar/components/Remark'
import { PhaseChangeButton } from '../../../Home/HomeCard/CardStatistics/CardStatistics.styles'
import { PhaseCardData } from '../../../Home/HomeCard/CardStatistics/CardStatistics.types'
import { MediumTemplateWrapper } from '../InfoCard/InfoCard.styles'
import { InfoBlockWrapper, InfoCardButton } from '../PhaseCardContent'

export const RemarksCard = () => {
  const { projectId: projectIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)
  const navigate = useNavigate()
  const { data: projectRes, isFetching } = useGetProjectDashboardQuery({ id: projectId! }, { skip: !projectId })
  const { project } = projectRes?.data || {}
  const [phaseFilter, setPhaseFilter] = useState<ProjectPhase>(project?.phase || 'Инженерные изыскания')
  const { data: remarksInfo } = useGetRemarksWidgetQuery({ projectId }, { skip: !projectId })
  const phaseEn = projectPhaseRuToEn[phaseFilter]
  const [exportRemarks, exportRemarksResponse] = useExportRemarksMutation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const buttonsData: PhaseCardData[] = [
    { title: 'ИРД', phase: 'Сбор исходных данных' },
    { title: 'ИИ', phase: 'Инженерные изыскания' },
    { title: 'ПД', phase: 'Проектная документация' },
    { title: 'РД', phase: 'Рабочая документация' },
  ]

  const onCardClick = () => {
    dispatch(setRemarkGroupFilter({ remarkGroupFilter: { second: undefined, obj: null, group: null, authors: null } }))
    navigate(`remarks?phase=${determineTomType(phaseFilter!)}`)
  }

  const onCardStatusClick = (e: MouseEvent, status: RemarkStatus) => {
    e.stopPropagation()
    navigate(`remarks?phase=${determineTomType(phaseFilter!)}&status=${status}`)
  }

  const onPhaseFilter = (e: MouseEvent<HTMLButtonElement>, buttonPhase: ProjectPhase) => {
    e.stopPropagation()
    setPhaseFilter(buttonPhase)
  }

  const onExportClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    exportRemarks({
      projectId,
      localDateTime: getLocalTime(),
      status: null,
      type: determineTomType(phaseFilter),
    })
  }

  useMutationHandlers(exportRemarksResponse, (data) => {
    downloadBlob(
      new File([data], ''),
      `Реестр замечаний по проекту ${project?.shifrName} ${exportRemarksResponse.originalArgs?.localDateTime}.xlsx`,
    )
    enqueueSnackbar('Файл успешно сформирован.', { variant: 'success' })
  })

  return (
    <MediumTemplateWrapper height='100%' onClick={onCardClick}>
      <Typography variant='h2' textAlign='left' fontSize={16}>
        Реестр замечаний
      </Typography>

      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={0.5}>
        {buttonsData.map(({ title, phase: buttonPhase }) => (
          <PhaseChangeButton
            onClick={(e) => onPhaseFilter(e, buttonPhase)}
            checked={phaseFilter === buttonPhase}
            color='secondary'
            variant='outlined'
            fullWidth
          >
            {title}
          </PhaseChangeButton>
        ))}
      </Stack>

      <Stack flex={1} justifyContent='space-between' spacing={3}>
        <InfoBlockWrapper>
          {dashboardRemarkStatuses.map((status) => {
            const totalRemarks = remarksInfo ? remarksInfo[phaseEn][status] : 0
            const allStatuses = status === 'all'
            return (
              <InfoCardButton
                key={status}
                variant='text'
                onClick={(e) => allStatuses ? undefined : onCardStatusClick(e, status)}
                disabled={totalRemarks === 0}
                endIcon={<Typography variant='body2'>{totalRemarks}</Typography>}
              >
                {allStatuses ? 'Общее кол-во замечаний' : remarkStatusEnToRu[status]}
              </InfoCardButton>
            )
          })}
        </InfoBlockWrapper>

        <InfoBlockWrapper>
          {exportRemarksResponse.status === 'pending' ? (
            <Progress size={'24px'} />
          ) : (
            <InfoCardButton variant='text' onClick={onExportClick} startIcon={<SmallDownloadIcon fontSize='small' />}>
              Экспортировать реестр
            </InfoCardButton>
          )}
        </InfoBlockWrapper>
      </Stack>
    </MediumTemplateWrapper>
  )
}
