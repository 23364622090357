import styled from 'styled-components'
import { Box } from '@mui/material'

export const StyledLabel = styled(Box)`
  background-color: ${(props) => props.theme.palette.primary.main};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  height: 24px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.text.light};
`

export const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  transform: scale(1) translate(50%, -50%);
  z-index: 2;

  & svg {
    width: 10px;
    height: 10px;
    fill: ${(props) => props.theme.palette.bg.white};
  }
`
