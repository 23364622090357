import { EmployeeProjectProfile } from 'api/users/types'

import { FilterValues } from '../../pages/DocView/components/DocViewRightSideBar'
import { ProjectPhaseEn } from '../../types/project'
import { TomStatus, TomType } from '../../types/tom'
import { UserRolesEn } from '../../types/user'
import { Annotation } from '@pages/DocView/components/PragmaPdfViewer'

export interface RemarkDataSend {
  change?: number | null
  comment: string
  page: number | string | null
  reason: string | null
  type: string | null
}

export interface RemarkAuthor {
  avatar: string | null
  company: string | null
  id: number
  name: string
  position: string | null
  role: UserRolesEn
}

export type MailType = 'REQUEST' | 'RESPONSE'
export type RemarkType = 'incoming' | 'outgoing'
export type RemarkStatus = 'accepted' | 'closed' | 'created' | 'processed' | 'submitted'
export const dashboardRemarkStatuses: (RemarkStatus | 'all')[] = [
  'created',
  'submitted',
  'accepted',
  'processed',
  'closed',
  'all'
]

export interface RemarkLinkedTom {
  change: number | null
  id: number
  page: number | null
  title: string
  type: TomType
  version: number | null
}

export interface RemarkProjectGroup {
  head: RemarkMailHead | null
  rows: RemarkProjectItem[]
}

export interface RemarkMailHead {
  btnState: HeadBtnState
  mail: HeadMail
}

export interface HeadMail {
  id: number
  mailDate: string
  mailNum: string
  mailSender: EmployeeProjectProfile | null
  responsible: EmployeeProjectProfile | null
  responsibleCompany: string
}

export interface HeadBtnState {
  attachments: 'NONE' | 'SINGLE' | 'MANY'
  executors: 'EMPTY' | 'EXISTS'
}

export interface RemarkProjectItem {
  row: RemarkProjectRow
  secondRows: RemarkProjectRow[] | null
}

export interface RemarkProjectRow {
  answer: RemarkRowMessage
  question: RemarkRowMessage
  remark: RemarkRowInfo
  tom: RemarkLinkedTom
}

export interface RemarkAccessMessage {
  canAddMessage: boolean
  canEditAnswer: boolean
  canEditRemark: boolean
}

export interface Remark {
  access: RemarkAccessMessage
  messages: RemarkMessages[]
  remark: RemarkInfo
  mail: RemarkMailData | null
  totalPages: number | null
}
export interface TotalRemakStatus extends Record<RemarkStatus | 'all', number> {}
export interface RemarkWidget extends Record<ProjectPhaseEn, TotalRemakStatus> {}

export interface AmountRemarks extends Record<FilterValues, number> {}

export interface MessageAttachment {
  fileSize: number
  id: number
  name: string
}

// История замечаний, удалено из функционала 13.02.25
/* export interface RemarkMail {
  attachment: Omit<MessageAttachment, 'id'>
  excel: Omit<MessageAttachment, 'id'>
  mail: RemarkMailData
} */

export interface RemarkMailData {
  id: number
  createdAt: string
  creator: EmployeeProjectProfile
  isAutoSent: boolean
  mailDate: string
  mailNum: string
  responsible: EmployeeProjectProfile | null
  responsibleCompany: string
}

export interface RemarkMessages {
  attachments: MessageAttachment[]
  message: RemarkMessage
  marks?: Mark[]
  mail: RemarkMailData | null
}

export interface RemarkMessage {
  author: RemarkAuthor
  comment: string | null
  createdAt: string
  id: number
  reason: string | null
  status: RemarkStatus
  version: number
}

export interface RemarkRowMessage {
  author: RemarkAuthor
  comment: string | null
  createdAt: string
  id: number
  reason: string | null
}

export interface RemarkInfo {
  author: RemarkAuthor
  change: number | null
  createdAt: string
  updatedAt: string
  executor: RemarkAuthor | null
  id: number
  isSecond: boolean
  page: number | null
  status: RemarkStatus
  type: string | null
}

export interface RemarkRowInfo {
  executor: RemarkAuthor | null
  id: number
  isSecond: boolean
  status: RemarkStatus
}

export interface ReplyMessage {
  comment: string | null
  reason: string | null
  status: RemarkStatus
}

export interface RemarkMenuGroup {
  id: number
  name: string
}

export interface RemarkMenuObject {
  groups: RemarkMenuGroup[]
  id: number
  name: string
  number: string
}

export interface RemarkMenuFilter {
  groups: RemarkMenuGroup[]
  objects: RemarkMenuObject[]
}

export interface RemarkTom {
  id: number
  title: string
}

export interface RemarkMailItems {
  groups: RemarkMailGroup[]
  objects: RemarkMailObject[]
}

export interface RemarkPersonFilter {
  company: string
  persons: RemarkCompanyPersonFilter[]
}

export interface RemarkCompanyPersonFilter {
  id: number
  name: string
}

export interface RemarkMailGroup {
  name: string
  toms: RemarkMailTom[]
}

export interface RemarkMailObject {
  groups: RemarkMailGroup[]
  name: string
  number: string
}

export interface RemarkMailTom {
  id: number
  name: string
  status: TomStatus
}

export interface RemarkMailDataSend {
  mailDate: string
  mailNum: string
  responsibleCompany: string
  responsibleId: number | null
  copyToCompanies: string[]
  toms: number[]
}

export interface RemarkAnnotation extends Omit<Annotation, 'id'> {}

export interface Mark {
  id: number | null
  annotation: RemarkAnnotation
}

