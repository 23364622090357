import { Avatar, Box, Stack, Typography } from '@mui/material'
import { DocumentSigning, DocumentSigningSource } from '@seven-winds-studio/react-cades'
import { DownloadCertificateButton } from '@seven-winds-studio/react-cades'
import { useCallback, useMemo, useState } from 'react'

import { InnermapActionsType } from '../../../../../../../api/internalAgreement/types'
import { ShiftDownContainedIcon, ShiftDownOutlinedIcon } from '../../../../../../../assets/icons/ShiftDownIcon'
import { ShiftUpContainedIcon, ShiftUpOutlinedIcon } from '../../../../../../../assets/icons/ShiftUpIcon'
import Tooltip from '../../../../../../../components/Tooltip'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../../../hooks/useConfirmDialog'
import {
  currentDocumentSelector,
  docsSelector,
  tomSelector,
} from '../../../../../../../store/slices/documentsPages/tom'
import { profileSelector } from '../../../../../../../store/slices/profile'
import { useTypedSelector } from '../../../../../../../store/store'
import { convertDateTime } from '../../../../../../../utils/formatDateAndTimeGMT'
import { useAccess } from '../../../../InternalAgreementSchemes.utils'
import { TableRow } from '../../InternalAgreementTable.styles'
import { MembersMenu } from '../MembersMenu'
import {
  ApprovalIcon,
  AssuranceIcon,
  ButtonDownloadSignatureWrapper,
  IconWrapper,
  RemarksIcon,
  SigningIcon,
  StyledButton,
  StyledMoveCell,
  StyledName,
  StyledPosition,
} from './MemberRow.styles'
import { MemberRowProps } from './MemberRow.types'
import { is } from 'date-fns/locale'

export const MemberRow = ({
  allowMove,
  isApproved,
  isFirst,
  isLast,
  member,
  isSelectedProcedure,
  editMember,
  deleteMember,
  moveUpMember,
  moveDownMember,
  sendMemberAction,
  memberActionError,
  resetMemberActionError,
}: MemberRowProps) => {
  const { isAdmin, isModerator } = useAccess()
  const { approval, assurance, remarks, signing } = member.actionLogs

  const { employeeId } = useTypedSelector(profileSelector)
  const { tomVersions } = useTypedSelector(docsSelector)
  const { tom } = useTypedSelector(tomSelector)
  const { currentDocument } = useTypedSelector(currentDocumentSelector)

  const isAnnulled = tom?.features.annulment.annulled
  const hasAccess = employeeId === member.person.id || isAdmin || isModerator

  const [pluginSelectionOpen, setPluginSelectionOpen] = useState(false)

  const fileName = useMemo(() => currentDocument?.link?.split('/').pop() || '', [currentDocument.link])
  const documentName = `${tom?.title || ''} (${tom?.changeCount ? `изменение ${tom.changeCount}` : `версия ${tom?.version || 0}`})`

  const handleMoveUpClick = () => moveUpMember({ memberId: member.id, direction: 'up' })
  const handleMoveDownClick = () => moveDownMember({ memberId: member.id, direction: 'down' })
  const handleEditMember = () => editMember(member)
  const handleDeleteMember = () => deleteMember(member.id)

  const handleButtonClick = useCallback(
    (action: InnermapActionsType, signedDoc?: string, source?: DocumentSigningSource) => {
      if (action === 'approval') {
        openConfirm(action, signedDoc)
      } else {
        sendMemberAction(member.id, action, signedDoc, source)
      }
    },
    [],
  )

  const confirmSendMemberAction = (confirm: boolean, action: InnermapActionsType) => {
    if (confirm) {
      //sendMemberAction(member.id, action)
      setPluginSelectionOpen(true)
    }
  }

  const handleSigningSnackbarVisibilityChange = (isOpen: boolean) => {
    if (!isOpen && memberActionError) resetMemberActionError?.()
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: confirmSendMemberAction,
    title: 'Утвердить документ?',
    body: <>Это завершит процедуру согласования и сделает ее недоступной для редактирования</>,
    denyButtonText: 'Отменить',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  return (
    <TableRow spacing={1} direction='row'>
      {allowMove && (
        <>
          {isFirst && isLast ? (
            <Box minWidth='30px' />
          ) : isFirst ? (
            <StyledMoveCell>
              <ShiftDownContainedIcon fontSize='small' className='icon-hover' onClick={handleMoveDownClick} />
              <ShiftDownOutlinedIcon fontSize='small' className='icon' />
            </StyledMoveCell>
          ) : isLast ? (
            <StyledMoveCell>
              <ShiftUpContainedIcon fontSize='small' className='icon-hover' onClick={handleMoveUpClick} />
              <ShiftUpOutlinedIcon fontSize='small' className='icon' />
            </StyledMoveCell>
          ) : (
            <Stack spacing={0}>
              <StyledMoveCell>
                <ShiftUpContainedIcon fontSize='small' className='icon-hover' onClick={handleMoveUpClick} />
                <ShiftUpOutlinedIcon fontSize='small' className='icon' />
              </StyledMoveCell>
              <StyledMoveCell>
                <ShiftDownContainedIcon fontSize='small' className='icon-hover' onClick={handleMoveDownClick} />
                <ShiftDownOutlinedIcon fontSize='small' className='icon' />
              </StyledMoveCell>
            </Stack>
          )}
        </>
      )}

      <Avatar src={member.person.avatar} sx={{ width: '24px', height: '24px', marginLeft: '8px' }} />
      <StyledName>{member.person.name}</StyledName>
      <StyledPosition>{member.person.position}</StyledPosition>
      <IconWrapper direction='row' $color={approval.signLink ? 'blue' : 'default'}>
        {isSelectedProcedure && approval.datetime ? (
          <>
            <Tooltip title={<>{approval.person.name}</>}>
              <Typography variant='body2'>
                {approval.datetime ? convertDateTime(approval.datetime, undefined, true) : '-'}
              </Typography>
            </Tooltip>
            {approval.signLink && (
              <Tooltip title='Скачать сертификат ЭЦП'>
                <DownloadCertificateButton link={approval.signLink} />
              </Tooltip>
            )}
          </>
        ) : isSelectedProcedure && hasAccess && isSelectedProcedure && member.approval && !approval.datetime ? (
          <DocumentSigning
            file={currentDocument.link}
            fileSize={currentDocument.linkSize}
            documentName={documentName}
            fileName={fileName}
            onSuccess={(signedDoc, source) => sendMemberAction(member.id, 'approval', signedDoc, source)}
            onPluginSelectionClose={() => setPluginSelectionOpen(false)}
            pluginSelectionOpen={pluginSelectionOpen}
            onClick={() => handleButtonClick('approval')}
            buttonText='Утвердить'
            disabled={isAnnulled}
            error={memberActionError}
            onSnackbarVisibilityChange={handleSigningSnackbarVisibilityChange}
          />
        ) : (
          <ApprovalIcon $approval={member.approval} fontSize='small' />
        )}
      </IconWrapper>
      <IconWrapper direction='row'>
        {isSelectedProcedure && assurance.datetime ? (
          <Tooltip title={<>{assurance.person.name}</>}>
            <Typography variant='body2'>
              {assurance.datetime ? convertDateTime(assurance.datetime, undefined, true) : '-'}
            </Typography>
          </Tooltip>
        ) : isSelectedProcedure && hasAccess && member.assurance && !assurance.datetime ? (
          <StyledButton disabled={isAnnulled} onClick={() => handleButtonClick('assurance')} color='primary'>
            Визировать
          </StyledButton>
        ) : (
          <AssuranceIcon $assurance={member.assurance} fontSize='small' />
        )}
      </IconWrapper>
      <IconWrapper direction='row' $color={signing.signLink ? 'blue' : 'default'}>
        {isSelectedProcedure && signing.datetime ? (
          <>
            <Tooltip title={<>{signing.person.name}</>}>
              <Typography variant='body2'>
                {signing.datetime ? convertDateTime(signing.datetime, undefined, true) : '-'}
              </Typography>
            </Tooltip>
            {signing.signLink && (
              <Tooltip title='Скачать сертификат ЭЦП'>
                <ButtonDownloadSignatureWrapper>
                  <DownloadCertificateButton link={signing.signLink} />
                </ButtonDownloadSignatureWrapper>
              </Tooltip>
            )}
          </>
        ) : isSelectedProcedure && hasAccess && member.signing && !signing.datetime ? (
          <DocumentSigning
            file={currentDocument.link}
            fileSize={currentDocument.linkSize}
            documentName={documentName}
            fileName={fileName}
            onSuccess={(signedDoc, source) => handleButtonClick('signing', signedDoc, source)}
            buttonText='Подписать'
            disabled={isAnnulled}
            error={memberActionError}
            onSnackbarVisibilityChange={handleSigningSnackbarVisibilityChange}
          />
        ) : (
          <SigningIcon $signing={member.signing} fontSize='small' />
        )}
      </IconWrapper>
      <IconWrapper direction='row'>
        {isSelectedProcedure && remarks.datetime ? (
          <Tooltip title={<>{remarks.person.name}</>}>
            <Typography variant='body2'>
              {remarks.datetime ? convertDateTime(remarks.datetime, undefined, true) : '-'}
            </Typography>
          </Tooltip>
        ) : isSelectedProcedure && hasAccess && member.remarks && !remarks.datetime && tomVersions.length > 0 ? (
          <StyledButton disabled={isAnnulled} onClick={() => handleButtonClick('remarks')} color='error'>
            Замечание
          </StyledButton>
        ) : (
          <RemarksIcon $remarks={member.remarks} fontSize='small' />
        )}
      </IconWrapper>
      <Box minWidth='30px' />
      {!isAnnulled && (isAdmin || isModerator) && !isApproved && (
        <MembersMenu
          onFirstItemClick={handleEditMember}
          onSecondItemClick={handleDeleteMember}
          firstItemTitle={'Редактировать'}
          secondItemTitle='Удалить участника'
        />
      )}
      <ConfirmDialog />
    </TableRow>
  )
}
