import { Stack, Typography } from '@mui/material'
import { CardText, CardWrapper, StyledIconButton, StyledLabel } from './VersionCard.styles'
import { theme } from '../../../../styles/theme'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import PersonIcon from '@mui/icons-material/Person'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { SubtitleText } from '../DrawerVersionView'
import { VersionCardProps } from './VersionCard.types'
import Tooltip from '../../../../components/Tooltip'
import { convertDateTime } from '../../../../utils/formatDateAndTimeGMT'
import { useDownloadTimMutation } from '../../../../api/tim'
import { useParams } from 'react-router-dom'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { downloadBlob } from '../../../../utils/dowloadBlob'
import { useState } from 'react'
import Progress from '../../../../components/Progress'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../hooks/useConfirmDialog'
import { useTypedSelector } from '../../../../store/store'
import { profileSelector } from '../../../../store/slices/profile'
import { ModelExtension } from '../ModelExtension'

function formatFileSize(sizeInBytes: number) {
  const kilobyte = 1024
  const megabyte = 1024 * 1024

  if (sizeInBytes < megabyte) {
    const sizeInKB = sizeInBytes / kilobyte
    return sizeInKB.toFixed(2) + ' КБ'
  } else {
    const sizeInMB = sizeInBytes / megabyte
    return sizeInMB.toFixed(2) + ' МБ'
  }
}

export const VersionCard = ({ version, onDeleteTimVersion }: VersionCardProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { name } = version.createdBy
  const { role } = useTypedSelector(profileSelector)
  const [downloadTim, downloadTimResponse] = useDownloadTimMutation()
  const showLoader = downloadTimResponse.isLoading && version.fileSize > 1024 * 1024

  const handleDownloadTim = () => {
    downloadTim({ id: projectId, timId: version.timID, version: version.version })
  }

  useMutationHandlers(downloadTimResponse, (data) => {
    if (data) {
      downloadBlob(data, version.originName || 'Файл загрузки')
    }
  })

  const confirmDeleteVersion = (confirm: boolean) => {
    if (confirm) {
      onDeleteTimVersion(version.timID, version.version)
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: confirmDeleteVersion,
    title: 'Удалить версию?',
    body: (
      <>
        Версия {version.version} от {convertDateTime(version.createdAt)} будет удалена без возможности восстановить.
      </>
    ),
    denyButtonText: 'Отменить',
    confirmButtonColor: 'error',
    confirmButtonText: 'Удалить',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  return (
    <CardWrapper>
      {showLoader ? (
        <Progress />
      ) : (
        <>
          <Stack spacing={1.25} direction='row' alignItems='center'>
            <ModelExtension extension={version.extension} tanglBindStatus={version.tanglBindStatus} />
            <Typography variant='body2' color={theme.palette.primary.main} flex={1}>
              {`Версия ${version.version}`}
            </Typography>
            <Tooltip title={<>{`Скачать (${formatFileSize(version.fileSize)})`}</>}>
              <StyledIconButton color='primary' onClick={handleDownloadTim}>
                <DownloadIcon fontSize='small' />
              </StyledIconButton>
            </Tooltip>
            {role === 'admin' && (
              <StyledIconButton color='error' onClick={openConfirm}>
                <DeleteIcon fontSize='small' />
              </StyledIconButton>
            )}
          </Stack>

          <Stack direction='row' spacing={0.75} pt={1.25} alignItems='center'>
            <PersonIcon color='secondary' fontSize='medium' />
            <Typography variant='body2' fontSize={12} lineHeight='normal'>
              {name}
            </Typography>
          </Stack>

          <Stack direction='row' spacing={0.75} pt={1.25} alignItems='center'>
            <EventNoteIcon fontSize='medium' color='secondary' />
            <Typography variant='body2' fontSize={12} lineHeight='normal'>
              {convertDateTime(version.createdAt, true, false)}
            </Typography>
          </Stack>

          <SubtitleText mt={2}>Комментарий: </SubtitleText>
          <CardText>{version.description || '—'}</CardText>
        </>
      )}
      <ConfirmDialog />
    </CardWrapper>
  )
}
